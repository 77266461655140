/*
* fontP: Path to fonts folder
* fileN: Files name without extension
* fontF: font-family
* fontW: font-weight
* fontS: font-style
* formats: woff2 woff truetype eot
*/
@mixin fontFace(
	$fontP,
	$fileN,
	$fontF, 
) {
	@font-face {
		font-family: $fontF;
		src: url($fontP + $fileN + ".woff");
		font-display: swap;
	}
}

@include fontFace("/assets/fonts/", 'ProximaNova-Semibold', 'ProximaNova-Semibold');
@include fontFace("/assets/fonts/", 'ProximaNova-Bold', 'ProximaNova-Bold');
@include fontFace("/assets/fonts/", 'ProximaNova-Regular', 'ProximaNova-Regular');
@include fontFace("/assets/fonts/", 'ProximaNova-Regular', 'ProximaNova');
@include fontFace("/assets/fonts/", 'ProximaNova-Extrabld', 'ProximaNova-Extrabold');
@include fontFace("/assets/fonts/", 'ProximaNova-Regular', 'Proxima Nova');

.l-nl-article {
	background-color: #fff;
	color: var(--outer-space);
	min-height: 280px;
}
.l-nl-article.is-alabaster {
	background-color: #e5eaed;
	padding-bottom: 1.5rem;
}
.l-nl-article.is-primary-bg {
	background-color: $tealColor;
	padding-bottom: 2.5rem;
	color: #fff;
}
.l-nl-article.is-light-blue {
	background: #e5eaed;
}
.l-nl-article.is-dark-bg {
	background: #295967;
	color: #fff;
}
.l-nl-article__header {
	padding-top: 3.5rem;
	padding-bottom: 2.5rem;
}
.l-nl-article__title {
	font-size: 32px;
	font-family: 'ProximaNova-Extrabold';
	letter-spacing: 0.44px;
	text-align: center;
	color: inherit;
	line-height: 1;
}
@media (max-width: $screen-sm-max) {
	.l-nl-article__title {
		font-size: 2.8rem;
   }
}
.l-nl-article__title .icon {
	font-size: 0.4em;
	vertical-align: text-top;
	margin: 0.55em 0 0;

	&.icon-nl-trademark {
		font-size: 1rem;
		margin: 0.2rem 0 0;
	}
}
.l-nl-article__sub-ttl {
	display: block;
	font-size: 20px;
	font-family: 'ProximaNova-Semibold';
	text-align: center;
	color: inherit;
	margin-top: 0.5rem;
}
@media (max-width: $screen-sm-max) {
	.l-nl-article__sub-ttl {
		font-size: 1.6rem;
   }
}
.l-nl-article__body {
	display: block;
	padding-bottom: 2.5rem;
}
.l-nl-article__body.engage-video-section {
	text-align: center;
	position: relative;
}
.l-nl-article__body.engage-video-section .img-group-logo {
	position: absolute;
	top: 0;
	left: 67%;
	width: 300px;
	z-index: 1;
}
.l-nl-article__body.engage-video-section video {
	position: relative;
	z-index: 2;
}
@media (max-width: 426px) {
	.l-nl-article__body.engage-video-section video {
		width: 100%;
   }
}
.l-nl-article .btn-container {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	margin-top: 50px;
}
@media (max-width: $breakpoint-xs) {
	.l-nl-article .btn-container {
		margin-top: 20px;
   }
	.l-nl-article .btn-container .c-btn {
		max-width: 100%;
   }
}
@media (max-width: 426px) {
	.l-nl-article .btn-container {
		flex-direction: column;
   }
	.l-nl-article .btn-container .c-btn {
		margin: 0 0 10px !important;
		width: 100%;
   }
}
.l-nl-article .btn-container .c-btn {
	margin: 0;
	font-size: 14px;
	line-height: 17px;
	font-family: 'ProximaNova-Bold';
	min-width: 17.2rem;
	padding: 11.5px 10px;
}
.l-nl-article .btn-container .c-btn:first-child {
	margin-right: 10px;
}
.l-nl-article.section-howit-works {
	overflow: hidden;
}
.l-nl-article.section-howit-works > .c-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
.l-nl-article.section-howit-works .image-container {
	position: relative;
	width: 49%;

	@media (max-width: $breakpoint-xs) {
		display: none;
	}
}

.l-nl-article.section-howit-works .image-container .img-group {
	position: absolute;
	left: 0;
	top: -6rem;
	z-index: 2;
	max-width: 83%;
}
.l-nl-article.section-howit-works .image-container .img-group-bg {
	position: absolute;
	z-index: 1;
	left: -37%;
	top: -56%;
	max-width: 150%;
	width: 150%;
	transform: rotate(50deg);

	@media (max-width: $breakpoint-xm-max) {
		top: -26%;
	}
}
.l-nl-article.section-howit-works .paragraph-container {
	width: 49%;
	text-align: left;
	padding-top: 30px;
	padding-bottom: 70px;

	.l-nl-article__header {
		@media (max-width: $breakpoint-xs-max) {
			padding-top: 0;
		}
	}

	.l-nl-article__body {
		@media (max-width: $breakpoint-xs-max) {
			padding-bottom: 0;
		}
	}
}
@media (max-width: $breakpoint-xs) {
	.l-nl-article.section-howit-works .paragraph-container {
		width: 100%;
		padding-bottom: 30px;
   }
}
.l-nl-article.section-howit-works .paragraph-container h2 {
	text-align: left;
	margin-bottom: 0;

}
.l-nl-article.section-howit-works .paragraph-container ul {
	font-size: 18px;
	list-style: none;
}
.l-nl-article.section-howit-works .paragraph-container ul li {
	border-bottom: solid 1px #fff;
	padding-bottom: 10px;
	margin-bottom: 10px;
	position: relative;
	padding-left: 20px;
}
.l-nl-article.section-howit-works .paragraph-container ul li:last-child {
	border-bottom: none;
}
.l-nl-article.section-howit-works .paragraph-container ul li::before {
	content: "\25CF";
	font-size: 12px;
	position: absolute;
	left: 0;
	top: 3px;
}
.l-nl-article.section-howit-works .paragraph-container p {
	font-size: 18px;
	// font-family: 'ProximaNova-Bold';
	margin-bottom: 25px;
}
.l-nl-article.section-howit-works .paragraph-container .btn-container {
	margin-top: 35px;
	justify-content: flex-start;
}
@media (max-width: 821px) {
	.l-nl-article.section-howit-works .paragraph-container .btn-container {
		flex-direction: column;
		align-items: flex-start;
   }
	.l-nl-article.section-howit-works .paragraph-container .btn-container .c-btn {
		margin: 0 0 10px;
		max-width: 220px;
		width: 100%;
   }
}
@media (max-width: $breakpoint-xs) {
	.l-nl-article.section-howit-works .paragraph-container .btn-container .c-btn {
		max-width: 100%;
   }
}
.l-nl-article.main-brands-section .btn-container {
	margin-top: 35px;
	padding-bottom: 4rem;
}
.l-nl-article.getting-started-section > .c-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	overflow: hidden;
}
@media (max-width: $breakpoint-xs) {
	.l-nl-article.getting-started-section > .c-container {
		flex-direction: column-reverse;
		align-items: flex-end;
   }
}
.l-nl-article.getting-started-section .left-container {
	width: 48%;
}
@media (max-width: $breakpoint-xs) {
	.l-nl-article.getting-started-section .left-container {
		width: 100%;
   }
}
.l-nl-article.getting-started-section .left-container .l-nl-article__header {
	padding-top: 7rem;
	padding-bottom: 7rem;
}
@media (max-width: $breakpoint-xs) {
	.l-nl-article.getting-started-section .left-container .l-nl-article__header {
		padding-top: 30px;
		padding-bottom: 30px;
   }
}
.l-nl-article.getting-started-section .left-container .l-nl-article__title {
	text-align: left;
	margin-bottom: 10px;
}
.l-nl-article.getting-started-section .left-container p {
	font-size: 20px;
	line-height: 26px;
}
.l-nl-article.getting-started-section .left-container .btn-container {
	margin-top: 30px;
	justify-content: flex-start;
}
@media (max-width: 821px) {
	.l-nl-article.getting-started-section .left-container .btn-container {
		align-items: flex-start;
		flex-direction: column;
   }
	.l-nl-article.getting-started-section .left-container .btn-container .c-btn {
		margin: 0 0 10px;
		max-width: 220px;
		width: 100%;
   }
}
@media (max-width: $breakpoint-xs) {
	.l-nl-article.getting-started-section .left-container .btn-container .c-btn {
		max-width: 100%;
   }
}
.l-nl-article.getting-started-section .left-container .btn-container .c-btn_dark-alternate, .l-nl-article.getting-started-section .left-container .btn-container .c-btn_dark-revert {
	background: #fff;
	&:hover {
		background: var(--accent-color);
		color: var(--white);
	}
}
.l-nl-article.getting-started-section .image-section {
	width: 48%;
	position: relative;
	@media (max-width: $breakpoint-xs) {
		display: none;
	}
}

.l-nl-article.getting-started-section .image-section .img-group {
	position: absolute;
	top: -3rem;
	left: 20%;
	z-index: 2;
	max-width: 65%;
}

.l-nl-article.getting-started-section .image-section .img-group-bg {
	position: absolute;
	top: -40%;
	left: 0;
	z-index: 1;
}

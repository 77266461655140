body .c-article {
    display: block;
}
body .c-article.has-margin-top {
    margin-top: 50px;
}
body .c-article.is-isolated {
    padding: 40px;
    margin-bottom: 40px;
    margin-top: 50px;

    &.no-margin-top {
        margin-top: 0;
   }

   @media (max-width: $breakpoint-xm-max) {
        padding: 15px 0 15px 0;
        margin-bottom: 30px;
        margin-top: 30px;
        font-size: 12px;
   }
}

body .c-article.profile-friends {
    background: none !important;
    padding-top: 0;
    margin-bottom: 0;
}
body .c-article__header {
    display: flex;
    align-items: center;
    padding-bottom: 15px;
    font-size: 12px;
}
@media (min-width: $breakpoint-xm) {
    body .c-article__header.is-md-centered {
        justify-content: center;
   }
}
body .c-article__title {
    display: flex;
    text-transform: none;
    font-family: 'ProximaNova-Bold';
    margin-bottom: 0;
    font-size: 22px;
    letter-spacing: 0.22px;
    color: var(--bg-text-color);
}
body .c-article__title .e-thin {
    font-family: 'ProximaNova-Bold';
}
body .c-article__sub-tools {
    display: flex;
    margin-bottom: 0;
    margin-left: 10px;
    margin-top: 3px;
    font-size: 10px;
    font-family: 'ProximaNova-Bold';
    letter-spacing: 0.25px;
    color: $black-70;
}
@media (max-width: $breakpoint-sm) {
    body .c-article__sub-tools {
        margin-top: 1px;
   }
}
body .c-article__tools {
    margin-left: auto;
    display: flex;
    align-items: baseline;
    font-size: 10px;
}
@media (min-width: $breakpoint-xm) {
    .is-md-centered body .c-article__tools {
        margin-left: 10px;
   }
}
body .c-article__tool {
    display: flex;
    align-items: baseline;
    font-family: 'ProximaNova-Extrabold';
    text-transform: uppercase;
    text-decoration: none !important;
    font-size: 12px;
}
body .c-article__tool .icon, body .c-article__tool .fa {
    margin-right: 5px;
}
body .c-article .prof-list-more {
    white-space: nowrap;
    margin-right: auto;
    margin-left: auto;
    display: block;
    font-size: 16px;
    text-decoration: none;
    color: var(--accent-color);
    font-weight: bold;
    cursor: pointer;
    text-align: center;
}

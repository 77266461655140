@import 'variables';

.c-comments__list > .c-comments__item .c-comments__replies .c-comments__header {
	background-color: transparent;
	padding: 0;
}
.c-comments__list > .c-comments__item > .c-comments__replies .c-comments__body {
	padding-top: 0;
	padding-bottom: 0;
	padding-right: 0;
}
.c-comments__list > .c-comments__item > .c-comments__replies {
	padding-left: 6rem;
	padding-right: 1rem;
}
@media (max-width: $screen-sm-max) {
	.c-comments__list > .c-comments__item > .c-comments__replies {
		padding-left: 3.5rem;
   }
}
.c-comments__header {
	display: flex;
	align-items: flex-start;
	padding: 20px 0 0;
}
.c-comments__header .c-comments__header {
	padding: 10px 0 0;
}
.c-comments__header .c-dl-ns-feedback {
	margin-top: 10px;
}
.c-comments__options {
	display: flex;
	align-items: center;
	margin-top: 5px;
}
.c-comments__options__reply-btn {
	margin-right: 20px;
	font-weight: bold;
	font-size: 12px;
	cursor: pointer;
	color: var(--bg-text-color);
}
.c-comments__options__status {
	font-size: 12px;
	font-family: 'ProximaNova-Semibold';
	color: var(--bg-text-color-light);
}
.c-comments__options__status:not(:first-child) {
	margin-left: 1rem;
}
@media (max-width: $screen-xs-max) {
	.c-comments__options__status:not(:first-child) {
		margin-left: 0.5rem;
   }
}
.c-comments__options__status.is-right {
	margin-left: auto;
}
.c-comments__options__status.is-reply {
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 50%;
}
@media (max-width: $screen-xs-max) {
	.c-comments__options__status.is-reply {
		max-width: 10rem;
   }
}
.c-comments__options__edited-title {
	font-size: 12px;
	color: color var(--bg-text-color-light);
	margin-left: 30px;
}
.c-comments__profile {
	display: flex;
	align-items: center;
	font-size: 2rem;
	text-decoration: none !important;
	font-family: 'ProximaNova-Bold';
}
@media (max-width: $screen-xs-max) {
	.c-comments__profile {
		font-size: 1.4rem;
   }
}
.c-comments__profile .c-avatar {
	font-size: 4rem;
	margin-right: 1rem;

	@media (max-width: $screen-xs-max) {
		font-size: 3rem;
		margin-right: 0.5rem;
	}
}

.c-comments__profile > span {
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
@media (max-width: $screen-xs-max) {
	.c-comments__profile > span {
		max-width: 12rem;
   }
}
.c-comments__body {
	padding: 0.5rem 0 0.5rem 0;
	font-size: 1.6rem;
}
.c-comments__webview-wrap {
	background-color: #fff;
	margin-top: -1px;
	padding: 15px;
}
.c-comments__form {
	padding: 10px 20px 20px;
}
.c-comments__charters {
	padding-top: 10px;
	text-align: right;
	width: 100%;
	display: block;
	font-size: 12px;
}
.c-comments__charters.is-red {
	color: geraldine;
}
.c-comments__item {
	display: block;
	padding-left: 20px;
	padding-right: 20px;
}
.c-comments__caption {
	padding-left: 5rem;
}
@media (max-width: $screen-xs-max) {
	.c-comments__caption {
		padding-left: 3.5rem;
   }
}
.c-comments__replies {
	padding-left: 5rem;
}
@media (max-width: $screen-xs-max) {
	.c-comments__replies {
		padding-left: 3rem;
   }
}
.c-comments__replies__more {
	padding-left: 5rem;
	padding-top: 1rem;
}
@media (max-width: $screen-md-max) {
	.c-comments__replies__more {
		padding-left: 2rem;
   }
}
.c-comments__replies__more__inner {
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	font-size: 2rem;
	padding: 0.5rem 1rem;
	border-radius: 0.4rem;
	font-family: 'ProximaNova-Bold';
	background-color: alabaster;
	font-size: 16px;
}
@media (max-width: $screen-sm-max) {
	.c-comments__replies__more__inner {
		font-size: 1.6rem;
   }
}
.c-comments__replies__more__inner__trigger {
	display: flex;
	align-items: center;
	text-decoration: none;
}
.c-comments__replies__more__inner__trigger:hover {
	text-decoration: underline;
	color: initial;
}
.c-comments__replies__more__inner__pagination {
	margin-left: auto;
	color: dove-gray;
	font-size: 0.8em;
}
.c-comments__replies__more__pagination {
	margin-left: auto;
	color: dove-gray;
	font-size: 0.8em;
	font-family: 'ProximaNova-Semibold';
}
.c-comments__editor__footer {
	display: flex;
	justify-content: flex-end;
	padding-top: 1rem;
}
.c-comments__footer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-left: 4rem;
	padding-right: 1rem;
	font-size: 1.6rem;
	height: 4rem;
	font-family: 'ProximaNova-Bold';
}
@media (max-width: $screen-md-max) {
	.c-comments__footer {
		padding-left: 2rem;
   }
}
@media (max-width: $screen-xs-max) {
	.c-comments__footer {
		padding-right: 0.5rem;
   }
}
.c-comments__footer__item {
	display: flex;
	align-items: center;
	font-weight: inherit;
}
.c-comments__footer__item:not(:last-child) {
	margin-right: 1rem;
}
.c-comments__footer__item .c-comment__drop {
	top: 0;
}
.c-comments__footer__item.reply-btn {
	font-family: 'ProximaNova-Semibold';
	color: var(--greyColor);
	padding-right: 10px;
}
.c-comments__link {
	text-align: center;
	display: block;
	font-weight: normal;
	padding-top: 12px;
}
.c-comments__empty {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px 10px;
	text-align: center;
	width: 100%;
	height: 55px;
}
.c-comments__empty__overwrap {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}
.c-comments__empty__link {
	background: transparent;
	padding: 0;
	height: 100%;
	width: 100%;
	font-size: 0;
	overflow: hidden;
	text-indent: -1000px;
	margin: 0;
	border: 0;
	cursor: pointer;
}
.c-comments__empty .icon {
	font-size: 15px;
	margin-right: 10px;
	margin-top: -0.1em;
}
.c-comments__body-header {
	font-size: 1.6rem;
	width: 100%;
}
.c-comments__content {
	border-radius: 10px;
	padding: 10px 14px;
	background: var(--comment-blue-bg);
	color: var(--comment-text-color);
	word-wrap: break-word;
	word-break: break-word;
	position: relative;
	display: block;

	a {
		color: var(--comment-text-color);
		word-wrap: break-word;
		word-break: break-word;
		font-family: 'ProximaNova-Semibold';
	}

	&__name {
		font-family: 'ProximaNova-Semibold';
		font-size: 16px;
		margin-bottom: 7px;
		cursor: pointer;

		&:hover {
			text-decoration: underline;
		}
	}
}
.c-comments__content__edit {
	padding: 10px 14px;
	width: 100%;
}
.c-comments__options-cont {
	position: relative;
}
.c-comments__options-cont .dots {
	height: 30px;
	width: 30px;
	padding: 5px 7px;
	border-radius: 50%;
	margin-left: 10px;
	cursor: pointer;
}
.c-comments__options-box {
	position: absolute;
	top: 22px;
	left: -70px;
	border-radius: 5px;
	background: var(--flow-pages-bg);
	box-shadow: 0px 2px 4px rgba(0,0,0,0.25);
	padding: 10px 0;
	min-width: 190px;
	z-index: 3;
	color: var(--bg-text-color);
}
.c-comments__options-box ul {
	margin: 0;
	padding: 0;
	list-style: none;
	font-size: 14px;
	cursor: pointer;
}
.c-comments__options-box ul li {
	padding: 10px 20px;
}
.c-comments__options-box ul li:hover {
	background: var(--milestone-task-bg);
}
.c-comment__user-avatar .c-avatar {
	font-size: 40px;
	border: none;
}
@media (max-width: $quest-detail-new-sm-max) {
	.c-comment__user-avatar .c-avatar {
		font-size: 32px;
   }
}
.c-comment .c-comment__drop .c-dropdown__header {
	background: transparent;
	min-width: 30px;
}
.c-comment__editor {
	padding: 15px;
	border: solid 1px #979797;
	display: block;
	min-height: 40px;
	resize: vertical;
	max-width: 100%;
	flex-grow: 1;
	background-color: #fff;
	font-size: 16px;
	width: auto;
	font-family: 'ProximaNova-Bold';
}
@media (max-width: $quest-detail-new-sm-max) {
	.c-comment__editor {
		font-size: 14px;
   }
}
.c-comment__body .c-comment__editor {
	width: 100%;
}
.c-comment__show-all-replies {
	justify-content: center;
	padding: 15px;
	display: flex;
}
.c-comment__show-all-replies a {
	font-family: 'ProximaNova-Bold';
	font-size: 20px;
	display: block;
}
@media (max-width: $quest-detail-new-sm-max) {
	.c-comment__show-all-replies a {
		font-size: 16px;
   }
}
@media (max-width: $breakpoint-xm-max) {
	.c-comment__show-all-replies a {
		font-size: 14px;
   }
}
.c-comment__drop .c-dropdown__body {
	background-color: alabaster;
	box-shadow: 0px 2px 12px -1px #000;
}

.c-nl-quests-carousel .c-nl-quests__col {
	margin-bottom: 0;
}
.c-nl-quests-carousel .slick-dots {
	display: flex;
	flex-wrap: wrap;
	padding: 10px 5px 0;
	justify-content: center;
	list-style-type: none;
	margin-bottom: 0;
}
.c-nl-quests-carousel .slick-dots li {
	width: 1em;
	height: 1em;
	border-radius: 50%;
	font-size: 10px;
	background-color: anakiwa;
	cursor: pointer;
}
.c-nl-quests-carousel .slick-dots li:not(:last-child) {
	margin-right: 4px;
}
.c-nl-quests-carousel .slick-dots li.slick-active {
	background-color: $tealColor;
}
.c-nl-quests {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	margin-left: -1rem;
	margin-right: -1rem;
}
.c-nl-quests.is-loading {
	opacity: 0.5;
	pointer-events: none;
}
@media (min-width: $screen-md-min) {
	.c-nl-quests {
		margin-left: -1.5rem;
		margin-right: -1.5rem;
   }
}
.c-nl-quests__col {
	width: 50%;
	padding-left: 1rem;
	padding-right: 1rem;
	margin-bottom: 2rem;
}
@media (max-width: $screen-sm-max) {
	.c-nl-quests__col.is-xs {
		width: 50%;
   }
}
@media (max-width: $screen-xs-max) {
	.c-nl-quests__col.is-xs {
		width: 100%;
   }
}
@media (min-width: $screen-md-min) {
	.c-nl-quests__col {
		width: 33.33%;
		padding-left: 1.5rem;
		padding-right: 1.5rem;
		margin-bottom: 3rem;
   }
}
@media (max-width: $screen-sm-max) {
	.c-nl-quests__col {
		width: 100%;
   }
}
.c-nl-quests__placeholder {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 10rem;
}

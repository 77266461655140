$lg-size: 2.5rem;
$lg-double-size: 5rem;
$md-size: 1rem;
$md-double-size: 2rem;

.c-dl-newsfeed-ctrl {
    display: block;
    font-size: $DL-h4;
    max-width: 800px;
    margin: 0 auto $lg-size auto;
    padding: 2rem;
    background: var(--white-section-bg);
    border-radius: 5px;
}
@media (max-width: $screen-md-max) {
    .c-dl-newsfeed-ctrl {
        font-size: DL-h5;
        max-width: 70%;
   }
}
@media (max-width: $screen-xs-max) { 
    .c-dl-newsfeed-ctrl {
        max-width: 100%;
   }
}
.c-dl-newsfeed-ctrl__body {
    display: flex;
}
.c-dl-newsfeed-ctrl__body.is-disabled {
    opacity: 0.8;
    pointer-events: none;
}
@media (max-width: $screen-sm-max) {
    .c-dl-newsfeed-ctrl__body.has-preview {
        flex-direction: column;
   }
}
.c-dl-newsfeed-ctrl__view {
    position: relative;
    flex-shrink: 0;
}
.has-preview .c-dl-newsfeed-ctrl__view {
    width: 50%;
}
@media (max-width: $screen-sm-max) {
    .has-preview .c-dl-newsfeed-ctrl__view {
        width: 100%;
   }
}
.c-dl-newsfeed-ctrl__view__circle {
    position: relative;
}
.c-dl-newsfeed-ctrl__caption {
    flex-grow: 1;
    padding-left: 1rem;
}
.c-dl-newsfeed-ctrl__text {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
}
.c-dl-newsfeed-ctrl__text__inner {
    flex-grow: 1;
    display: block;
    border: solid 1px #c1c0c2;
    background-color: var(--white-bg-original);
    padding: 1.5rem;
    border-radius: 0.4rem;
    width: 100%;
    height: 100%;
    font-family: 'ProximaNova-Semibold';
    cursor: text;
    height: 80px;
    color: var(--bg-text-color-light);
}
.c-dl-newsfeed-ctrl__text__inner.is-edit-mode {
    padding-bottom: 6.5rem;
}
.c-dl-newsfeed-ctrl__text__ctrl {
    display: block;
    background-color: var(--white-bg-original);
    line-height: 1.2;
    min-height: 0;
    border: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    height: auto;
    min-height: 0;
    resize: none;
    box-shadow: none;
    overflow: hidden;
    font-size: 16px;
}
@media (max-width: $screen-md-max) {
    .c-dl-newsfeed-ctrl__text__ctrl {
        min-height: 0;
   }
}
.c-dl-newsfeed-ctrl__text__ctrl:focus {
    outline: none;
}
.c-dl-newsfeed-ctrl__text__ctrl::placeholder {
    color: var(--bg-text-color) !important;
}

.c-btn {
	margin: 0 auto;
	border: 1px solid var(--accent-color);
	background: var(--accent-color);
	vertical-align: baseline;
	font-family: inherit;
	font-weight: inherit;
	color: var(--theme-text-light);
	-webkit-appearance: none;
	appearance: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	width: auto;
	text-transform: uppercase;
	padding: 12px 20px;
	border-radius: 5px;
	margin-bottom: 0;
	font-family: 'ProximaNova-Bold';
	font-size: 22px;
	transition-property: background-color, border-color, color;
	transition-duration: 200ms;
	transition-timing-function: ease;
	text-decoration: none !important;
	cursor: pointer;
	min-width: 150px;
	font-family: 'ProximaNova-Bold';

	&_disabled-small-rounded {
		padding: 5px 15px !important;
		border-radius: 20px !important;
		background: var(--lighter-btn-bg) !important;
		color: var(--accent-color) !important;
		opacity: 0.8;
	}
}
@media (max-width: 650px) {
	.c-btn {
		min-width: 100px;
   }
}
.c-btn > span {
	margin-bottom: -0.1em;
}
.c-btn > .icon:first-child {
	margin-right: 5px;
}
.c-btn > .icon:last-child {
	margin-left: 5px;
}
.c-btn_label {
	position: relative;
	text-align: center;
}
.c-btn_label > input {
	position: absolute;
	top: 0;
	left: 0;
	width: 0;
	height: 0;
	border: 0;
	padding: 0;
	margin: 0;
	visibility: hidden;
	opacity: 0;
}
.c-btn_xs {
	padding: 0;
	border: 0;
	font-family: 'ProximaNova-Bold';
	font-size: 12px;
	letter-spacing: 0.5px;
	width: auto;
}
.c-btn_xs.with-p {
	margin: 0;
	padding: 10px;
}
.c-btn_xs .fa .fa-arrow-left, .c-btn_xs .fa .fa-arrow-right {
	font-size: 0.8em;
	position: relative;
	top: -0.1em;
}
.c-btn_sm {
	padding: 5px 10px;
	font-size: 14px;
	font-family: 'ProximaNova-Semibold';
	width: auto;
	font-family: 'ProximaNova-Bold';
}
.c-btn_sm .fa .fa-arrow-left, .c-btn_sm .fa .fa-arrow-right {
	font-size: 0.8em;
	position: relative;
	top: -0.1em;
}
.c-btn_ms {
	padding: 8px 20px;
	font-size: 15px;
	font-family: 'ProximaNova-Semibold';
	width: auto;
	font-family: 'ProximaNova-Bold';
	min-height: 42px;
}
.c-btn_ms .fa .fa-arrow-left, .c-btn_ms .fa .fa-arrow-right {
	font-size: 0.8em;
	position: relative;
	top: -0.1em;
}
.c-btn_md {
	padding: 13px 20px;
	font-size: 16px;
	font-family: 'ProximaNova-Semibold';
	width: auto;
	font-family: 'ProximaNova-Bold';
}
.c-btn_md .fa:first-child {
	margin-right: 5px;
}
.c-btn_md .fa:last-child {
	margin-left: 5px;
}
.c-btn_md .fa .fa-arrow-left, .c-btn_md .fa .fa-arrow-right {
	font-size: 0.8em;
	position: relative;
	top: -0.1em;
}
.c-btn_full {
	width: 100%;
}
.c-btn_dark-revert {
	border-color: var(--accent-color);
	background: transparent;
	color: var(--bg-text-color);
}
.c-btn_dark-revert:active, .c-btn_dark-revert:hover, .c-btn_dark-revert:focus {
	border-color: var(--accent-bg-light-color);
	background: var(--accent-bg-light-color);
	color: var(--theme-text-light);
}
.c-btn_dark-revert.w-auto {
	padding: 10px 20px;
	width: auto;
	min-width: auto;
	max-height: 38px;
	margin-right: 0;
}
.c-btn_dark {
	border-color: var(--accent-color);
	background: var(--accent-color);
	color: var(--theme-text-light);

	&.is-disabled, 
	&:disabled {
		opacity: 0.3 !important;
		pointer-events: none;
	}
}
.c-btn_dark:active, .c-btn_dark:hover, .c-btn_dark:focus {
	border-color: var(--accent-bg-light-color);
	background: var(--accent-bg-light-color);
	color: #fff;
}
.c-btn_dark.is-disabled.gray {
	border-color: $lightGreyColor;
	background: $lightGreyColor;
	color: #fff;
}
.c-btn_dark.is-disabled.gray:active, .c-btn_dark.is-disabled.gray:hover, .c-btn_dark.is-disabled.gray:focus {
	border-color: $lightGreyColor;
	background: $lightGreyColor;
	color: #fff;
}
.c-btn_dark-alternate {
	border-color: var(--accent-color);
	background: transparent;
	color: var(--bg-text-color);
}
.c-btn_dark-alternate.is-disabled {
	border: 1px solid var(--accent-bg-light-color);
	color: var(--accent-bg-light-color);
}
.c-btn_dark-alternate.w-auto {
	padding: 10px 20px;
	width: auto;
	min-width: auto;
	max-height: 38px;
	margin-right: 0;
}
.c-btn_dark-alternate:active, .c-btn_dark-alternate:hover, .c-btn_dark-alternate:focus {
	border-color: var(--accent-bg-light-color);
	color: var(--bg-text-color);
}
.c-btn_dark-yellow {
	border-color: var(--accent-bg-light-color);
	background: transparent;
	color: var(--theme-text-dark);
}
.c-btn_dark-yellow:active, .c-btn_dark-yellow:hover, .c-btn_dark-yellow:focus {
	border-color: var(--accent-color);
	color: var(--theme-text-dark);
}

.c-btn_alto {
	border-color: $alto;
	background: $alto;
	color: var(--theme-text-dark);
	&:disabled {
			background: darken($alto, 5%);
	}
	&:active,
	&:hover,
	&:focus {
		border-color: darken($alto, 5%);
		background: darken($alto, 5%);
		color: $black;
	}
}

.c-btn_white {
	border-color: white;
	background: white;
	color: $black;

	&:disabled {
		background: darken(white, 5%);
	}
	&:active,
	&:hover,
	&:focus {
		border-color: darken(white, 5%);
		background: darken(white, 5%);
		color: $black;
	}
}
		
.c-btn_yellow {
	border-color: $tealColor;
	background: $tealColor;
	color: white;

	&:disabled {
		background: darken($tealColor, 5%);
	}
	&:active,
	&:hover,
	&:focus {
		border-color: tealLightColor;
		background: tealLightColor;
		color: white;
	}
}
		
.c-btn_transparent {
	border-color: transparent;
	background-color: transparent;
	color: inherit;
	&:hover,
	&:focus,
	&:active {
		color: inherit;
	}
}
.c-btn.is-loading {
	opacity: 1;
	pointer-events: none;
	.fa, .icon {
		display: none;
	}
	&:before {
		content: '';
		position: relative;
		width: 1em;
		height: 1em;
		border-radius: 50%;
		border: 2px solid currentColor;
		border-bottom-color: transparent;
		display: inline-block;
		vertical-align: middle;
		margin-right: 5px;
		top: -1px;
		animation: rotating 2s linear infinite;
	}
}

.c-link {
	text-decoration: none;
}
.c-link__dark {
	background: transparent;
	color: var(--accent-color);
	border: 0 none;
	margin: 0;
	padding: 0;
	font-weight: bold;
	font-size: 14px;
}

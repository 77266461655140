.e-checkbox {
    position: relative;
    padding-left: 3rem;
    min-height: 2rem;
    display: flex;
    align-items: center;
    font-weight: normal;
    cursor: pointer;
    line-height: 1.2;
    text-align: left;
    font-size: 1.4rem;
}
.e-checkbox > input {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border: 0;
    padding: 0;
    margin: 0;
    opacity: 0;
}
.e-checkbox > input:disabled ~ .e-checkbox__icon {
    opacity: 0.5;
}
.e-checkbox > input:disabled ~ .e-checkbox__caption {
    opacity: 0.9;
}
.form-is-submitted .e-checkbox > input.ng-invalid ~ .e-checkbox__icon {
    border: 2px solid var(--error-dark);
}
.e-checkbox:not(.is-loading) > input:checked ~ .e-checkbox__icon .icon {
    visibility: visible;
    opacity: 1;
}
.e-checkbox.is-loading .icon.icon-checked {
    visibility: hidden;
}
.e-checkbox.is-loading .e-checkbox__icon {
    opacity: 0.5;
}
.e-checkbox.is-disabled {
    cursor: default;
}
.e-checkbox.is-disabled .icon.icon-checked {
    cursor: not-allowed;
    opacity: 0.5;
}
.e-checkbox__icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 2rem;
    height: 2rem;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    background: #fff;
    border-radius: 0.4rem;
    border: 0.2rem solid $silver-sand;

    &.error {
        border-color: var(--error-dark);
    }
}
.e-checkbox__icon .icon {
    opacity: 0;
    visibility: hidden;
    transition-property: opacity, visibility;
    transition-duration: 300ms;
    transition-timing-function: ease;
}
.e-checkbox__caption {
    display: flex;
    align-items: center;
    min-height: 2rem;
    word-wrap: break-word;
}

.c-dl-milestone {
	display: block;
	margin-bottom: 10px;
	background: var(--quest-content-bg);
	border-radius: 5px;
	
	&.is-page {
		.c-dl-milestone {
			&__body__title-row {
				h4 {
					font-size: 24px;
				}
			}

			&__desc {
				width: 50%;

				@media (max-width: $breakpoint-xs) {
					width: 100%;
				}

				&__view {
					width: 48%;
					margin-left: 2%;

					@media (max-width: $breakpoint-xs) {
						width: 100%;
						margin-left: 0;
					}
				}

				.milestone-description {
					p {
						font-size: 14px;
					}
				}
			}
		}
	}

	&__edit-tools {
		flex-direction: row;
		align-items: center;
		display: none;

		.icon {
			fill: var(--btn-text-light);
		}
		.e-btn {
			&:not(:first-child) {
				margin-left: 10px;
			}
		}
	}

	&__inner {
		display: flex;
		height: auto;
		max-height: auto;
		text-decoration: none !important;
		color: inherit;
		overflow: hidden;

		@media (max-width: $screen-sm-max) {
			position: relative;
			height: auto;
		}

		@media (max-width: $breakpoint-xs) {
			overflow: visible;
		}

		&.preview-ml {
			margin-bottom: 15px;
		}

		&--expanded {
			height: auto;

			@media (max-width: $breakpoint-xm) {
				height: auto;
			}

			.c-dl-milestone__body__inner {
				display: flex;

				@media (max-width: $breakpoint-xm) {
					display: none;
				}
			}
			
			.c-dl-milestone__desc {
				// h4 {
				// 	margin-bottom: 0.8rem;
				// }
				
				p {
					display: block;
				}

				// &__activity-list {
				// 	display: flex;
				// }

				// &__view {
				// 	@media (max-width: $breakpoint-xm) {
				// 		display: block;
				// 	}
				// }
			}

			// .c-dl-milestone__body__view {
			// 	height: auto;
			// }

			.c-dl-milestone__edit-tools {
				display: flex;
			}
		}

		.task-container {
			width: 100%;

			.mb-0 {
				margin-bottom: 0;
			}
		}
	}
	
	&__check {
		margin: 1.6rem;
		display: flex;
		align-items: flex-start;

		@media (max-width: $breakpoint-xm) {
			margin: 1rem;
		}
	}

	&__tools {
		transition: opacity 300ms ease;

		&.is-disabled {
			opacity: 0.2;
			pointer-events: none;
		}

		&__left {
			margin-right: auto;

			&.is-red {
				color: $tabasco;
			}
		}

		.icon {
			fill: var(--btn-text-light);
		}
	}

	&__tool {
		display: flex;
		align-items: center;
		justify-content: center;
		border: solid 2px var(--lightGreyColor);
		border-radius: 5px;
		width: 22px;
		height: 22px;
		background-color: transparent;
		padding: 5px 3px;

		@media (max-width: $screen-sm-max) {
			width: 20px;
			height: 20px;
			padding: 2px 2px;
		}

		&:not(.is-disabled) {
			cursor: pointer;

			&:hover {
				background-color: var(--marked-milestone-bg-hover);
				.icon {
					fill: var(--btn-text-light);
				}
			}

			.icon {
				display: block;
			}
		}

		&.is-active {
			background-color: var(--marked-milestone-bg);
			border-color: var(--marked-milestone-bg);
			color: #fff;
		
			.icon {
				fill: var(--white)
			}
		}

		&.is-active:not(.is-disabled) {
			cursor: pointer;

			&:hover {
				background-color: var(--marked-milestone-bg-hover);
				color: var(--accent-color);
			}
		}

		&.is-disabled {
			opacity: 0.6;
			cursor: default;
		}

		&:not(:first-child) {
			margin-top: 1rem;

			@media (max-width: $screen-sm-max) {
				margin-top: 0.5rem;
			}
		}

		.icon {
			font-size: 2rem;
			pointer-events: none;

			@media (max-width: $screen-sm-max) {
				font-size: 1.2rem;
			}
		}
	}

	&__body {
		// display: flex;
		// flex-direction: row;
		width: 100%;
		// justify-content: space-between;
		padding: 1.6rem 1.6rem 1.6rem 0;

		@media (max-width: $breakpoint-xm) {
			padding: 1rem 1rem 1rem 0;
		}

		&__title-row {
			align-items: center;
			justify-content: space-between;

			&.desktop-only {
				display: flex;

				@media (max-width: $breakpoint-xs-max) {
				  display: none;
				}
			}

			&.mobile-only {
				display: none;
			  
				@media (max-width: $breakpoint-xs-max) {
					display: flex;
				}
			}

			&.expanded {
				padding-bottom: 1.2rem;
				
				@media (max-width: $breakpoint-xm) {
					padding-bottom: 1rem;
				}	
			}


			h4 {
				font-size: 16px;
				margin-bottom: 0;
				font-family: 'ProximaNova-Semibold';
				text-overflow: ellipsis;
				text-transform: none;
				overflow: hidden;
				display: flex;
				align-items: center;
				width: 80%;
	
				.icon-open-link {
					width: 18px;
					height: 18px;
					margin-left: 0.8rem;
					cursor: pointer;
					position: relative;
					z-index: 4;
				}
			}
		}

		&__inner {
			width: 100%;
			flex-direction: row;
			display: none;
		}
		
		&__loader {
			width: 3rem;
			height: 3rem;
			position: absolute;
			z-index: 2;
			right: 0;
		}

		&__mobile-tools {
			&__options {
				position: relative;
			}

			.dots {
				transform: rotate(90deg);
				cursor: pointer;
				display: inline-block;
				margin-right: 10px;
			}

			.icon-open-link {
				width: 23px;
				height: 23px;
				cursor: pointer;
				position: relative;
				z-index: 4;
			}

			.options-dropdown {
				display: none;
				background: var(--white-bg-original);
				box-shadow: 0px 4px 4px 0 rgba(0, 0, 0, 0.5);
				padding: 10px 0;
				width: 160px;
				border-radius: var(--border-radius-val);
				list-style: none;

				&.open {
					display: block;
					position: absolute;
					top: 20px;
					right: 10px;
					z-index: 2;
				}

				li {
					font-size: 1.6rem;
					font-family: 'ProximaNova-Semibold';
					padding: 1.2rem 1.6rem;
					display: flex;
					align-items: center;

					span {
						margin-left: 1rem;
					}
				}
			}
		}

		&__tools {
			width: 17%;
			display: flex;
			flex-direction: row;
			justify-content: right;
			align-items: center;

			&.edit-available {
				@media (max-width: $breakpoint-xs) {
					width: 100%;
				}
			}

			&__edit {
				margin-right: 15px;
				cursor: pointer;

				@media (max-width: $breakpoint-xs) {
					margin-right: 8px;
				}

				svg {
					width: 18px;
					height: 18px;
				}
			}

			&__delete {
				margin-right: 15px;
				cursor: pointer;

				@media (max-width: $breakpoint-xs) {
					margin-right: 8px;
				}
				
				svg {
					width: 17px;
					height: 19px;
				}
			}

			&__open-close {
				// margin: 0.8rem 0.9rem 0.8rem 0;
				cursor: pointer;
				// padding-top: 0.6rem;
	
				svg {
					width: 1.2em;
					height: 1.2em;
					// transform: rotate(90deg);
				}
	
				&--expanded {
					svg  {
						transform: rotate(90deg);
						// transform: rotate(-90deg);
					}
				}
			}
		}
	}

	&__desc {
		font-size: 1.2rem;
		font-family: 'ProximaNova-Semibold';
		color: var(--bg-text-color);
		margin-bottom: 0;
		word-break: break-word;
		overflow: hidden;
		width: 100%;

		p {
			display: none;
		}

		&__activity-list {
			// margin-top: 15px;
			// display: none;
			flex-direction: row;
			flex-wrap: wrap;

			.activity-item {
				border-radius: 17px;
				padding: 2px 10px;
				margin-right: 8px;
				margin-bottom: 8px;
				position: relative;
				border: solid 1px;
				font-size: 11px;
				display: inline-block;

				&::before {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					border-radius: 20px;
					opacity: 0.05;
				}

				&.physical {
					color: var(--physical);
					border-color: var(--physical);

					&::before {
						background-color: var(--physical);
					}
				}

				&.mental {
					color: var(--mental);
					border-color: var(--mental);

					&::before {
						background-color: var(--mental);
					}
				}

				&.environmental {
					color: var(--environmental);
					border-color: var(--environmental);

					&::before {
						background-color: var(--environmental);
					}
				}

				&.occupational {
					color: var(--occupational);
					border-color: var(--occupational);

					&::before {
						background-color: var(--occupational);
					}
				}

				&.financial {
					color: var(--piggybank);
					border-color: var(--piggybank);

					&::before {
						background-color: var(--piggybank);
					}
				}

				&.social {
					color: var(--friends);
					border-color: var(--friends);

					&::before {
						background-color: var(--friends);
					}
				}

				&.others {
					color: var(--greyColor);
					border-color: var(--greyColor);
					cursor: pointer;

					&::before {
						background-color: var(--white);
						box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10);
					}

					@media (max-width: $breakpoint-xs) {
						&.desktop {
							display: none;
						}

						&.mobile {
							display: inline-block !important;
						}
					}

					&.mobile {
						display: none;
					}
				}

				&._1:not(.others) {
					@media (max-width: $breakpoint-xs) {
						display: none;
					}
				}
			}
		}

		&__view {
			position: relative;
			min-width: 200px;
			border-radius: 5px;
			aspect-ratio: 3 / 2;
			cursor: pointer;
			width: 200px;
			padding: 0.8rem 0.8rem 0.8rem 0;
			height: auto;

			@media (max-width: $breakpoint-xs) {
				min-width: 160px;
				width: 100%;
				padding: 0.8rem 0 0;
			}
		}
	}

	&__new-inner {
		padding-bottom: 40px;
		border-bottom: solid 1px $greyDarkColor;

		&-bg {
			background: var(--milestone-task-bg);
		}

		.task-container {
			width: 100%;
			padding: 30px;
			border-radius: 2px;

			.mb-0 {
				margin-bottom: 0;
			}

			.task-list {
				border-bottom: solid 1px $greyDarkColor;
				margin-bottom: 30px;
			}
		}

		.task-settings {
			padding: 5px 0 0 0;
			margin-bottom: 40px;

			@media (max-width: 650px) {
				padding: 5px 10px 20px 10px;
			}

			.task-link-attach-container {
				@media (max-width: 769px) {
					flex-direction: column !important;
				}
				
				// .add-link {
				// 	width: 51%;

				// 	@media (max-width: 769px) {
				// 		width: 100%;
				// 	}			
				// }

				.add-image {
					// width: 45%;

					@media (max-width: 769px) {
						// width: 100%;
						margin-top: 15px;
					}

					.image-upload-box {
						border: solid 1px #c5c9cb;
						background: #fff;
						border-radius: 5px;
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						cursor: pointer;
						height: 87%;
						min-height: 102px;
						width: 100%;

						.image-preview {
							max-height: 80px;
						}

						svg {
							margin-bottom: 11px;
						}

						span {
							font-size: 12px;
						}
					}					
				}

				.or-text {
					font-size: 14px;
					margin-top: 10px;
					margin-bottom: 10px;
					text-align: center;
				}

				label {
					font-size: 14px;
					margin-bottom: 6px;
					display: block;
				}
		   }
		}
	}

	&__view {
		@media (max-width: $screen-sm-max) {
			width: 60px;
		}

		&__picture {
			display: block;
			position: relative;
			height: 100%;
			width: 100%;
			border-radius: 5px;

			> .is-md {
				@media (max-width: $screen-sm-max) {
					display: none;
				}
		   }

		   > .is-sm {
				display: none;

				@media (max-width: $screen-sm-max) {
					display: block;
				}
			}

			.icon-play-btn {
				position: absolute;
				width: 40px;
				height: 40px;
				top: calc(50% - 20px);
				left: calc(50% - 20px);
			}
		}

		&__empty {
			display: block;
			position: relative;
			height: 100%;
			width: 100%;
			border-radius: 5px;
			background-color: $lightGreyColor;
		}

		&__link {
			height: 100%;
			position: relative;
			display: flex;
			align-items: flex-end;
			
			&__picture {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				border-radius: 5px;
				background: url("/assets/images/useful/milestones-url-placeholder-lg.webp") no-repeat center center;

				.icon-play-btn {
					position: absolute;
					width: 40px;
					height: 40px;
					top: 40%;
					left: 40%;
				}
			}

			&__caption {
				position: relative;
				display: block;
				background: rgba(244,244,244,0.5);
				padding: 0.5rem 1rem;
				width: 100%;
				max-width: 100%;
				z-index: 1;
			}

			&__title {
				display: block;
				white-space: nowrap;
				overflow: hidden;
				max-width: 100%;
				text-overflow: ellipsis;
			}

			&__desc {
				display: block;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			&__cont {
				flex-direction: row;
				position: absolute;
				left: 5px;
				// width: calc(100% - 10px);
				bottom: 5px;
				z-index: 3;
				align-items: center;
				display: flex;

				.svg-cont,
				.text-cont {
					padding-top: 2px;
					padding-bottom: 2px;
				}

				.text-cont {
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
					width: 100%;
					padding-right: 5px;
				}

				&::before {
					content: '';
					width: 100%;
					height: 100%;
					background: white;
					opacity: 0.9;
					z-index: -1;
					border-radius: 5px;
					position: absolute;
				}

				.icon-link-small {
					margin-left: 5px;
					margin-right: 5px;
					width: 16px;
					height: 16px;
				}
			}
		}

		&__icon {
			font-size: 2rem;
			position: absolute;
			background: var(--white);
			position: absolute;
			bottom: 5px;
			left: 5px;
			margin: auto;
			box-shadow: 0 0 12px -10px #000;
			z-index: 1;
		}
	}

	&__control-parent {
		width: 100%;
		padding-right: 20px;
	}

	&__control {
		overflow: auto;
		margin: 0px;
		min-height: 8.5rem;
		max-height: 8.5rem;
		resize: none;

		@media (max-width: $screen-md-max) {
			min-height: 5.7rem;
			max-height: none;
		}

		@media (max-width: $screen-sm-max) {
			font-size: 1.1rem;
			margin: -5px -4px 0;
		}
	}

	&__new__footer {
		justify-content: stretch;
		align-items: flex-end;
		display: flex;

		@media (max-width: $screen-sm-max) {
			flex-direction: column;

			.e-btn {
				width: 100%;
				margin-left: 0 !important;
				margin-right: 0 !important;
				margin-bottom: 15px !important;
			}
		}

		> .e-btn:not(:last-child) {
			margin-right: 1rem;
		}
	}
}

.c-dl-milestone:active .c-dl-milestone__inner {
	box-shadow: 0 5px 5px -3px rgba(0,0,0,0.2);
}



.task-group__header {
	background: var(--accent-dark-color);
	color: var(--btn-text-light);
	font-size: 24px;
	font-family: 'ProximaNova-Bold';
	border-radius: 2px;
	line-height: 36px;
}
.task-group__header__title {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 10px 20px 10px 30px;
	justify-content: space-between;
}
.task-group__header__title-name {
	max-width: 80%;
	flex: 1;
}
.task-group__header__title-btn {
	width: 25px;
	height: 25px;
	cursor: pointer;
	display: flex;

	svg {
		fill: var(--theme-text-light);
	}
}

.h-loader {
	position: relative;
	overflow: hidden;
}
.h-loader__caption {
	transition-property: opacity, visibility;
	transition-timing-function: ease;
	transition-duration: 300ms;
}
.h-loader.is-loading .h-loader__caption {
	opacity: 0;
	visibility: hidden;
}
.h-loader__control {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 2;
	color: var(--tealColor);
	opacity: 0;
	visibility: hidden;
	transition-property: opacity, visibility;
	transition-timing-function: ease;
	transition-duration: 300ms;
}
.h-loader__control.is-currentColor {
	background-color: transparent;
	color: currentColor;
}
.h-loader.is-loading .h-loader__control {
	opacity: 1;
	visibility: visible;
}
.h-loader__control .icon-q-check {
	position: absolute;
	bottom: 10px;
	right: 10px;
	font-size: 100px;
	opacity: 0.2;
}
.h-loader__control:after, .h-loader__control:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	width: 1em;
	height: 1em;
	border-radius: 50%;
	border: 2px solid currentColor;
	border-bottom-color: transparent;
	border-left-color: transparent;
	animation: rotating 2s linear infinite;
}
.h-loader__control:before {
	font-size: 30px;
	animation-delay: 100ms;
	animation: rotating 2s linear infinite;
}
.h-loader__control:after {
	font-size: 40px;
	animation: rotating 1.5s linear infinite;
}
.h-xs-loader {
	position: relative;
	overflow: hidden;
}
.h-xs-loader__caption {
	transition-property: opacity, visibility;
	transition-timing-function: ease;
	transition-duration: 300ms;
}
.h-xs-loader.is-loading .h-xs-loader__caption {
	opacity: 0;
	visibility: hidden;
}
.h-xs-loader__control {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 2;
	color: currentColor;
	opacity: 0;
	visibility: hidden;
	transition-property: opacity, visibility;
	transition-timing-function: ease;
	transition-duration: 300ms;
}
.h-xs-loader.is-loading .h-xs-loader__control {
	opacity: 1;
	visibility: visible;
}
.h-xs-loader.is-currentColor .h-xs-loader__control {
	color: currentColor;
}
.h-xs-loader__control:after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	width: 1em;
	height: 1em;
	border-radius: 50%;
	border: 2px solid currentColor;
	border-bottom-color: transparent;
	border-left-color: transparent;
	animation: rotating 2s linear infinite;
}

.tippy {
	&__btn-cont {
		margin-top: 5px;
		width: 100%;
		
		&.two-buttons {
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			.e-btn {
				width: 48%;
			}
		}
		
		.e-btn {
			width: 100%;
		}
	}
}
.tippy-box {
	border-radius: 5px !important;
}
.tippy__mention {
	display: block;
	box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.4);
	min-width: 340px;
}
.tippy__caption {
	position: relative;
	display: flex;
	align-items: center;
	padding-right: 15px;
	cursor: pointer;
	color: #2e3638;
}
.tippy__caption:hover {
	text-decoration: underline;
}
.tippy__caption:after {
	content: '';
	display: none;
}
.tippy__avatar {
	font-size: 40px !important;
	flex-shrink: 0;

	&.c-avatar {
		width: 50px;
		height: 50px;
	}
}
@media (max-width: $quest-detail-new-sm-max) {
	.tippy__avatar {
		font-size: 32px !important;
   }
}
.tippy__ttl {
	padding-left: 10px;
	display: flex;
	flex-direction: column;
	width: 100%;

	a {
		font-size: 16px;
		font-family: 'ProximaNova-Bold';
		text-decoration: none;
	}

	.location {
		font-size: 14px;
		margin-top: 0;
		margin-bottom: 10px;
		display: flex;
		align-items: center;

		.icon {
			margin-right: 3px;
		}
	}

	.mission {
		font-size: 14px;
		white-space: normal;
		width: 100%;
		word-break: break-word;
		color: var(--dove-gray);
	}
}
@media (max-width: $quest-detail-new-sm-max) {
	.tippy__ttl {
		font-size: 16px;
   }
}
.tippy__friend-status {
	display: flex;
	align-items: center;
	justify-content: center;
	color: smalt-blue;
	margin: 0 auto;
}
.tippy__friend-status span {
	display: none;
}
.tippy__friend-status .icon {
	display: none;
	margin-right: 5px;
	font-size: 0.7em;
}
.tippy__tools {
	display: none;
	align-items: center;
	justify-content: center;
	width: 100%;
	border-top: 1px dashed #fff;
	padding-top: 5px;
	margin-top: 5px;
}
.tippy__tools.is-active {
	display: flex;
}
.tippy__tools.is-active .tippy__friend-status {
	color: #fff;
}
.tippy__tools.is-active .tippy__friend-status:hover {
	text-decoration: underline;
}
.tippy__tools.is-active .tippy__friend-status .active {
	display: block;
}
.tippy__tools.is-active .tippy__friend-status .icon-plus {
	display: block;
}
.tippy__tools.is-pending {
	display: block;
}
.tippy__tools.is-pending .tippy__friend-status {
	color: #fff;
}
.tippy__tools.is-pending .tippy__friend-status .pending {
	display: block;
}
.tippy__tools.is-pending .tippy__friend-status .icon-plane {
	display: block;
}
.tippy__tools.is-accepted {
	display: block;
}
.tippy__tools.is-accepted .tippy__friend-status {
	color: shamrock;
}
.tippy__tools.is-accepted .tippy__friend-status .accepted {
	display: block;
}
.tippy__tools.is-accepted .tippy__friend-status .icon-checked {
	display: block;
}

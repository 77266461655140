body app-profile {
    background-image: none;
    display: block;
}
body .c-profile-new {
    display: block;

    &__gray-bg {
        background-color: var(--bg-milestone);
    }

    &__padding-general {
        padding: 5rem 17rem;

        @media (max-width: $breakpoint-xm) {
            padding: 3rem 5rem;
        }

        @media (max-width: $breakpoint-xs) {
            padding: 2rem 1rem;
        }
    }

    &__discover-box {
        text-align: center;
        border-radius: var(--border-radius-val);

        &__label {
            font-size: 1.6rem;
            margin-bottom: 2.5rem;
            color: var(--bg-text-color);
        }

        .e-btn {
            .icon {
                margin-left: 1rem;
                margin-right: 0;
            }
        }
    }

    &__country {
        margin-bottom: 5px;
        font-family: 'ProximaNova-Semibold';
        font-size: 18px;
        color: var(--bg-text-color);
        text-align: center;
    }

    &__missionstatement {
        text-align: center;
        font-size: 16px;
        padding-left: 11rem;
        padding-right: 11rem;
        margin-bottom: 2rem;

        @media (max-width: $breakpoint-xm) {
            padding-left: 5rem;
            padding-right: 5rem;
        }

        @media (max-width: $breakpoint-xs) {
            padding-left: 1rem;
            padding-right: 1rem;
        }

        @media (max-width: $breakpoint-6s) {
            padding-left: 0;
            padding-right: 0;
        }
    
        .e-edit {
            position: relative;
    
            &__tools {
                position: absolute;
                right: -30px;
                top: 10px;
                z-index: 9;

                &.editing-on {
                    right: -60px;
                }

                @media (max-width: $breakpoint-xm) {
                    right: 0;
                    top: -30px;

                    &.editing-on {
                        right: 0;
                    }
                }
            }
        }
    }

    &__transactions {
        @media (max-width: $breakpoint-xm) {
            .c-quest__article__header {
                padding-left: 0;
            }
        }

        .prof-list-more {
            white-space: nowrap;
            margin-right: auto;
            margin-left: auto;
            display: block;
            font-size: 16px;
            text-decoration: none;
            color: var(--accent-color);
            font-weight: bold;
            cursor: pointer;
            text-align: center;
        }
    }
}
body .c-profile-new__page {
    background-color: var(--primary-bg);
    background-image: none;
}
body .c-profile-new__container {
    background-color: var(--primary-bg-secondary);

    &__content {
        padding-left: 4rem;
        padding-right: 4rem;

        @media (max-width: $breakpoint-xm) {
            padding-left: 2rem;
            padding-right: 2rem;
        }

        @media (max-width: $breakpoint-xs) {
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }
}
body .c-profile-new__container.content-container {
    margin-top: 20px;
    border-radius: 5px 5px 0 0;
    padding-top: 40px;
    min-height: 400px;

    @media (max-width: $breakpoint-xm) {
        margin-top: 0;
    }
}

body .c-profile-new__header {
    position: relative;
    display: block;

     &__cover-upload-btn {
        justify-content: center;
        align-items: center;
        position: absolute;
        font-size: 12px;
        display: flex;
        cursor: pointer;
        width: auto;
        border-radius: 5px;
        right: 10px;
        bottom: 10px;
        padding: 7px 10px;
        color: var(--btn-text-light);
        
        .icon {
            fill: var(--btn-text-light);
            width: 16px;
            height: 16px;
            margin-right: 8px;
            @media (max-width: $breakpoint-xs) {
                margin-right: 0;
            }
        }
        * {
            z-index: 2;
        }
        &::before {
            content: '';
            background: var(--accent-color);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            opacity: 0.6;
            border-radius: 5px;
        }
        .no-mobile {
            @media (max-width: $breakpoint-xs) {
                display: none;
            }
        }
    }
}
body .c-profile-new__header__photo {
    // background: url("/assets/images/useful/tree-back.webp") no-repeat center center;
    background-color: #bebebe;
    padding-bottom: 18.6875%;
    background-size: cover;
    margin-bottom: 50px;
    position: relative;
    height: 0;

    @media (max-width: $breakpoint-xm) {
        padding-bottom: 18.5%;
        margin-bottom: 45px;
    }    

    @media (min-width: $breakpoint-md-max) {
        margin-bottom: 40px;
    }

    .c-avatar {
        border-color: #fff;
        font-size: inherit;
        border-width: 4px;
        display: block;
        width: 1.1em;
        height: 1.1em;
        border: 3px solid #fff;
        box-shadow: 0px 0px 1px 1px #c1c1c1;
    }
}

body .c-profile-new__header__photo__bg .h-object-fit {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
}
body .c-profile-new__header__photo__bg__upload {
    justify-content: center;
    align-items: center;
    position: absolute;
    font-size: 20px;
    display: flex;
    cursor: pointer;
    background: var(--accent-color);
    width: 2em;
    height: 2em;
    border-radius: 5px;
    right: 10px;
    bottom: 10px;
    padding: 2px 3px;
}
@media (max-width: 426px) {
    body .c-profile-new__header__photo__bg__upload {
        right: 15px;
        width: 1.5em;
        height: 1.5em;
   }
    body .c-profile-new__header__photo__bg__upload .icon {
        width: 1.3em;
        height: 1.3em;
   }
}
body .c-profile-new__header__photo__bg__upload .icon {
    fill: var(--theme-text-light);
}
@media (max-width: 426px) and (max-width: 426px) {
    body .c-profile-new__header__photo__bg__upload .icon {
        width: 1em;
        height: 1em;
   }
}
body .c-profile-new__header__photo__img {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;

    &__cont-bg {
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
}
body .c-profile-new__header__photo__avatar {
    transform: translateY(25%);
    position: absolute;
    margin-right: auto;
    margin-left: auto;
    font-size: 120px;
    display: block;
    height: 1.1em;
    width: 1.1em;
    bottom: 0;
    right: 0;
    left: 0;

    @media (max-width: $breakpoint-xm) {
        font-size: 80px;
    }

    @media (min-width: $breakpoint-md-max) {
        font-size: 146px;
    }
}


body .c-profile-new__header__photo__image-upload {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    width: 1.7em;
    height: 1.7em;
    border-radius: 5px;
    position: absolute;
    bottom: 8px;
    left: 4px;
    background: var(--accent-color);
    color: var(--theme-text-light);
    cursor: pointer;
    padding-left: 3px;
    padding-top: 2px;

    @media (max-width: $breakpoint-xm) {
        bottom: 4px;
        left: 0;
        width: 1.5em;
        height: 1.5em;
    }

    .icon {
        display: block;
        width: 1.1em;
        height: 1.1em;
        fill: var(--theme-text-light);

        @media (max-width: $breakpoint-xm) {
            width: 0.8em;
            height: 0.8em;
        }
    }
}

body .c-profile-new__header__caption {
    text-align: center;
}
body .c-profile-new__header{
    &__user {
        text-transform: none;
        margin-bottom: 7px;
        padding-top: 20px;
        font-family: 'ProximaNova-Bold';
        font-size: 28px;
        color: var(--bg-text-color);

        @media (max-width: $breakpoint-xm) {
            font-size: 20px;
        }
    }
}


body .c-profile-new__header__edit a {
    text-decoration: none;
    color: #f8ec31;
    font-family: 'ProximaNova-Bold';
    font-size: 12px;
    text-shadow: 0 0 1px #000;
}

body .c-profile-new__header__tools {
    display: flex;
    justify-content: center;
}
body .c-profile-new__header__trigger {
    margin: 0;
}
body .c-profile-new__header__trigger:not(:last-child) {
    margin-right: 10px;
}
body .c-profile-new__header__trigger > .icon-add-friend {
    margin-top: -0.2em;
}
body .c-profile-new__header__trigger > .icon-remove {
    margin-top: -0.1em;
}
body .c-profile-new__tabs {
    &-row {
        background: var(--primary-bg-secondary);
        border: solid 1px var(--lightGreyColor);
        border-collapse: collapse;
        margin-top: 3rem;
        position: relative;
        border-radius: var(--border-radius-val);

        &__nav {
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: row;
            overflow-x: auto;
        
            @media (max-width: $breakpoint-xs-max) {
                padding: 0;
            }

            &__item {
                font-size: 16px;
                font-family: 'ProximaNova-Bold';
                color: var(--bg-text-color);
                text-align: center;
                padding: 1.2rem;
                position: relative;
                cursor: pointer;
                line-height: 1;
                flex: 1;
                border-right: solid 1px var(--lightGreyColor);
                min-width: 120px;
                
                &:last-child {
                    border-right: none;
                }
            
                &.active-tab {
                    background-color: var(--accent-color);
                    color: var(--btn-text-light);
                }

                a {
                    text-decoration: none;
                    color: inherit;
                    display: block;
                }
            
                .notify-msg {
                    background-color: var(--accent-color);
                    color: var(--btn-text-light);
                    padding: 3px 5px;
                    border-radius: $border-radius-val;
                    font-size: 10px;
                    font-family: 'ProximaNova-Bold';
                    position: absolute;
                    top: -5px;
                    right: 28px;
                    line-height: 1;
                    text-transform: uppercase;
            
                    @media (max-width: $breakpoint-xs) {
                        top: -11px;
                        right: -26px;
                    }
                }
            }
        }
    }

    &__content {
        margin-top: 2rem;
        padding-bottom: 3rem;
    }
}
body .c-profile-new__tabs__nav {
    text-transform: uppercase;
    list-style-type: none;
    border-radius: 5px;
    margin-right: auto;
    margin-left: auto;
    line-height: 18px;
    max-width: 500px;
    overflow: hidden;
    display: flex;
    padding-left: 0;
    margin-bottom: 0;
}
@media (max-width: $breakpoint-xm) {
    body .c-profile-new__tabs__nav {
        margin-right: 10px;
        margin-left: 10px;
        max-width: 100%;
   }
}
body .c-profile-new__tabs__nav__item {
    text-align: center;
    flex-basis: 50%;
    flex-shrink: 0;
    flex-grow: 1;
}
body .c-profile-new__tabs__nav__item a {
    background-color: var(--quest-highlight-bg);
    text-decoration: none;
    padding: 7px 10px;
    color: var(--bg-text-color);
    display: block;
}
body .c-profile-new__tabs__nav__item:not(:last-child) {
    margin-right: 0;
}


body .c-profile-new__quests__icons {
    padding-bottom: 15px;
    padding-top: 15px;
}
body .c-profile-new__quests__list {
    padding-bottom: 10px;
    margin-right: -10px;
    margin-left: -10px;
    flex-wrap: wrap;
    display: flex;
}
body .c-profile-new__quests__item {
    flex-basis: 50%;
    padding: 10px;
    width: 50%;
}
@media (max-width: $breakpoint-xm) {
    body .c-profile-new__quests__item {
        flex-basis: 100%;
        width: 100%;
   }
}
body .c-profile-new__quests__item .c-explore__item {
    border-radius: 0;
    padding-right: 0;
    padding-left: 0;
    width: 100%;
    margin: 0;
}

body .c-profile-new__balance {
    align-items: center;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;

    @media (max-width: $breakpoint-xs) {
        flex-direction: column;
        align-items: flex-start;
    }
    
    &__item {
        padding: 20px;

        &.price {
            border-radius: 5px;
            background: var(--quest-highlight-bg);
            color: var(--bg-text-color);
            width: 43%;
            margin-right: 2rem;

            b {
                margin-right: 1.6rem;
            }

            @media (max-width: $breakpoint-xs) {
                width: 100%;
                margin-bottom: 10px;
                margin-right: 0;

                span {
                    display: block;
                }
            }
        }

        &.btn-container {
            padding: 0;
        }
    }
}

.favorites-sec .rando-h3 {
    margin-bottom: 25px;
}
.favorites-sec .c-my-friends__profile-empty, 
.favorites-sec .c-my-friends__profile-empty span {
    font-family: 'ProximaNova-Bold';
}


body .c-profile-new__transactions .c-article.is-isolated {
    padding: 25px;
}

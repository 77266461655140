.c-nl-banner {
	position: relative;
	overflow: hidden;
}
.c-nl-banner__bg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-size: cover;
	background-position: top center;
	animation: zoominoutsinglefeatured 60s forwards;
	background: #295967;
}
.c-nl-banner__bg:after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
// .c-nl-banner__bg-1 {
// 	background: url("/assets/images/useful/landing/banner.jpg") center;
// }
// .c-nl-banner__bg-2 {
// 	background: url("/assets/images/useful/landing/banner2.jpg") no-repeat;
// 	background-size: auto 100%;
// }
// @media (max-width: $screen-sm-max) {
// 	.c-nl-banner__bg-2 {
// 		background-size: 300% 100%;
//    }
// }
// .c-nl-banner__bg-3 {
// 	background: url("/assets/images/useful/landing/banner3.jpg") no-repeat;
// 	background-size: cover;
// }
.c-nl-banner__caption {
	position: relative;
	min-height: 42rem;
	padding-top: 8rem;
	padding-bottom: 8rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
@media (max-width: $screen-sm-max) {
	.c-nl-banner__caption {
		min-height: 30rem;
		height: auto;
   }
}
.c-nl-banner__caption.secondary-caption {
	flex-direction: row-reverse;
	justify-content: space-between;
	position: relative;
	padding-top: 4rem;
	padding-bottom: 4rem;
	height: auto;
	min-height: 44rem;
}
@media (max-width: 550px) {
	.c-nl-banner__caption.secondary-caption .img-group, .c-nl-banner__caption.secondary-caption .img-group-bg {
		display: none;
   }
}
.c-nl-banner__caption.secondary-caption .banner-img {
	width: 50%;
}
.c-nl-banner__caption.secondary-caption .img-group {
	margin-top: -12rem;
	position: relative;
	z-index: 2;
}
.c-nl-banner__caption.secondary-caption .img-group-bg {
	position: absolute;
	z-index: 1;
	top: -50%;
	right: -20%;
}
.c-nl-banner__caption.secondary-caption .img-group-logo {
	position: absolute;
	z-index: 3;
	bottom: 20%;
	right: 0;
}
.c-nl-banner__caption.c-nl-banner__main-caption {
	align-items: center;
	flex-direction: row;
	justify-content: space-between;
	padding-top: 0;
	padding-bottom: 0;
}
@media (max-width: 650px) {
	.c-nl-banner__caption.c-nl-banner__main-caption {
		flex-direction: column-reverse;
		align-items: flex-end;
		padding-bottom: 30px;
   }
}
.c-nl-banner__caption.c-nl-banner__main-caption .image-container-1 {
	position: relative;
	width: 49%;
	
	@media (max-width: 821px) {
		width: 39%;
	}

	@media (max-width: 650px) {
		width: 100%;
		margin: 30px auto 10px;
	}

	@media (max-width: $breakpoint-xs-max) {
		margin-bottom: 0 !important;
	}

	img {
		max-width: 480px;
	}

	.img-group {
		margin-top: -8rem;

		@media (max-width: 650px) {
			width: 100%;
		}
	}
}


@media (max-width: 360px) {
	.c-nl-banner__caption.c-nl-banner__main-caption .image-container-1 .img-group {
		margin-top: -5rem;
   }
}
.c-nl-banner__caption.c-nl-banner__main-caption .image-container-1 .img-group-logo {
	position: absolute;
	top: 30%;
	left: 70%;
}
@media (max-width: 650px) {
	.c-nl-banner__caption.c-nl-banner__main-caption .image-container-1 .img-group-logo {
		max-width: 100px;
		top: 60%;
		left: 60%;
   }
}
.c-nl-banner__title {
	font-size: 4.1rem;
	font-family: 'ProximaNova-Extrabold';
	line-height: 1;
	color: #fff;
	letter-spacing: 0.5px;
	width: 100%;
	margin-bottom: 25px;

	@media (max-width: $screen-sm-max) {
		font-size: 2.8rem;
	}
}

.c-nl-banner__title .mob-only {
	display: none;
}
@media (max-width: 650px) {
	.c-nl-banner__title .web-only {
		display: none;
   }
	.c-nl-banner__title .mob-only {
		display: block;
   }
}
.c-nl-banner__title .icon {
	font-size: 0.25em;
	vertical-align: text-top;
	margin-top: 1.15em;
	margin-left: 0.2em;
}
.c-nl-banner__sub-ttl {
	font-size: 2.2rem;
	font-family: 'ProximaNova-Extrabold';
	text-align: center;
	margin-bottom: 1.5rem;
	line-height: 1;
	color: $casal;
}
@media (max-width: $screen-sm-max) {
	.c-nl-banner__sub-ttl {
		font-size: 1.8rem;
   }
}
.c-nl-banner__paragraph-1 {
	font-size: 1.8rem;
	font-family: 'ProximaNova-Semibold';
	max-width: 92rem;
	line-height: 1.23;
	margin-bottom: 25px;
	color: #fff;

	@media (max-width: $screen-sm-max) {
		font-size: 1.6rem;
		max-width: 33rem;
	}
}

.c-nl-banner__paragraph-1-container {
	background: rgba(0,130,151,0.8);
	padding: 8rem 0;
	letter-spacing: 0.5px;
	max-width: 900px;
	width: 49%;

	@media (max-width: $breakpoint-xs-max) {
		margin-top: 30px;
	}
}
@media (max-width: 821px) {
	.c-nl-banner__paragraph-1-container {
		width: 59%;
   }
}
@media (max-width: 650px) {
	.c-nl-banner__paragraph-1-container {
		width: 100%;
		padding: 0;
   }
}
.c-nl-banner__paragraph-2 {
	font-size: 1.8rem;
	text-align: left;
	font-family: 'ProximaNova-Semibold';
	max-width: 48%;
	line-height: 1.23;
	margin-bottom: 2.3rem;
	color: #fff;
	letter-spacing: 0.5px;
}
@media (max-width: $screen-sm-max) {
	.c-nl-banner__paragraph-2 {
		font-size: 1.6rem;
		max-width: 33rem;
   }
}
.c-nl-banner__paragraph-3 {
	font-size: 1.8rem;
	max-width: 84rem;
	line-height: 1.23;
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	color: #fff;
	letter-spacing: 0.5px;
}
@media (max-width: $screen-sm-max) {
	.c-nl-banner__paragraph-3 {
		font-size: 1.6rem;
   }
}
@media (min-width: $screen-sm-min) {
	.c-nl-banner__paragraph-3-caption {
		padding: 10rem;
   }
}
.c-nl-banner__paragraph-6 {
	font-size: 1.8rem;
	max-width: 84rem;
	line-height: 1.23;
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	color: #2e3638;
	letter-spacing: 0.5px;
}
@media (max-width: $screen-sm-max) {
	.c-nl-banner__paragraph-6 {
		font-size: 1.6rem;
   }
}
@media (min-width: $screen-sm-min) {
	.c-nl-banner__paragraph-6-caption {
		padding: 10rem;
   }
}
.c-nl-banner__paragraph-6-caption .c-nl-banner__title {
	color: #2e3638;
	font-size: 32px;
	margin-bottom: 22px;
}
@media (min-width: 1200px) {
	.c-nl-banner__paragraph-6-caption .c-container {
		max-width: 700px;
   }
}
.c-nl-banner__paragraph-6-caption .c-nl-banner__paragraph-6-container {
	padding: 0 2rem;
	background: #fff;
	border-radius: 5px;
	min-height: 10px;
}
.c-nl-banner__paragraph-3-container {
	background: rgba(29,114,143,0.9);
}
@media (max-width: $screen-sm-max) {
	.c-nl-banner__paragraph-3-container {
		padding: 3rem;
   }
}
@media (min-width: $screen-sm-min) {
	.c-nl-banner__paragraph-3-container {
		width: fit-content;
		height: 38rem;
   }
}
.c-nl-banner.is-primary-bg {
	background: $tealColor;
}
@media (max-width: 821px) {
	.c-nl-banner .btn-container {
		display: flex;
		flex-direction: column;
   }
	.c-nl-banner .btn-container .c-btn {
		margin: 0 0 10px;
		max-width: 220px;
   }
}
.c-nl-banner .btn-container .c-btn {
	border-radius: 5px;
	font-size: 14px;
	line-height: 17px;
	font-family: 'ProximaNova-Semibold';
	margin-right: 10px;
	padding: 11.5px 10px;
}
@media (max-width: 821px) and (max-width: 650px) {
	.c-nl-banner .btn-container .c-btn {
		margin-right: 0;
		max-width: 100%;
   }
}
.c-nl-banner__submit {
	min-width: 17.2rem;
	border-color: #fff;
	background: transparent;
	color: #fff;
}
.c-nl-banner__submit:hover {
	color: #fff;
	opacity: 0.9;
}
.c-nl-banner__submit-white {
	min-width: 17.2rem;
	border-color: #fff;
	background: #fff;
	color: $greyDarkColor;
}
.c-nl-banner__submit-white:hover {
	color: $greyDarkColor;
	opacity: 0.9;
}
.c-nl-banner__submit-yellow {
	min-width: 17.2rem;
	border-color: #ecfe36;
	background: #ecfe36;
	color: $greyDarkColor;
}
.c-nl-banner__submit-yellow:hover {
	color: $greyDarkColor;
	opacity: 0.9;
}
@-moz-keyframes zoominoutsinglefeatured {
	0% {
		transform: scale(1, 1);
   }
	50% {
		transform: scale(1.2, 1.2);
   }
	100% {
		transform: scale(1, 1);
   }
}
@-webkit-keyframes zoominoutsinglefeatured {
	0% {
		transform: scale(1, 1);
   }
	50% {
		transform: scale(1.2, 1.2);
   }
	100% {
		transform: scale(1, 1);
   }
}
@-o-keyframes zoominoutsinglefeatured {
	0% {
		transform: scale(1, 1);
   }
	50% {
		transform: scale(1.2, 1.2);
   }
	100% {
		transform: scale(1, 1);
   }
}
@keyframes zoominoutsinglefeatured {
	0% {
		transform: scale(1, 1);
   }
	50% {
		transform: scale(1.2, 1.2);
   }
	100% {
		transform: scale(1, 1);
   }
}

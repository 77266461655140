@import "variables";

input:-webkit-autofill, input:-webkit-autofill:hover, textarea:-webkit-autofill, textarea:-webkit-autofill:hover, select:-webkit-autofill, select:-webkit-autofill:hover, input:-webkit-autofill:focus, textarea:-webkit-autofill:focus, select:-webkit-autofill:focus {
	-webkit-text-fill-color: outer-space !important;
	-webkit-box-shadow: 0 0 0px 1000px #fff inset !important;
	transition: background-color 5000s ease-in-out 0s !important;
}
body textarea.c-n-form__input {
	min-width: 100%;
	max-width: 100%;
	padding: 10px;
	resize: none;
	line-height: 20px;

	&.overflow-hidden {
		overflow: hidden;
	}
}
body textarea.c-n-form__input.blank-reply-input {
	height: 40px !important;
}
body .c-ticket-flow__body-cont .c-n-form__legend {
	font-size: 14px;
	font-family: 'ProximaNova-Semibold';
	line-height: 17px;
}
body .c-ticket-flow__body-cont .c-n-form__input {
	border: 1px solid #c5c9cb;
	border-radius: 5px;
}
body .c-ticket-flow__body-cont .c-n-form__input {
	&:focus {
		outline: none;
		border: 1px solid $tealColor;
	}

	&:active {
		outline: none;
	}

	input {
		&:focus,
		&:active {
			outline: 0 !important;
			border: 1px solid $tealColor;
		}
	}
}

body .c-n-form__group-title {
	display: block;
	font-size: 1.8rem;
	font-family: 'ProximaNova-Bold';
	text-transform: uppercase;
	text-align: left;
	margin: 0 2.5% 1.5rem;
}
body .c-n-form__tools {
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	margin-bottom: 1rem;
	margin-top: 2rem;
}
body .c-n-form__tools .c-n-form__group-title {
	margin-bottom: 0;
}
@media (min-width: $screen-sm-min) {
	body .c-n-form__row {
		display: flex;
		flex-wrap: wrap;
		margin-left: -1rem;
		margin-right: -1rem;
   }
}
@media (min-width: $screen-sm-min) {
	body .c-n-form__col {
		padding-left: 1rem;
		padding-right: 1rem;
		width: 50%;
   }
}
body .c-n-form__group {
	display: block;
	margin-bottom: 25px;
}
body .c-n-form__group.mb-0 {
	margin-bottom: 0;
}
body .c-n-form__group.is-invalid .c-n-form__input {
	border-color: var(--error-dark);
}
body .c-n-form__group.is-flexed {
	display: flex;
	align-items: center;
}
body .c-n-form__group.is-flexed .c-checkbox {
	margin-bottom: 0;
}
body .c-n-form__group.is-flexed .e-question {
	margin-left: 10px;
}
body .c-n-form__group.w-100 {
	width: 100%;
}
body .c-n-form__under-control {
	font-size: 1.2rem;
	padding: 1rem 0;
	margin-top: -2rem;
}
body .c-n-form__legend {
	font-size: 14px;
	font-style: normal;
	font-family: 'ProximaNova-Semibold';
	line-height: 17px;
	color: var(--bg-text-color);
	display: block;
	padding-bottom: 1rem;
	margin-bottom: 0;
	position: relative;

	&__sub-legend {
		font-family: 'ProximaNova-Regular';
		font-size: 12px;
		line-height: 14px;
		color: var(--bg-text-color-light);
		margin-top: 4px;
	}

	&.regular {
		font-family: 'ProximaNova-Regular';
	}
}
body .c-n-form__legend.is-required::after {
	content: '*';
	color: var(--bg-text-color);
	padding-left: 1px;
}
body .c-n-form__legend.is-required:hover::before {
	content: 'This field is required';
	position: absolute;
	top: -18px;
	background: #c9c9c9;
	padding: 2px 5px;
	border-radius: 5px;
	font-size: 10px;
}
body .c-n-form__title {
	font-size: 28px;
	font-style: normal;
	font-family: 'ProximaNova-Bold';
}
body .c-n-form__control {
	position: relative;
	display: block;
	font-weight: inherit;
	margin-bottom: 0;
}
body .c-n-form__control.has-icon-left .c-n-form__input {
	padding-left: 4rem;
}
body .c-n-form__control.has-icon-right .c-n-form__input {
	padding-right: 4rem;
}
body .c-n-form__control.is-required.has-icon-right .c-n-form__input {
	padding-right: 8rem;
}
body .c-n-form__control.is-required .c-n-form__input:not(.is-editor):not(ng-select) {
	padding-right: 4rem;
}
body .c-n-form__control.is-required:not(.no-star-in-field):after {
	content: '*';
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0.6rem;
	font-size: 3rem;
	width: 1em;
	height: 1em;
	text-align: center;
	line-height: 1.45em;
	margin: auto;
	color: $silver-sand;
	z-index: 1;
}
body .c-n-form__control.is-editor::after {
	top: 0.7rem;
	bottom: auto;
}
body .c-n-form__control.is-editor .c-n-form__input {
	padding-right: 0;
}
body .c-n-form__control.is-editor .c-n-form__input .ql-editor {
	padding-left: 0;
	padding-right: 0;
}
body .c-n-form__control.is-editor.is-required .ql-editor {
	padding-right: 4rem;
	overflow-y: hidden;
}
body .c-n-form__control.is-editor.is-required .ql-editor.ql-blank:before {
	right: 4rem;
}
body .c-n-form__control.is-editor > .c-n-form__input.is-editor > .ql-container > .ql-editor {
	padding-right: 4rem;
}
body .c-n-form__control.hide-empty-option .dropdown-menu.inner > li:first-child {
	display: none;
}
body .c-n-form__control__icon {
	position: absolute;
	right: 0;
	top: 0;
	padding: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 3;
	height: 100%;
	font-size: 2rem;
}
body .c-n-form__border {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 0.6rem;
	background-color: var(--outer-space);
	pointer-events: none;
	display: none !important;
}
body .c-n-form__input {
	margin: 0 auto;
	font-size: 1.4rem;
	font-family: inherit;
	font-weight: inherit;
	appearance: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	display: block;
	width: 100%;
	padding: 0 18px;
	font-family: 'ProximaNova-Regular';
	text-transform: none;
	color: var(--bg-text-color);
	background: var(--white-bg-original);
	transition: border 200ms ease;
	line-height: 1;
	height: 40px;
	text-align: left;
	outline: 0 !important;
	position: relative;
	z-index: 1;
	border: 1px solid $lightGreyColor;
	border-radius: 5px;

	&.ng-select {
		padding: 0 5px 0 18px !important;
	}
}
body .c-n-form__input[type=number]::-webkit-inner-spin-button, body .c-n-form__input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
body .c-n-form__input:disabled:read-only {
	background: var(--bg-white-ghost);
	opacity: 1;
	color: var(--bg-text-color);
}
body .c-n-form__input.is-focus, body .c-n-form__input:focus {
	border-color: var(--outer-space);
}
body .c-n-form__input.is-focus ~ .c-n-form__border, body .c-n-form__input:focus ~ .c-n-form__border {
	background-color: var(--outer-space);
}
body .c-n-form__input::placeholder {
	color: var(--bg-text-color-light);
}
body .c-n-form__input__placeholder {
	color: var(--bg-text-color-light);
}
body .c-n-form__input:-webkit-autofill {
	background-color: #fff;
}
body .c-n-form__input.is-editor {
	padding: 0;
}
body .c-n-form__input.is-editor > .ql-container > .ql-editor {
	padding: 1.1rem 1.2rem;
}
body .c-n-form__input.is-editor > .ql-container > .ql-editor:before {
	left: 1.1rem;
}
body .c-n-form__input.quill-editor {
	min-height: 100px;
	height: auto;

	&.c-dl-ns-feedback__input {
		min-height: 40px;
	}
}
body .c-n-form__input.ng-invalid.ng-touched {
	border-color: var(--error-dark);
}
body .c-n-form__input.ng-select {
	z-index: 9;
}
body .c-n-form__input.ng-select.z-index-more {
	z-index: 10;
}
body .c-n-form__input.ng-select.ng-select-focused:not(.ng-select-opened) .ng-select-container {
	box-shadow: none;
}
body .c-n-form__input.ng-select .ng-select-container {
	border: 0;
	color: var(--bg-text-color);
	background: var(--white-bg-original);
}
body .c-n-form__input.ng-select .ng-select-container .ng-value-container {
	padding-left: 0;
	color: var(--bg-text-color);
}
body .c-n-form__input.ng-select .ng-select-container .ng-value-container .ng-value {
	display: flex;
	align-items: center;
}
body .c-n-form__input.ng-select .ng-dropdown-panel {
	border-color: transparent;
	border-bottom-color: transparent;
	background: #fff;
	box-shadow: 0px 2px 4px 2px rgba(0,0,0,0.25);
}
body .c-n-form__input.ng-select .ng-dropdown-panel .ng-option {
	padding: 14px;
	color: var(--outer-space);
	display: flex;
	align-items: center;

	span {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		display: inline-block;
	}

	&.ng-option-marked {
		background-color: var(--teal-005);
		color: var(--outer-space);
	}
}

body .c-n-form__input.ng-select .ng-dropdown-panel .ng-option.ng-option-selected {
	color: var(--outer-space);
	background-color: teal-01;
}
body .c-n-form__input.force-default-color {
	background: #fff;
	color: var(--outer-space);
}
body .c-n-form__message {
	font-size: 1.2rem;
	display: none;
	margin-top: 0;
	margin-bottom: 2rem;
	margin-top: 5px;
}
.form-is-submitted body .c-n-form__message {
	display: block;
}
body .c-n-form__message.is-block {
	display: block;
}
body .c-n-form__message.is-red {
	color: $tabasco;
}
body .c-n-form__alert {
	font-size: 1.4rem;
	min-height: $DL-controls-space-interval;
	padding: 0.2rem 0;
	color: $tabasco;
}
body .c-n-form__alert.is-black {
	color: inherit;
}
body .c-n-form__alert.is-green {
	color: var(--amazon);
}
body .c-n-form__control .bootstrap-select > .dropdown-toggle.bs-placeholder, body .c-n-form__control .bootstrap-select > .dropdown-toggle.bs-placeholder:hover, body .c-n-form__control .bootstrap-select > .dropdown-toggle.bs-placeholder:focus, body .c-n-form__control .bootstrap-select > .dropdown-toggle.bs-placeholder:active {
	background: #fff;
	border-color: $lightGreyColor;
}
body .c-n-form__birth-day {
	display: flex;
	margin-left: -0.5rem;
	margin-right: -0.5rem;
	margin-bottom: 0;
}
body .c-n-form__birth-day__day, body .c-n-form__birth-day__month, body .c-n-form__birth-day__year {
	padding-left: 0.5rem;
	padding-right: 0.5rem;
}
body .c-n-form__birth-day__day {
	width: 8rem;
	flex-shrink: 0;
}
body .c-n-form__birth-day__month {
	width: calc(50%);
	flex-grow: 1;
}
body .c-n-form__birth-day__year {
	width: calc(50% - 8rem);
	flex-grow: 1;
}
body .c-n-form__phone ::-webkit-input-placeholder {
	color: var(--greyColor);
}
// body .c-n-form__phone > .iti {
// 	display: block;
// }
// body .c-n-form__phone > .iti > .iti__flag-container {
// 	z-index: 2;
// }
// body .c-n-form__phone > .iti > input {
// 	margin: 0 auto;
// 	padding: 0;
// 	font-size: 1.4rem;
// 	font-family: inherit;
// 	font-weight: inherit;
// 	appearance: none;
// 	-webkit-font-smoothing: antialiased;
// 	-moz-osx-font-smoothing: grayscale;
// 	display: block;
// 	width: 100%;
// 	padding: 1.1rem 1.2rem 1.1rem 5.2rem;
// 	font-family: 'ProximaNova-Semibold';
// 	text-transform: none;
// 	color: var(--bg-text-color);
// 	background: var(--white-bg-original);
// 	transition: border 200ms ease;
// 	line-height: 1.2;
// 	text-align: left;
// 	outline: 0 !important;
// 	position: relative;
// 	z-index: 1;
// 	border: 1px solid silver-sand;
// 	border-radius: 5px;
// }
// body .c-n-form__phone > .iti > input[type=number]::-webkit-inner-spin-button, body .c-n-form__phone > .iti > input[type=number]::-webkit-outer-spin-button {
// 	-webkit-appearance: none;
// 	margin: 0;
// }
// body .c-n-form__phone > .iti > input:disabled:read-only {
// 	-webkit-text-fill-color: #fff;
// 	opacity: 1;
// }
// body .c-n-form__phone > .iti > input.is-focus, body .c-n-form__phone > .iti > input:focus {
// 	border-color: var(--outer-space);
// }
// body .c-n-form__phone > .iti > input.is-focus ~ .c-n-form__border, body .c-n-form__phone > .iti > input:focus ~ .c-n-form__border {
// 	background-color: var(--outer-space);
// }
// body .c-n-form__phone > .iti > input::placeholder {
// 	color: var(--greyColor);
// 	opacity: 0.5;
// }
// body .c-n-form__phone > .iti > input::-webkit-input-placeholder {
// 	color: var(--greyColor);
// 	opacity: 0.5;
// }
// body .c-n-form__phone > .iti > input::-moz-placeholder {
// 	color: var(--greyColor);
// 	opacity: 0.5;
// }
// body .c-n-form__phone > .iti > input::-ms-placeholder {
// 	color: var(--greyColor);
// 	opacity: 0.5;
// }
// body .c-n-form__phone > .iti > input::-webkit-input-placeholder {
// 	color: var(--greyColor);
// 	opacity: 0.5;
// }
// body .c-n-form__phone > .iti > input:-webkit-autofill {
// 	background-color: #fff;
// }
body .c-n-form__phone .iti__country-list {
	min-width: 28rem;
}
// body .c-n-form__group.is-invalid .c-n-form__phone > .iti > input {
// 	border-color: puce;
// }
body .is-required-label:after {
	content: '*';
	padding-left: 1px;
	color: inherit;
}
// body .log-activity-modal .c-n-form__col {
// 	width: 100%;
// }
body .log-activity-modal .c-n-form__group {
	margin-bottom: 20px;
}

body ngx-intl-tel-input {
	display: block;
	width: 100%;

	.iti {
		width: 100%;
	}
}

.input-with-label {
	display: flex !important;
	flex-direction: row;
	padding: 0 10px;
	padding: 0 15px !important;

	&__cont {
		display: inline-block !important;
	}

	&.is-bordered {
		border-radius: 5px;
		border: solid 1px $lightGreyColor;
	}

	.label {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		border-right: solid 1px $lightGreyColor;
		padding-right: 1.5rem;
		margin-right: 10px;

		small {
			color: var(--bg-text-color-light);
			margin-top: 3px;
		}
	}

	&.label-right {
		.label {
			border-left: solid 1px $lightGreyColor;
			border-right: 0;
			padding-left: 1.5rem;
			padding-right: 0;
			margin-right: 0;
			margin-left: 10px;
		}
	}

	input {
		border: 0;
		background-color: transparent;
	}
}
$quest-profile-height: 65px;

.c-quest-profile {
    display: block;
    max-width: calc(100% - 100px);
}
.c-quest-profile__inner {
    position: relative;
    padding-left: 50px;
    min-height: $quest-profile-height;
    display: flex;
    align-items: center;
}
.c-quest-profile__img {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}
.c-quest-profile__img__inner {
    display: block;
    width: 100px;
    height: 100px;
}
.c-quest-profile__ttl {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 18px;
    font-family: 'ProximaNova-Bold';
    letter-spacing: 0.5px;
    min-height: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    background: $black-40;
    padding-right: 10px;
    margin-left: -30px;
    padding-left: 40px;
    border-radius: 8px;
}
.c-quest-profile__subTtl {
    font-size: 12px;
    font-family: 'ProximaNova-Semibold';
    margin-top: 1px;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background: $black-40;
    margin-left: 10px;
    padding: 10px 10px 10px 23px;
    border-radius: 5px;
    display: none;
}
.c-quest-profile__subTtl .icon {
    position: absolute;
    left: 4px;
    top: 0;
    bottom: 0;
    margin: auto;
}

.e-question {
	display: inline-flex;
	position: relative;
	text-decoration: none;
	font-size: 20px;
	width: 1em;
	height: 1em;
	border-radius: 50%;
	justify-content: center;
	align-items: center;
	background-color: transparent;
	border: solid 1px var(--tooltip-bg);
	text-align: center;
	font-style: normal;
	font-family: 'ProximaNova-Bold';
	cursor: pointer;

	&::after {
		content: "?";
		font-size: 0.7em;
		color: var(--tooltip-bg);
	}
}

.e-help {
	display: inline-flex;
	position: relative;
	text-decoration: none;
	font-size: 15px;
	width: 1em;
	height: 1em;
	border-radius: 50%;
	justify-content: center;
	align-items: center;
	background-color: transparent;
	text-align: center;
	font-style: normal;
	font-family: 'ProximaNova-Bold';
	cursor: pointer;
	border: solid 1px var(--tooltip-bg);

	&::after {
		content: "!";
		font-size: 0.7em;
		color: var(--tooltip-bg);
	}

	&.filled {
		background-color: var(--tooltip-bg);
		
		&::after {
			color: white;
		}
	}
}
.c-dl-tooltip .arrow {
	display: none;
}
.c-dl-tooltip .tooltip-inner {
	background-color: #212121;
	color: #fff;
	border-radius: 0.3rem;
	max-width: 220px;
	font-size: 12px;
}
.c-dl-tooltip .tooltip-inner p {
	margin-bottom: 10px;
}
.c-dl-tooltip .tooltip-inner p:last-child {
	margin-bottom: 0;
}
.c-dl-tooltip .tooltip-inner a {
	color: #fff;
}
.c-dl-tooltip.show .tooltip-inner {
	animation: show-dl-tooltip 300ms ease-in-out;
}
.c-dl-tooltip__content {
	display: block;
	text-align: left;
}
.c-dl-tooltip__title {
	display: block;
	margin-bottom: 0.2em;
}
.c-dl-tooltip__list {
	display: flex;
	flex-direction: column;
	text-align: left;
}
.c-dl-tooltip__list li {
	position: relative;
	padding-left: 1.3rem;
}
.c-dl-tooltip__list li:before {
	content: '';
	position: absolute;
	left: 0;
	top: 0.8em;
	width: 1em;
	height: 1em;
	background-color: currentColor;
	border-radius: 50%;
	font-size: 0.6rem;
}
@-moz-keyframes show-dl-tooltip {
	0% {
		opacity: 0;
		transform: scale(0.9);
   }
	100% {
		opacity: 1;
		transform: none;
   }
}
@-webkit-keyframes show-dl-tooltip {
	0% {
		opacity: 0;
		transform: scale(0.9);
   }
	100% {
		opacity: 1;
		transform: none;
   }
}
@-o-keyframes show-dl-tooltip {
	0% {
		opacity: 0;
		transform: scale(0.9);
   }
	100% {
		opacity: 1;
		transform: none;
   }
}
@keyframes show-dl-tooltip {
	0% {
		opacity: 0;
		transform: scale(0.9);
   }
	100% {
		opacity: 1;
		transform: none;
   }
}

.e-toggle {
	position: relative;
	width: 41px;
	cursor: pointer;
	font-family: 'ProximaNova-Semibold';
}
.e-toggle__input {
	position: absolute;
	top: 0;
	height: 0;
	width: 0;
	opacity: 0;
	border: none;
	outline: none;
}
.e-toggle__input:checked + .e-toggle__label {
	box-shadow: inset 0 0 0 18px var(--accent-color), 0 0 0 2px var(--accent-color);
}
.e-toggle__input:checked + .e-toggle__label:before {
	left: calc(100% - 20px);
	// box-shadow: 0 0 0 2px transparent, 0px 2px 6px 2px rgba(0,0,0,0.7);
}
.e-toggle__input:disabled + .e-toggle__label {
	opacity: 0.2;
	pointer-events: none;
}
.e-toggle__input[disabled] {
	opacity: 0;
}
.e-toggle__label {
	display: block;
	position: relative;
	font-size: 12px;
	width: 100%;
	height: 20px;
	border-radius: 18px;
	background: $lightGreyColor;
	cursor: pointer;
	box-shadow: 0 0 0 2px $lightGreyColor;
}
.e-toggle__label:before {
	content: '';
	display: block;
	position: absolute;
	z-index: 1;
	line-height: 34px;
	text-indent: 40px;
	height: 20px;
	width: 20px;
	border-radius: 100%;
	top: 0px;
	left: 0px;
	right: auto;
	background: #fff;
	transition: 0.25s ease-in-out;
}
.e-toggle__wrap {
	display: flex;
	justify-content: flex-start;
	position: relative;
	padding-left: 40px;
	min-height: 30px;
	align-items: center;
	margin-bottom: 10px;
}
.e-toggle__wrap.no-marg-bottom {
	margin-bottom: 0;
}
.e-toggle__wrap .e-toggle {
	position: absolute;
	left: 0;
	width: 40px;
	top: 5px;
}
.e-toggle__caption {
	padding-left: 10px;
}
.c-quest-manage .e-toggle__label {
	width: 40px;
	background: #c5c9cb;
	box-shadow: none;
}
.c-quest-manage .e-toggle__label::before {
	box-shadow: none;
	width: 16px;
	height: 16px;
	top: 2px;
	left: 3px;
}
.c-quest-manage .e-toggle__input:checked + .e-toggle__label {
	box-shadow: none;
	background: var(--accent-color);
}
.c-quest-manage .e-toggle__input:checked + .e-toggle__label::before {
	box-shadow: none;
	left: calc(100% - 19px);
}

.c-checkbox {
    position: relative;
    padding-left: 30px;
    min-height: 20px;
    display: block;
    font-family: 'ProximaNova-Semibold';
    cursor: pointer;
    line-height: 1.25;
    margin-bottom: 10px;
    text-align: left;
    font-size: 14px;
    color: var(--petrol);
}
.c-checkbox.as-icon {
    margin: 0;
    padding: 0;
    width: 20px;
    height: 20px;
}
.c-checkbox > input {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border: 0;
    padding: 0;
    margin: 0;
    opacity: 0;
}
.c-checkbox > input:disabled ~ .c-checkbox__icon {
    opacity: 0.5;
}
.c-checkbox > input:disabled ~ .c-checkbox__caption {
    opacity: 0.6;
}
.form-is-submitted .c-checkbox > input.ng-invalid ~ .c-checkbox__icon {
    border: 2px solid var(--error-dark);
}
.c-checkbox:not(.is-loading) > input:checked ~ .c-checkbox__icon .icon {
    visibility: visible;
    opacity: 1;
}
.c-checkbox.is-loading .icon.icon-checked {
    visibility: hidden;
}
.c-checkbox.is-loading .c-checkbox__icon {
    opacity: 0.5;
}
.c-checkbox.is-disabled {
    cursor: default;
}
.c-checkbox.is-disabled .icon.icon-checked {
    cursor: not-allowed;
    opacity: 0.5;
}
.c-checkbox.is-middle {
    padding-top: 0;
}
.c-checkbox.is-align-center {
    display: flex;
    align-items: center;
}
.c-checkbox__icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: var(--petrol);
    border-radius: 4px;
    border: solid 1px $lightGreyColor;
}
.c-checkbox.is-dark .c-checkbox__icon {
    background-color: $petrol-50;
    color: #fff;
}
.c-checkbox.is-dark:hover .c-checkbox__icon {
    background-color: var(--petrol);
    color: #fff;
}
.c-checkbox.is-casal .c-checkbox__icon {
    background-color: $casal;
    color: #fff;
}
.c-checkbox.is-casal:hover .c-checkbox__icon {
    background-color: var(--petrol);
    color: #fff;
}
.c-checkbox__icon .icon {
    opacity: 0;
    visibility: hidden;
    transition-property: opacity, visibility;
    transition-duration: 300ms;
    transition-timing-function: ease;
}
.c-checkbox__caption {
    display: flex;
    align-items: center;
    min-height: 22px;
    word-wrap: break-word;
    color: var(--bg-text-color);
}
.c-checkbox__caption .is-warning {
    margin-left: 10px;
    color: $bright-yellow;
}
.c-checkbox__caption > .e-question {
    margin-left: 10px;
}

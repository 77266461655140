.e-radio {
	display: block;
	position: relative;
	padding-left: 30px;
	font-weight: normal;
	margin-bottom: 0;

	&__icon {
		border: solid 1px var(--accent-color);
	}
}
.e-radio:not(:last-child) {
	margin-bottom: 10px;
}
.e-radio > input {
	position: absolute;
	top: 0;
	left: 0;
	width: 0;
	height: 0;
	border: 0;
	visibility: hidden;
	opacity: 0;
}
.e-radio > input:checked ~ .e-radio__icon:after {
	visibility: visible;
	opacity: 1;
}
.e-radio__icon {
	position: absolute;
	left: 0;
	width: 1em;
	height: 1em;
	border-radius: 50%;
	background-color: #fff;
	font-size: 20px;
}
.e-radio__icon:after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	margin: auto;
	width: 0.4em;
	height: 0.4em;
	border-radius: 50%;
	background-color: var(--petrol);
	visibility: hidden;
	opacity: 0;
}
.e-radio__caption {
	display: block;
}
.e-radio__title {
	display: flex;
	align-items: center;
	font-size: 1.4rem;
}
.e-radio__title .icon {
	margin-left: 5px;
}
.e-radio__desc {
	display: block;
	font-size: 12px;
	opacity: 0.7;
	padding-top: 2px;
}
.c-quest-manage .e-radio__icon, .c-payments__box-view .e-radio__icon {
	border: solid 1px #c5c9cb;
}
.c-quest-manage .e-radio__icon::after, .c-payments__box-view .e-radio__icon::after {
	background-color: var(--accent-color);
	width: 14px;
	height: 14px;
}
.c-quest-manage .e-radio__title, .c-payments__box-view .e-radio__title {
	font-size: 14px;
}
.c-quest-manage .e-radio__subtitle, .c-payments__box-view .e-radio__subtitle {
	font-size: 13px;
}

@import 'variables';
@import 'dl-variables';

.c-ticket-flow {
	display: block;
	// padding-bottom: 82px;
	font-size: 1.4rem;
	background: var(--flow-pages-bg);

	@media (max-width: $breakpoint-sd) {
		padding-left: 0;
		padding-right: 0;
	}

	.tf-right-padding {
		padding-right: 60px;
	}
}

.c-ticket-flow__caption {
	padding-left: 0;
	padding-right: 0;
}
@media (max-width: 650px) {
	.c-ticket-flow__caption {
		padding-bottom: 10px;
   }
}
.c-ticket-flow__header {
	position: relative;
	height: 132px;
	padding: 10px;
	display: flex;
	align-items: center;
}
.c-ticket-flow__header .c-container {
	width: 100%;
}
.c-ticket-flow__header__caption {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}
.c-ticket-flow__header__bg {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}
.c-ticket-flow__header__bg:after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,0.25);
}
.c-ticket-flow__header__picture {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
}
.c-ticket-flow__header__ttl {
	font-size: 22px;
	text-transform: none;
	color: var(--bg-text-color);
}
.c-ticket-flow__pagination {
	display: block;
	font-family: 'ProximaNova-Extrabold';
	font-size: 18px;
	text-transform: uppercase;
	color: #fff;
	padding: 0 10px;
	background-color: $tealColor;
}
@media (max-width: 650px) {
	.c-ticket-flow__pagination {
		padding: 0;
   }
}
.c-ticket-flow__pagination ul {
	display: flex;
	counter-reset: pagination;
	flex-direction: row;
	justify-content: space-between;
}
.c-ticket-flow__pagination li {
	display: block;
	transition: max-width 300ms ease;
	display: flex;
	overflow: hidden;
	padding: 13px 5px 10px 5px;
	color: #fff;
	position: relative;
	letter-spacing: 1.25px;
	font-size: 14px;
	font-family: 'ProximaNova-Bold';
	justify-content: center;
	width: 100%;
}
.c-ticket-flow__pagination li:before {
	counter-increment: pagination;
	content: counter(pagination);
	position: absolute;
	top: 13px;
	left: calc(50% - 12px);
	color: #000;
	background: #fff;
	border-radius: 60px;
	width: 24px;
	height: 24px;
	display: flex;
	align-items: center;
	font-size: 18px;
	font-family: 'ProximaNova-Bold';
	justify-content: center;
}
.c-ticket-flow__pagination li.is-active {
	background: tealLightColor;
	border-bottom: solid 3px #fff;
}
.c-ticket-flow__pagination li a {
	display: block;
	text-decoration: none !important;
	color: inherit;
	position: relative;
	margin-top: 30px;
}
@media (max-width: 650px) {
	.c-ticket-flow__pagination li a {
		visibility: hidden;
		margin-top: -10px;
   }
}
.c-ticket-flow__pagination .section-title {
	display: none;
	background: tealLightColor;
	padding: 0 30px;
}
@media (max-width: 650px) {
	.c-ticket-flow__pagination .section-title {
		display: block;
   }
}
.c-ticket-flow__pagination .section-title span {
	display: none;
}
.c-ticket-flow__pagination .section-title span.is-active {
	display: block;
}
.c-ticket-flow__pagination .section-title span a {
	display: block;
	text-decoration: none !important;
	color: #fff;
	text-transform: uppercase;
	font-size: 14px;
	font-family: 'ProximaNova-Bold';
	line-height: 30px;
	letter-spacing: 1.25px;
}
.c-ticket-flow__body-cont {
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
@media (max-width: 768px) {
	.c-ticket-flow__body-cont {
		padding-left: 0 !important;
		padding-right: 0 !important;
   }
}
@media (max-width: 650px) {
	.c-ticket-flow__body-cont {
		flex-direction: column;
   }
}
.c-ticket-flow__body-cont-title {
	font-size: 28px;
	font-style: normal;
	font-family: 'ProximaNova-Bold';
	margin-bottom: 15px;
	margin-top: 10px;
	text-transform: capitalize;
	color: var(--bg-text-color);
}
.c-ticket-flow__row:not(:last-child) {
	margin-bottom: 30px;
}
.c-ticket-flow__row__header {
	display: flex;
	padding-bottom: 10px;
}
.c-ticket-flow__row__title {
	font-size: 12px;
	text-transform: uppercase;
}
.c-ticket-flow__row__body {
	position: relative;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
}
.c-ticket-flow__row__body.is-simple {
	flex-direction: column;
}
.c-ticket-flow__row__body .c-n-form__control {
	width: 100%;
}
.c-ticket-flow__row__legend {
	display: block;
	position: absolute;
	top: 0;
	right: -10px;
	width: 70px;
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 100%;
	color: var(--petrol);
}
.c-ticket-flow__row__legend .icon {
	font-size: 20px;
}
.c-ticket-flow__footer {
	display: block;
	background: var(--flow-pages-section-bg);
	padding: 20px 10px;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	border-top: solid 1px #c5c9cb;
	z-index: 2;
}
// .c-ticket-flow__footer.c-donate-flow__footer {
// 	padding-left: 40px;
// 	padding-right: 40px;
// }
// @media (max-width: $breakpoint-xs-max) {
// 	.c-ticket-flow__footer.c-donate-flow__footer {
// 		padding-left: 10px;
// 		padding-right: 10px;
//    }
// }
.c-ticket-flow__footer__inner {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.c-ticket-flow__footer__inner > .c-btn {
	margin: 0;
}
.c-ticket-flow__footer .c-btn {
	font-size: 16px;
	font-style: normal;
	font-family: 'ProximaNova-Bold';
	line-height: 19px;
	text-align: center;
}
.c-ticket-flow__total {
	display: block;
	display: flex;
	flex-direction: column;
}
.c-ticket-flow__total span {
	font-size: 14px;
	color: #000;
	font-family: 'ProximaNova-Bold';
}
.c-ticket-flow__total b {
	font-size: 20px;
	color: #000;
}
.c-ticket-flow__tools {
	margin-left: auto;
}
.c-ticket-flow__tools .c-btn:not(:last-child) {
	margin-right: 10px;
}

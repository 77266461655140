.c-account {
    padding-bottom: 40px;

    &__footer {
        box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.25);
        background: var(--flow-pages-section-bg);
        padding: 15px 0;

        &.fixed {
            position: fixed;
            width: 100%;
            bottom: 0;
            left: 0;
            z-index: 10;
        }
    }

    &__row {
        &__upgrade {
            border: solid 2px var(--brown-gold);
            border-radius: 5px;
            padding: 17px 15px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;

            &__left {
                font-size: 16px;

                span {
                    border-radius: 5px;
                    background-color: var(--light-gold);
                    color: var(--brown-gold);
                    padding: 5px 7px;

                    &.upgraded {
                        background-color: var(--grayBlueBg);
                        color: var(--tealColor);

                        .icon {
                            fill: var(--tealColor);
                        }
                    }
                }
            }

            &__right {
                font-size: 14px;
                display: flex;
                align-items: center;

                .upgrade-btn {
                    padding: 8px 15px;
                    min-width: auto;
                    height: 44px !important;
                    color: var(--outer-space);
                    text-transform: capitalize;
                    font-size: 15px;
                
                    &:hover {
                        color: var(--white);
                    }
                
                    .diemlife-badge-icon {
                        width: 22px;
                        height: 22px;
                        margin-right: 10px !important;
                    }
                }
            }
        }
    }
}
.c-account__group {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-left: -5px;
    margin-right: -5px;
    margin-top: 20px;
}
@media (max-width: $breakpoint-xm) {
    .c-account__group {
        margin-top: 0;
   }
}
.c-account__col {
    width: 50%;
    padding-left: 5px;
    padding-right: 5px;
}
@media (max-width: $breakpoint-xm) {
    .c-account__col {
        width: 100%;
   }
}
.c-account__row {
    color: var(--bg-text-color);
}
.c-account__row:not(:last-child) {
    margin-bottom: 30px;
}
.c-account__row__header {
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 0 none;

    .c-account__row__title {
        font-size: 22px;
        font-family: 'ProximaNova-Bold';
    }
    
    p {
        font-size: 14px;
        margin-top: 8px;
    }
    
}
.c-account__row__title {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 0;
    text-transform: none;
    color: var(--bg-text-color);
}
.c-account__row__title .icon {
    font-size: 20px;
    margin-right: 10px;
}
.c-account__row__submit {
    display: flex;
    justify-content: flex-end;
    text-align: right;
}
.c-account__row__submit .c-btn {
    margin: 0;
}
@media (max-width: $breakpoint-xm) {
    .c-account__row__submit .c-btn {
        max-width: 100%;
   }
}
.c-account__row__secured { 
    display: flex;
    flex-direction: row;
    align-items: center;

    .icon {
        font-size: 16px;
    }
}
.c-account__row__secured__stripe-powered {
    float: right;
}
.c-account__submit {
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
    padding-top: 20px;
    border-top: 1px solid rgba(0,0,0,0.1);
}
.c-account__submit .c-btn {
    min-width: 300px;
}
.c-account .c-checkbox, .c-account .c-checkbox__caption {
    color: var(--bg-text-color);
}

.custom-color {
    &__container-content {
        font-size: 14px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        @media (max-width: $breakpoint-xs-max) {
            flex-direction: column;
        }

        .strong {
            font-family: 'ProximaNova-Bold';
        }
    }

    &__container-left {
        width: 40%;

        @media (max-width: $breakpoint-xs-max) {
            width: 100%;
        }
    
        .c-n-form__group {
            border-bottom: 0 none !important;
        }
    }

    &__container {
        .c-checkbox,
        .c-checkbox__caption {
            color: var(--bg-text-color);
        }
    }

    .btn-container {
        margin-top: 20px;
        display: flex;
        justify-content: flex-end;
    }
}

// custom color css
.custom-color__container-right {
    background: var(--sample-primary-bg);
    padding: 2rem 6rem;
    width: 55%;
    color: var(--sample-bg-text-color);
    border: dashed 1px #e5eaed;

    .e-btn_dark {
        border-color: var(--sample-theme-bg-color);
        background: var(--sample-theme-bg-color);
        color: var(--sample-theme-text-light);
    
        &:active, 
        &:hover {
            border-color: var(--sample-theme-bg-color);
            color: var(--sample-theme-text-light);
            background: var(--sample-theme-bg-color-light);
    
        }
    }
}
@media (max-width: $breakpoint-xs-max) {
    .custom-color__container-right {
        width: 100%;
   }
}
.custom-color__sample-window {
    background: var(--quest-content-bg);
    color: var(--sample-bg-text-color);
}
.custom-color__sample__content {
    padding: 20px;
}
.custom-color__sample-color-box {
    width: 40px;
    height: 40px;
    border: solid 1px #2e3638;
    margin-left: 10px;
    border-radius: 5px;
}
.custom-color .c-quest-tabs-nav {
    background: var(--sample-theme-bg-color);
}
.custom-color .c-quest-tabs-nav__list-item.active::after {
    background: var(--sample-theme-bg-color);
}
.custom-color .c-quest-tabs-nav__list-item:hover {
    color: var(--sample-theme-text-light);
}
.custom-color .c-quest-tabs-nav__list-item:hover::after {
    background: var(--sample-theme-bg-color);
}
.custom-color .c-quest-tabs-nav__list-item a {
    color: var(--sample-theme-text-light);
}
.custom-color .c-quest-tabs-nav__list-item a:active {
    color: var(--sample-theme-text-light);
}

.custom-color .color-field {
    position: relative;
}
.custom-color .color-field::before {
    content: '#';
    color: inherit;
    position: absolute;
    top: 10px;
    left: 18px;
    font-size: 1.4rem;
    z-index: 2;
}
.custom-color .color-field input {
    padding-left: 25px;
}

.custom-color .btn-container button.c-btn_dark-alternate {
    margin-right: 20px;
}

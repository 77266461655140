// * the next rule resets all styles for new headigns
*[class^="t-typo__display-"], *[class^="t-typo__h"] {
	text-transform: none;
	font-size: inherit;
	color: inherit;
	line-height: inherit;
	margin-bottom: inherit;
	font-weight: inherit;
}
*[class^="t-typo__display-"].is-thin, *[class^="t-typo__h"].is-thin {
	font-family: 'ProximaNova-Semibold';
}
.t-typo {
	line-height: 1.2;
	font-family: serif;
}
.t-typo__display-1 {
	font-size: DL-d1;
	line-height: 1.2;
	margin-bottom: 1.2rem;
	font-weight: DL-d1-weight;
}
.t-typo__display-2 {
	font-size: DL-d2;
	line-height: 1.2;
	margin-bottom: 1.2rem;
	font-weight: DL-d2-weight;
}
.t-typo__display-3 {
	font-size: DL-d3;
	line-height: 1.2;
	margin-bottom: 1.2rem;
	font-weight: DL-d3-weight;
}
.t-typo__display-4 {
	font-size: DL-d4;
	line-height: 1.2;
	margin-bottom: 1.2rem;
	font-weight: DL-d4-weight;
}
.t-typo__h1 {
	font-size: DL-h1;
	line-height: 1.2;
	margin-bottom: 1.2rem;
	font-weight: DL-h-weight;
}
.t-typo__h2 {
	font-size: DL-h2;
	line-height: 1.2;
	margin-bottom: 1.2rem;
	font-weight: DL-h-weight;
}
.t-typo__h3 {
	font-size: DL-h3;
	line-height: 1.2;
	margin-bottom: 1.2rem;
	font-weight: DL-h-weight;
}
.t-typo__h4 {
	font-size: $DL-h4;
	line-height: 1.2;
	margin-bottom: 1.2rem;
	font-weight: DL-h-weight;
}
.t-typo__h5 {
	font-size: DL-h5;
	line-height: 1.2;
	margin-bottom: 1.2rem;
	font-weight: DL-h-weight;
}
.t-typo__h6 {
	font-size: DL-h6;
	line-height: 1.2;
	margin-bottom: 1.2rem;
	font-weight: DL-h-weight;
}
.t-typo__link {
	text-decoration: none;
	color: inherit;
}
.t-typo__link:hover {
	text-decoration: underline;
}
.t-typo__link-inverted {
	text-decoration: underline;
	color: $tealColor;
}
.t-typo__link-inverted:hover {
	text-decoration: none;
	color: $tealColor;
}
.t-typo__link.is-flexed, .t-typo__link-inverted.is-flexed {
	display: flex;
	align-items: center;
}
.t-typo__link.is-flexed .icon, .t-typo__link-inverted.is-flexed .icon {
	font-size: 0.8em;
}
.t-typo__link.is-flexed .icon:first-child, .t-typo__link-inverted.is-flexed .icon:first-child {
	margin-right: 0.6rem;
}
.t-typo__link.is-flexed .icon:last-child, .t-typo__link-inverted.is-flexed .icon:last-child {
	margin-left: 0.6rem;
}
.t-typo__link.is-flexed > span, .t-typo__link-inverted.is-flexed > span {
	position: relative;
	top: 0.1em;
}

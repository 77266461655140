body select.selectpicker, body select.bs-select-hidden, body .bootstrap-select > select.bs-select-hidden {
    display: none !important;
}
body .bootstrap-select {
    width: 100%;
}
body .bootstrap-select > .dropdown-toggle {
    position: relative;
    width: 100%;
    z-index: 1;
    text-align: right;
    white-space: nowrap;
    border-color: $lightGreyColor;
}
body .bootstrap-select > .dropdown-toggle::after {
    display: none !important;
}
body .bootstrap-select > select {
    position: absolute !important;
    bottom: 0;
    left: 50%;
    display: block !important;
    width: 0.5px !important;
    height: 100% !important;
    padding: 0 !important;
    opacity: 0 !important;
    border: none;
}
body .bootstrap-select > select.mobile-device {
    top: 0;
    left: 0;
    display: block !important;
    width: 100% !important;
    z-index: 2;
}
body .bootstrap-select.fit-width {
    width: auto !important;
}
body .bootstrap-select.fit-width .dropdown-toggle .filter-option {
    position: static;
    display: inline;
    padding: 0;
}
body .bootstrap-select.fit-width .dropdown-toggle .caret {
    position: static;
    top: auto;
    margin-top: -1px;
}
body .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
    width: 100%;
}
body .bootstrap-select .dropdown-toggle {
    display: flex;
}
body .bootstrap-select .dropdown-toggle .filter-option {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: left;
}
body .bootstrap-select .dropdown-toggle .filter-option-inner {
    padding-right: inherit;
    height: 100%;
}
body .bootstrap-select .dropdown-toggle .filter-option-inner-inner {
    overflow: hidden;
    display: flex;
    align-items: center;
    height: 100%;
}
body .bootstrap-select .dropdown-toggle .filter-option-inner-inner .icon, body .bootstrap-select .dropdown-toggle .filter-option-inner-inner .flag {
    margin-right: 5px;
}
body .bootstrap-select .dropdown-toggle .caret {
    position: absolute;
    top: 50%;
    right: 12px;
    margin-top: -2px;
    vertical-align: middle;
}
body .bootstrap-select.form-control {
    margin-bottom: 0;
    padding: 0;
    border: none;
}
body .bootstrap-select.form-control.input-group-btn {
    z-index: auto;
}
body .bootstrap-select.form-control.input-group-btn:not(:first-child):not(:last-child) > .btn {
    border-radius: 0;
}
body .bootstrap-select.form-control-sm .dropdown-toggle {
    padding: 0.25rem 0.5rem;
}
body .bootstrap-select.form-control-lg .dropdown-toggle {
    padding: 0.5rem 1rem;
}
body .bootstrap-select.bs-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 0 !important;
    padding: 0 !important;
}
body .bootstrap-select.bs-container .dropdown-menu {
    z-index: 1060;
}
body .bootstrap-select .dropdown-menu {
    min-width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
body .bootstrap-select .dropdown-menu > .inner:focus {
    outline: none !important;
}
body .bootstrap-select .dropdown-menu.inner {
    position: static;
    float: none;
    border: 0;
    padding: 0;
    margin: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}
body .bootstrap-select .dropdown-menu li {
    position: relative;
}
body .bootstrap-select .dropdown-menu li.active small {
    color: #fff;
}
body .bootstrap-select .dropdown-menu li.disabled a {
    cursor: not-allowed;
}
body .bootstrap-select .dropdown-menu li a {
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-decoration: none !important;
}
body .bootstrap-select .dropdown-menu li a.opt {
    position: relative;
    padding-left: 2.25em;
}
body .bootstrap-select .dropdown-menu li a span.check-mark {
    display: none;
}
body .bootstrap-select .dropdown-menu li a span.text {
    display: flex;
    align-items: baseline;
    overflow: hidden;
    text-overflow: ellipsis;
}
body .bootstrap-select .dropdown-menu li a span.text .icon, body .bootstrap-select .dropdown-menu li a span.text .flag {
    margin-right: 5px;
}
body .bootstrap-select .dropdown-menu li small {
    padding-left: 0.5em;
}
body .bootstrap-select .dropdown-menu .notify {
    position: absolute;
    bottom: 5px;
    width: 96%;
    margin: 0 2%;
    min-height: 26px;
    padding: 3px 5px;
    background: #f5f5f5;
    border: 1px solid #e3e3e3;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.05);
    box-shadow: inset 0 1px 1px rgba(0,0,0,0.05);
    pointer-events: none;
    opacity: 0.9;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
body .bootstrap-select .no-results {
    padding: 3px;
    background: #f5f5f5;
    margin: 0 5px;
    white-space: nowrap;
}
body .bootstrap-select.show-tick .dropdown-menu .selected span.check-mark {
    position: absolute;
    display: inline-block;
    right: 15px;
    top: 5px;
}
body .bootstrap-select.show-tick .dropdown-menu li a span.text {
    margin-right: 34px;
}
body .bootstrap-select .bs-ok-default:after {
    content: '';
    display: block;
    width: 0.5em;
    height: 1em;
    border-style: solid;
    border-width: 0 0.26em 0.26em 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}
body .bootstrap-select.show-menu-arrow .dropdown-toggle .filter-option:before {
    content: '';
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid rgba(204,204,204,0.2);
    position: absolute;
    bottom: -4px;
    left: 9px;
    display: none;
}
body .bootstrap-select.show-menu-arrow .dropdown-toggle .filter-option:after {
    content: '';
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fff;
    position: absolute;
    bottom: -4px;
    left: 10px;
    display: none;
}
body .bootstrap-select.show-menu-arrow.dropup .dropdown-toggle .filter-option:before {
    bottom: auto;
    top: -4px;
    border-top: 7px solid rgba(204,204,204,0.2);
    border-bottom: 0;
}
body .bootstrap-select.show-menu-arrow.dropup .dropdown-toggle .filter-option:after {
    bottom: auto;
    top: -4px;
    border-top: 6px solid #fff;
    border-bottom: 0;
}
body .bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle .filter-option:before {
    right: 12px;
    left: auto;
}
body .bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle .filter-option:after {
    right: 13px;
    left: auto;
}
body .bootstrap-select > .dropdown-toggle.bs-placeholder, body .bootstrap-select > .dropdown-toggle.bs-placeholder:hover, body .bootstrap-select > .dropdown-toggle.bs-placeholder:focus, body .bootstrap-select > .dropdown-toggle.bs-placeholder:active {
    color: var(--greyColor);
}
body .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-primary, body .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-secondary, body .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-success, body .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-danger, body .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-info, body .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-dark, body .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-primary:hover, body .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-secondary:hover, body .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-success:hover, body .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-danger:hover, body .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-info:hover, body .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-dark:hover, body .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-primary:focus, body .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-secondary:focus, body .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-success:focus, body .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-danger:focus, body .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-info:focus, body .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-dark:focus, body .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-primary:active, body .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-secondary:active, body .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-success:active, body .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-danger:active, body .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-info:active, body .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-dark:active {
    color: rgba(255,255,255,0.5);
}
body .has-error .bootstrap-select .dropdown-toggle, body .error .bootstrap-select .dropdown-toggle, body .bootstrap-select.is-invalid .dropdown-toggle, body .was-validated .bootstrap-select .selectpicker:invalid + .dropdown-toggle {
    border-color: #b94a48;
}
body .bootstrap-select.is-valid .dropdown-toggle, body .was-validated .bootstrap-select .selectpicker:valid + .dropdown-toggle {
    border-color: #28a745;
}
body :not(.input-group) > .bootstrap-select.form-control:not([class*="col-"]) {
    width: 100%;
}
body .bootstrap-select:not(.input-group-btn), body .bootstrap-select[class*="col-"] {
    float: none;
    display: inline-block;
    margin-left: 0;
}
body .bootstrap-select.dropdown-menu-right, body .bootstrap-select[class*="col-"].dropdown-menu-right, body .row .bootstrap-select[class*="col-"].dropdown-menu-right {
    float: right;
}
body .form-inline .bootstrap-select, body .form-horizontal .bootstrap-select, body .form-group .bootstrap-select {
    margin-bottom: 0;
}
body .form-group-lg .bootstrap-select.form-control, body .form-group-sm .bootstrap-select.form-control {
    padding: 0;
}
body .form-group-lg .bootstrap-select.form-control .dropdown-toggle, body .form-group-sm .bootstrap-select.form-control .dropdown-toggle {
    height: 100%;
    font-size: inherit;
    line-height: inherit;
    border-radius: inherit;
}
body .bootstrap-select.form-control-sm .dropdown-toggle, body .bootstrap-select.form-control-lg .dropdown-toggle {
    font-size: inherit;
    line-height: inherit;
    border-radius: inherit;
}
body .form-inline .bootstrap-select .form-control {
    width: 100%;
}
body .bootstrap-select.disabled, body .bootstrap-select > .disabled {
    cursor: not-allowed;
}
body .bootstrap-select.disabled:focus, body .bootstrap-select > .disabled:focus {
    outline: none !important;
}
body .input-group .bootstrap-select.form-control .dropdown-toggle {
    border-radius: inherit;
}
body .bootstrap-select[class*="col-"] .dropdown-toggle {
    width: 100%;
}
body .bootstrap-select.fit-width .dropdown-toggle .filter-option-inner, body .bootstrap-select.fit-width .dropdown-toggle .filter-option-inner-inner {
    display: inline;
}
body .bootstrap-select.show-menu-arrow.open > .dropdown-toggle, body .bootstrap-select.show-menu-arrow.show > .dropdown-toggle {
    z-index: 1061;
}
body .bootstrap-select.show-menu-arrow.open > .dropdown-toggle .filter-option:before, body .bootstrap-select.show-menu-arrow.show > .dropdown-toggle .filter-option:before, body .bootstrap-select.show-menu-arrow.open > .dropdown-toggle .filter-option:after, body .bootstrap-select.show-menu-arrow.show > .dropdown-toggle .filter-option:after {
    display: block;
}
body .bs-searchbox, body .bs-actionsbox, body .bs-donebutton {
    padding: 4px 8px;
}
body .bs-actionsbox {
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
body .bs-actionsbox .btn-group button {
    width: 50%;
}
body .bs-donebutton {
    float: left;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
body .bs-donebutton .btn-group button {
    width: 100%;
}
body .bs-searchbox {
    position: relative;
}
body .bs-searchbox:after {
    content: '';
    background: url("/assets/images/svg/SVG/loop.svg") 0 0;
    background-repeat: no-repeat;
    background-size: 100% auto;
    width: 1em;
    height: 1.06em;
    font-size: 18px;
    position: absolute;
    top: 0;
    bottom: 5px;
    left: 20px;
    margin: auto;
    opacity: 0.2;
}
body .bs-searchbox .form-control {
    margin-bottom: 0;
    width: 100%;
    float: none;
    margin: 0 auto;
    margin: 0 auto;
    font-size: 14px;
    font-family: inherit;
    appearance: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: block;
    width: 100%;
    padding: 15px 20px 15px 35px;
    font-family: 'ProximaNova-Bold';
    background: nont;
    text-transform: none;
    color: #005166;
    border: 0;
    border-bottom: 1px solid #b8e3ed;
    transition: border 200ms ease;
    line-height: 1.2;
    border-radius: 0;
    box-shadow: 0 4px 13px -17px #000;
}
body .form-is-submitted .bootstrap-select > select.ng-invalid ~ .c-n-form__input {
    border-color: var(--error-dark);
}

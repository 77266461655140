.c-quest-info {
    display: flex;
    padding: 10px;
}
.c-quest-info__item {
    display: flex;
    align-items: center;
    font-size: 12px;
}
.c-quest-info__item:not(:last-child) {
    margin-right: 20px;
}
.c-quest-info__item .icon {
    margin-right: 10px;
}

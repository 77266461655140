.c-quest__inner {
    padding-bottom: 0;
    padding-top: 52px;
}

.c-quest__row {
    border-radius: 0 0 7px 7px;
    margin-bottom: 10px;
}
.c-quest__row:empty {
    display: none;
}
.c-quest__tools {
    transition-property: transform, opacity, visibility;
    transition-timing-function: ease;
    transform-origin: center bottom;
    transition-duration: 300ms;
    background-color: #fff;
    padding-bottom: 10px;
    padding-top: 10px;
    position: fixed;
    z-index: 10;
    width: 100%;
    bottom: 0;
    left: 0;
}
body.has-focus-in .c-quest__tools {
    transform: scale(1, 0);
    visibility: hidden;
    opacity: 0;
}
.c-quest__tools__container {
    align-items: center;
    display: flex;
}
.c-quest__tools__triggers {
    margin-left: auto;
}
.c-quest__tools__triggers .c-btn:not(:last-child) {
    margin-right: 10px;
}
.c-quest__tools__status {
    background: linear-gradient(to right, #ececec 50%, rgba(255,255,255,0) 100%);
    border-radius: 0 2px 2px 0;
    padding-right: 10px;
    padding-left: 35px;
    margin-left: -30px;
    display: flex;
    padding: 5px;
}
.c-quest__progress {
    margin-top: -50px;
}
.c-quest__article {
    display: block;

    // &.progress-bar-displayed {
    //     @media (min-width: $breakpoint-xm) {
    //         margin-top: 40px;
    //     }
    // }

    &__donate {
        margin-bottom: 40px;

        @media (max-width: $breakpoint-xm-max) {
            margin-bottom: 30px;
        }
    }
}
.c-quest__article__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 0 ;

    @media (max-width: $breakpoint-xm-max) {
        padding: 0 20px;
    }
}

.c-quest__article__header__headline {
    max-width: 50%;
    align-items: center;
}
@media (max-width: $quest-detail-new-xs-max) {
    .c-quest__article__header__headline {
        max-width: 80%;
   }
}
.c-quest__article__header__headline.d-flex {
    justify-content: space-between;
    width: 100%;
    max-width: 100%;
    margin-bottom: 12px;
}
.c-quest__article__header__headline__title {
    font-size: 23px;
    font-family: 'ProximaNova-Bold';
    color: var(--bg-text-color);
    text-transform: none;
    margin-bottom: 0;
    line-height: 27px;
}
.c-quest__article__header__headline__btn-cont {
    display: flex;
    align-items: center;
}
.c-quest__article__inner {
    padding: 20px;

    &.gallery {
        padding: 0;
        @media (max-width: $breakpoint-xm-max) {
            padding: 0 20px 20px 20px;
        }
    }

    &.members {
        padding: 30px 25px 5px 25px;

        @media (max-width: $breakpoint-xm-max) {
            padding: 20px 20px 5px 20px;
        }

        &.members-on-profile {
            padding: 30px 40px;

            @media (max-width: $breakpoint-xm) {
                padding: 20px 25px;
            }
        }
    }
}
.c-quest__article__inner__white {
    background: var(--primary-bg-secondary);
    border-radius: 5px;
}

.c-quest__article__inner.members.members-on-profile .c-search__item:first-child .c-my-friends__item.more-padding {
    padding-top: 0;
}
.c-quest__article__inner.members.members-on-profile .c-search__item:last-child .c-my-friends__item.more-padding {
    padding-bottom: 0;
}
.c-quest__article__inner.members .c-quest__doers {
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: space-between;
    @media (max-width: $breakpoint-xm-max) {
        grid-template-columns: auto auto auto auto;
    }

    &.no-grid {
        display: flex;
        justify-content: flex-start;

        > .avatarDiv {
            margin-right: 17px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}


.c-quest__article__inner.members.bg-gray {
    background: var(--bg-milestone);
}
.c-quest__article__inner.team-list {
    padding: 30px 25px 5px 25px;
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: space-between;
    
    @media (max-width: $breakpoint-xm-max) {
        padding: 20px 20px 5px 20px;
    }

    @media (max-width: 650px) {
        grid-template-columns: auto auto auto auto;
    }
}
.c-quest__article__gallery {
    max-width: 330px;
}
@media (max-width: $quest-detail-new-xs-max) {
    .c-quest__article__gallery {
        max-width: 100%;
   }
}
.c-quest__article__body {
    margin-top: 36px;
}
@media (max-width: $quest-detail-new-xs-max) {
    .c-quest-detail-new .c-quest__article__body {
        margin-top: 14px;
   }
}
@media (min-width: $quest-detail-new-sm-min) and (max-width: $quest-detail-new-sm-max) {
    .c-quest-detail-new .c-quest__article__body {
        margin-top: 25px;
   }
}
.c-quest__article__tool {
    text-transform: uppercase;
    text-decoration: none;
    align-items: center;
    font-family: 'ProximaNova-Extrabold';
    font-size: 14px;
    display: flex;
    color: var(--bg-text-color);
}
.c-quest__article__tool {
    cursor: pointer;
    
    .icon-pencil,
    .icon-checked,
    .icon-settings-cog {
        fill: var(--bg-text-color);
    }

    &:hover {
        .icon-pencil,
        .icon-checked,
        .icon-settings-cog {
            fill: var(--accent-color);
        }   
    }
}
.c-quest__article__tool .icon:first-child {
    margin-right: 5px;
}
.c-quest__article__tool .icon:last-child {
    margin-left: 5px;
}
.c-quest__article__title {
    font-size: 22px;
    font-family: 'ProximaNova-Bold';
    text-transform: none;
}
.c-quest__article__subtitle {
    font-size: 16px;
    margin-bottom: 30px;
}
// .c-quest__team-list {
//     margin-top: 40px;
// }
.c-quest__members-list {
    margin-top: 40px;
}
.c-quest__members-list .row__inner {
    padding: 40px;
}
.c-quest__tooltip {
    margin-left: 7px;
}
.c-quest__doer {
    text-decoration: none;
    border-radius: 50%;
    position: relative;
    display: block;
    height: auto;
    width: 60px;

    &.left-users-count {
        width: 60px;
        height: 60px;
        background: var(--accent-color);
        display: flex;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        font-family: 'ProximaNova-Bold';
        color: var(--btn-text-light);
    }
}
.c-quest__doer.has-progress .c-quest__doer__img {
    margin: auto;
    height: 80%;
    width: 80%;
    bottom: 0;
}
.c-quest__doer .c-quest-profile__img {
    position: relative;
}
.c-quest__doer__img {
    border-radius: 50%;
    overflow: hidden;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
}
.c-quest__doer:not(.is-visible):nth-child(13) {
    right: 0;
}
.c-quest__doer:not(.is-visible):nth-child(13) ~ .c-quest__doer {
    display: none;
}
.c-quest__doer:not(.is-visible):nth-child(13) + .c-quest__doer ~ .c-quest__doers__more {
    display: flex;
}
@media (max-width: 650px) {
    .c-quest__doer {
        font-size: 10px;
   }
}
.c-quest__doer__progress {
    position: absolute !important;
    height: 100%;
    width: 100%;
    left: 0;
    top: -30px;
}
.c-quest__doer__amount {
    letter-spacing: 0.022em;
    text-decoration: none;
    text-align: center;
    font-family: 'ProximaNova-Semibold';
    color: var(--bg-text-color);
    font-size: 12px;
}
.c-quest__mode-select {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    font-size: 1.4rem;
    font-family: 'ProximaNova-Bold';
    background: #ceeff6;
    max-width: 38rem;
}
.c-quest-manage__article {
    margin-bottom: 25px;
}
.c-quest-manage__article__title {
    text-transform: none;
    font-size: 32px;
    margin-bottom: 8px;
}
.c-quest-manage__article__subtitle {
    font-size: 16px;
    margin-bottom: 30px;
}
.c-quest-manage__article__body {
    margin-top: 0;
    margin-bottom: 5px;
    font-size: 12px;
}
@media (max-width: 769px) {
    .c-quest-manage > .c-container {
        padding-left: 0;
        padding-right: 0;
   }
}
.c-quest-manage .c-n-form__legend {
    padding-top: 0;
    padding-bottom: 10px;
}

.c-quest-manage .quest-settings .c-n-form__legend {
    padding-bottom: 0;
}
.c-quest-manage .quest-settings .c-n-form__legend.pb-bottom {
    padding-bottom: 10px;
}
.c-quest-manage .quest-settings .c-n-form__group {
    margin-bottom: 0;
    padding-bottom: 20px;
    padding-top: 20px;
    border-bottom: solid 1px #c5c9cb;

    &.no-border-bottom {
        border-bottom: 0;
    }
}
.c-quest-manage__header {
    margin-right: 10px;
    align-items: flex-start;
    display: flex;
}
.c-quest-manage__forms-group {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}
.c-quest-manage__mode-select {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    font-size: 1.4rem;
    font-family: 'ProximaNova-Bold';
    background: #ceeff6;
    max-width: 38rem;
}

.c-breadcrumbs {
    background-color: #83cad9;
    margin-bottom: 26px;
}
@media (min-width: $quest-detail-new-sm-min) {
    .c-breadcrumbs {
        display: inline-block;
        border-radius: 14px;
        padding: 7px 25px;
        font-size: 12px;
   }
}
@media (max-width: $quest-detail-new-xs-max) {
    .c-breadcrumbs {
        margin-right: - $gutter-xs;
        margin-left: - $gutter-xs;
        padding-right: $gutter-xs;
        padding-left: $gutter-xs;
        padding-bottom: 9px;
        padding-top: 9px;
        font-size: 9px;
   }
}
.c-breadcrumbs ul {
    list-style-type: none;
    margin-bottom: 0;
    padding-left: 0;
    flex-wrap: wrap;
    display: flex;
}
@media (max-width: $quest-detail-new-xs-max) {
    .c-breadcrumbs ul {
        justify-content: center;
   }
}
.c-breadcrumbs li:not(:last-child) {
    padding-right: 0.3em;
}
.c-breadcrumbs li:not(:last-child):after {
    color: rgba(0,0,0,0.7);
    font-family: 'ProximaNova-Bold';
    content: ' >';
}
.c-breadcrumbs a {
    color: rgba(0,0,0,0.7);
    letter-spacing: 0.025em;
    text-decoration: none;
    white-space: nowrap;
    font-family: 'ProximaNova-Bold';
}

.c-quest-detail-new {
    background-color: #a6e0ec;
    padding-bottom: 0;
    padding-top: 0;

    .c-quest-detail__layout {
        &__creator-cont {
            font-size: 12px;
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 0;
            color: var(--accent-color);
            list-style-position: inside;
            
            @media (max-width: $breakpoint-xs-max) {
                flex-wrap: wrap;
            }

            span {
                color: var(--bg-text-color-light);
            }
            
            .created-by-parent {
                display: flex;
                flex-direction: row;
                align-items: center;

                @media (max-width: $breakpoint-xs-max) {
                    width: 100% !important;
                }

                app-profile-avatar {
                    display: block;
                    width: 22px;
                    height: 22px;
                    border: solid 1px var(--accent-color);
                    cursor: pointer;
                }

                span:last-child {
                    cursor: pointer;
                    display: inline-block;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    max-width: 90px;
                    word-break: break-all;
                    overflow: hidden;
                }
            }

            .dot {
                margin: 0 0 0 10px;
                padding: 0;

                &.views-count {
                    @media (max-width: $breakpoint-xs-max) {
                        margin-left: 0;
                        list-style: none;
                    }   
                }
            }

            .no-dot {
                list-style: none;
            }
        }
    }
}
.c-quest-detail-new .container {
    background-color: rgba(134,208,225,0.6);
    padding-right: $gutter-md;
    padding-left: $gutter-md;
    border-radius: 10px;
    margin-right: auto;
    margin-left: auto;
    width: $container-md;
}
@media (min-width: $quest-detail-new-sm-min) {
    .c-quest-detail-new .container {
        max-width: calc(100% - 60px);
   }
}
@media (min-width: $quest-detail-new-sm-min) and (max-width: $quest-detail-new-sm-max) {
    .c-quest-detail-new .container {
        padding-right: $gutter-sm;
        padding-left: $gutter-sm;
        width: $container-md;
   }
}
@media (max-width: $quest-detail-new-xs-max) {
    .c-quest-detail-new .container {
        padding-right: $gutter-xs;
        padding-left: $gutter-xs;
        width: auto;
   }
}
.c-quest-detail-new .row__inner {
    background: primary-bg;
    border-radius: 5px;
}
.c-quest-detail-new .row__inner.no-bg {
    background: transparent;
}
.c-quest-detail-new .row__inner.no-padding {
    padding: 0;
}
@media (min-width: $quest-detail-new-sm-min) and (max-width: $quest-detail-new-sm-max) {
    .c-quest-detail-new .row__inner {
        padding-right: $gutter-sm;
        padding-left: $gutter-sm;
   }
}
@media (max-width: $quest-detail-new-xs-max) {
    .c-quest-detail-new .row__inner {
        padding-right: $gutter-xs;
        padding-left: $gutter-xs;
   }
}
.c-quest-detail-new .c-quest-tabs__content {
    padding: 0;
}
.c-quest-detail-new .c-quest-tabs__content.mt-0 {
    margin-top: 0;
}
@media (min-width: $quest-detail-new-md-min) {
    .c-quest-detail-new .c-quest-tabs__content {
        margin-top: 72px;
   }
}
@media (min-width: $quest-detail-new-sm-min) and (max-width: $quest-detail-new-sm-max) {
    .c-quest-detail-new .c-quest-tabs__content {
        margin-top: 28px;
   }
}
@media (max-width: $quest-detail-new-xs-max) {
    .c-quest-detail-new .c-quest-tabs__content {
        margin-top: 20px;
   }
}
.c-quest-detail-new .c-quest-detail__header .c-profile-new__header__photo__bg__upload {
    z-index: 1;
}
.c-quest-detail-new .c-quest-detail__header .blank-bg {
    background: $lightGreyColor;
}
.c-quest-detail-new .c-quest-detail__header__img {
    overflow: hidden;
}
@media (min-width: $quest-detail-new-sm-min) {
    .c-quest-detail-new .c-quest-detail__header__img {
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 1;
        bottom: 0;
        right: 0;
        left: 0;
        top: 0;
   }
}
@media (min-width: $breakpoint-xs) and (max-width: $quest-detail-new-xs-max) {
    .c-quest-detail-new .c-quest-detail__header__img {
        height: 340px;
   }
}
@media (max-width: $breakpoint-xs-max) {
    .c-quest-detail-new .c-quest-detail__header__img {
        height: 200px;
   }
}
.c-quest-detail-new .c-quest-detail__header__title {
    text-align: center;
    background: linear-gradient(0deg, #2e3638 0%, rgba(41,89,103,0) 100%);
    position: absolute;
    z-index: 2;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 0 39px;
    @media (max-width: $breakpoint-xs-max) {
        padding: 0 20px;
    }

    &.with-progress-bar {
        @media (max-width: $breakpoint-xs-max) {
            padding: 0 90px 0 20px;

            h1 {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }   
    }
}
.c-quest-detail-new .c-quest-detail__header__title__created, .c-quest-detail-new .c-quest-detail__header__title__top {
    letter-spacing: -0.008333333333333em;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    display: flex;
}
@media (min-width: $quest-detail-new-sm-min) {
    .c-quest-detail-new .c-quest-detail__header__title__created, .c-quest-detail-new .c-quest-detail__header__title__top {
        color: #fff;
   }
}
@media (max-width: $quest-detail-new-xs-max) {
    .c-quest-detail-new .c-quest-detail__header__title__created, .c-quest-detail-new .c-quest-detail__header__title__top {
        font-family: 'ProximaNova-Bold';
   }
}
.c-quest-detail-new .c-quest-detail__header__title__top {
    padding-bottom: 14px;
}
@media (min-width: $quest-detail-new-sm-min) and (max-width: $quest-detail-new-sm-max) {
    .c-quest-detail-new .c-quest-detail__header__title__top {
        padding-bottom: 6px;
   }
}
@media (max-width: $quest-detail-new-xs-max) {
    .c-quest-detail-new .c-quest-detail__header__title__top {
        padding-bottom: 2px;
   }
}
.c-quest-detail-new .c-quest-detail__header__title__category {
    text-transform: uppercase;
}
.c-quest-detail-new .c-quest-detail__header__title__dot {
    margin-right: 4px;
    margin-left: 4px;
}
.c-quest-detail-new .c-quest-detail__header__title__shield {
    display: inline-block;
    font-size: 0.8125em;
    margin-left: 0.1em;
    color: #ff0;
}
.c-quest-detail-new .c-quest-detail__header__title .icon {
    margin-right: 4px;
    margin-top: -3px;
    display: block;
}
.c-quest-detail-new .c-quest-detail__header__title h1 {
    text-transform: none;
    font-family: 'ProximaNova-Bold';
    text-align: left;
    color: #fff;
}
@media (min-width: $quest-detail-new-sm-min) {
    .c-quest-detail-new .c-quest-detail__header__title h1 {
        font-size: 28px;
        line-height: 42px;
   }
}
@media (min-width: $quest-detail-new-sm-min) and (max-width: $quest-detail-new-sm-max) {
    .c-quest-detail-new .c-quest-detail__header__title h1 {
        font-size: 24px;
        line-height: 38px;
   }
}
@media (max-width: $quest-detail-new-sm-max) {
    .c-quest-detail-new .c-quest-detail__header__title h1 {
        margin-bottom: 6px;
   }
}
@media (max-width: $quest-detail-new-xs-max) {
    .c-quest-detail-new .c-quest-detail__header__title h1 {
        font-size: 20px;
        line-height: 32px;
   }
}
.c-quest-detail-new .c-quest-detail__header__dropdown__trig {
    transition: background-color 0.3s ease;
    background-color: #83cad9;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    padding: 12px;
    display: flex;
    border: none;
}
@media (max-width: $quest-detail-new-sm-max) {
    .c-quest-detail-new .c-quest-detail__header__dropdown__trig {
        flex-direction: column;
        padding: 6px 3px;
        min-width: 55px;
   }
}
.c-quest-detail-new .c-quest-detail__header__dropdown__trig:hover {
    background-color: #48b1c7;
}
.c-quest-detail-new .c-quest-detail__header__dropdown__trig__txt {
    letter-spacing: 0.026666666666667em;
    color: rgba(0,0,0,0.7);
    text-transform: uppercase;
    font-family: 'ProximaNova-Extrabold';
    font-size: 15px;
}
@media (max-width: $quest-detail-new-sm-max) {
    .c-quest-detail-new .c-quest-detail__header__dropdown__trig__txt {
        font-size: 10px;
   }
}
.c-quest-detail-new .c-quest-detail__header__dropdown__trig__icon {
    background-color: #000;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    margin-left: 8px;
    display: flex;
    color: #fff;
    height: 14px;
    width: 35px;
}
@media (max-width: $quest-detail-new-sm-max) {
    .c-quest-detail-new .c-quest-detail__header__dropdown__trig__icon {
        margin-left: 0;
   }
}
.c-quest-detail-new .c-quest-detail__header__dropdown__trig .icon {
    display: block;
}
.c-quest-detail-new .c-quest-detail__header__dropdown__menu {
    transition: visibility 0.3s ease, opacity 0.3s ease;
    background-color: var(--white-bg-original);
    border-radius: 5px;
    position: absolute;
    margin-top: 4px;
    min-width: 100%;
    padding: 10px 0;
    top: 100%;
    right: 0;
    z-index: 9;
    box-shadow: 0px 4px 4px 0 rgba(0,0,0,0.5);
    min-height: 60px;
    max-width: unset !important;

    ul {
        list-style-type: none;
        margin-bottom: 0;
        padding-left: 0;
    }

    li {
        white-space: nowrap;

        &.only-mobile-col {
            display: none;
            @media (max-width: $breakpoint-xs) {
                display: block;
            }
        }
    }

    a,
    li > span {
        padding: 10px 20px;
        text-decoration: none;
        align-items: center;
        position: relative;
        color: var(--bg-text-color);
        font-size: 14px;
        display: flex;
        align-items: center;
        text-align: left;
        font-family: 'ProximaNova-Regular';
        cursor: pointer;
        text-transform: none;

        &:hover {
            text-decoration: none;
            background-color: var(--search-hover-bg);
        }

        .icon {
            margin-right: 10px;
        }

        .icon-span {
            display: inline-flex;
            height: 20px;
            width: 20px;
            background: var(--accent-color);
            margin-right: 10px;
            border-radius: 5px;
            align-items: center;
            justify-content: center;

            .icon {
                margin-right: 0;
                fill: var(--btn-text-light);
                width: 12px;
            }
        }
    }
}

.c-quest-detail-new .c-quest-detail__header__dropdown__menu a span:not(.icon-span) {
    flex-grow: 1;
}
.c-quest-detail-new .c-quest-detail__header__progress {
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border-radius: 50%;
    position: absolute;
    color: var(--bg-text-color);
    bottom: -15px;
    display: flex;
    height: 165px;
    width: 165px;
    right: 15px;
    z-index: 2;
}
@media (min-width: $quest-detail-new-sm-min) and (max-width: $quest-detail-new-sm-max) {
    .c-quest-detail-new .c-quest-detail__header__progress {
        height: 120px;
        bottom: -15px;
        width: 120px;
   }
}
@media (max-width: $quest-detail-new-xs-max) {
    .c-quest-detail-new .c-quest-detail__header__progress {
        height: 88px;
        top: -200%;
        width: 88px;
        right: 5px;
   }
}
.c-quest-detail-new .c-quest-detail__header__progress__value {
    letter-spacing: 0.006111111111111em;
    position: relative;
    font-family: 'ProximaNova-Bold';
    font-size: 30px;
    line-height: 40px;
    z-index: 2;
    top: 0;
}
@media (max-width: $quest-detail-new-xs-max) {
    .c-quest-detail-new .c-quest-detail__header__progress__value {
        font-size: 18px;
   }
}
.c-quest-detail-new .c-quest-detail__header__progress__value sub {
    letter-spacing: 0.007692307692308em;
    font-size: 0.722222222222222em;
    bottom: 0;
}
.c-quest-detail-new .c-quest-detail__header__progress__title, .c-quest-detail-new .c-quest-detail__header__progress__status {
    letter-spacing: 0.018181818181818em;
    text-transform: uppercase;
    text-align: center;
    position: relative;
    font-family: 'ProximaNova-Bold';
    font-size: 11px;
    z-index: 2;
    top: -6px;
}
@media (max-width: $quest-detail-new-xs-max) {
    .c-quest-detail-new .c-quest-detail__header__progress__title, .c-quest-detail-new .c-quest-detail__header__progress__status {
        font-size: 8px;
   }
}
.c-quest-detail-new .c-quest-detail__header__progress app-quest-progress {
    position: absolute;
    margin: auto;
    height: 100%;
    width: 100%;
    z-index: 1;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
}
.c-quest-detail-new .c-quest-detail__header__progress app-quest-progress .c-quest-progress__progress__value {
    stroke: var(--accent-color);
}
.c-quest-detail-new .c-quest-detail__header__progress app-quest-progress .c-quest-progress__progress {
    font-size: 165px;
    filter: drop-shadow(0px 4px 4px rgba(0,0,0,0.1));
}
@media (min-width: $quest-detail-new-sm-min) and (max-width: $quest-detail-new-sm-max) {
    .c-quest-detail-new .c-quest-detail__header__progress app-quest-progress .c-quest-progress__progress {
        font-size: 120px;
   }
}
@media (max-width: $quest-detail-new-xs-max) {
    .c-quest-detail-new .c-quest-detail__header__progress app-quest-progress .c-quest-progress__progress {
        font-size: 88px;
   }
}
.c-quest-detail-new .c-quest-detail__header .c-profile-new__header__photo__bg__upload {
    left: calc(50% - 20px);
    top: 30px;
}
.c-quest-detail-new .c-quest-detail__timelines {
    padding: 0 25px;
    flex-direction: row;
    font-size: 14px;
}
.c-quest-detail-new .c-quest-detail__timelines__item {
    margin-bottom: 14px;
    align-items: center;
}
.c-quest-detail-new .c-quest-detail__timelines__left {
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
}
.c-quest-detail-new .c-quest-detail__timelines__right > div:not(:last-child) {
    margin-bottom: 15px;
}
.c-quest-detail-new .c-quest-detail__quest-dt-block {
    margin: 0 0 30px 0;
    background: var(--primary-bg-secondary);
    border-radius: 5px;
    padding: 20px;
    @media (max-width: $breakpoint-xm-max) {
        margin: 0;
    }

    &__name-image {
        min-height: 50px;

        &__avatars {
            justify-content: center;
            position: relative;
            display: flex;
            margin-right: 15px;
            float: left;

            .c-profile-new__header__photo__image-upload {
                z-index: 5;
                left: auto;
                bottom: 0;
                right: 0;
                width: 25px;
                height: 25px;
                
                &:hover {
                    opacity: 1;
                }

                .icon {
                    width: 18px;
                    height: 18px;
                }
            }

            app-profile-avatar, 
            .c-avatar {
                border-style: none;
                cursor: pointer;
                flex-shrink: 0;
                position: relative;
                z-index: 1;
                width: 50px;
                height: 50px;
            }
        }
    }
}


.c-quest-detail-new .c-quest-detail__quest-dt-block__name-image__avatars app-profile-avatar:hover, .c-quest-detail-new .c-quest-detail__quest-dt-block__name-image__avatars .c-avatar:hover {
    z-index: 2;
}
.c-quest-detail-new .c-quest-detail__quest-dt-block__name-image__avatars app-profile-avatar:not(:first-child), .c-quest-detail-new .c-quest-detail__quest-dt-block__name-image__avatars .c-avatar:not(:first-child) {
    margin-left: -23px;
}
.c-quest-detail-new .c-quest-detail__quest-dt-block__name-image__avatars app-profile-avatar:not(:first-child).team-logo, .c-quest-detail-new .c-quest-detail__quest-dt-block__name-image__avatars .c-avatar:not(:first-child).team-logo {
    box-shadow: -1px 0px 3px 0px #000;
    border-radius: 8px;
    width: 50px;
    height: 50px;
}
.c-quest-detail-new .c-quest-detail__quest-dt-block__name-image__name {
    display: flex;
    align-items: center;
    min-height: 50px;
}
.c-quest-detail-new .c-quest-detail__quest-dt-block__name-image__name__user-name {
    cursor: pointer;
    color: var(--bg-text-color);
    font-size: 22px;
    font-family: 'ProximaNova-Bold';
    line-height: 20px;
}

@media (min-width: $quest-detail-new-md-min) {
    .c-quest-detail-new .c-quest-detail__layout__sidebar {
        margin-right: 25px;
        max-width: 315px;
        width: 28%;
   }
}
@media (min-width: $quest-detail-new-sm-min) {
    .c-quest-detail-new .c-quest-detail__layout__sidebar {
        flex-shrink: 0;
   }
}
@media (min-width: $quest-detail-new-sm-min) and (max-width: $quest-detail-new-sm-max) {
    .c-quest-detail-new .c-quest-detail__layout__sidebar {
        margin-right: 5px;
        width: 30%;
        max-width: 300px;
   }
}
@media (max-width: $quest-detail-new-xs-max) {
    .c-quest-detail-new .c-quest-detail__layout__sidebar {
        width: auto;
   }
}
.c-quest-detail-new .c-quest-detail__layout__sidebar > .c-quest-detail__main {
    background: #e5eaed;
    padding: 35px 50px;
}
@media (max-width: $quest-detail-new-xs-max) {
    .c-quest-detail-new .c-quest-detail__layout__sidebar > .c-quest-detail__main {
        padding-bottom: 28px;
   }
}
.c-quest-detail-new .c-quest-detail__layout__content {
    padding: 25px 40px;

    @media (min-width: $quest-detail-new-sm-min) {
        flex-grow: 1;
    }


    @media (min-width: $quest-detail-new-sm-min) and (max-width: $quest-detail-new-sm-max) {
        padding-top: 20px 30px;
    }


    @media (max-width: $breakpoint-xm-max) {
            padding: 0;
            font-size: 12px;
    }

    .quest-info-box {
        @media (max-width: $breakpoint-xm-max) {
            padding: 20px 20px 0 20px;
        }
    }
}

.c-quest-detail-new .c-quest-detail__layout__content.extra-top-pad {
    padding-top: 70px;
}
.c-quest-detail-new .c-quest-detail__layout__content__highlight {
    font-size: 12px;
    margin-bottom: 15px;

    @media (max-width: $breakpoint-xm-max) {
        display: block !important;
        margin-bottom: 15px;
    }

    .border-separation-top {
        margin-top: 10px;
        display: none;

        @media (max-width: $breakpoint-xm-max) {
            display: block;
        }
        
        &::before {
            margin-left: 0;
            width: 100%;
        }
    }
}
.c-quest-detail-new .c-quest-detail__layout__content__video {
    margin-top: 20px;
}
.c-quest-detail-new .c-quest-detail__layout__content__video-container {
    width: 100%;
    position: relative;
}
.c-quest-detail-new .c-quest-detail__layout__content__video-container:before {
    padding-bottom: 56.6%;
    display: block;
    content: '';
    width: 100%;
}
.c-quest-detail-new .c-quest-detail__layout__content__video-container iframe {
    position: absolute;
    display: block;
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    border: 0;
    left: 0;
    top: 0;
}
.c-quest-detail-new .c-quest-detail__layout__content__milestones {
    padding-top: 50px;

    @media (max-width: $breakpoint-xm-max) {
        padding-top: 20px;
    }
}
.c-quest-detail-new .c-quest-detail__main {
    flex-direction: column;
    align-items: center;
    display: flex;
    padding-top: 2rem;
}
.c-quest-detail-new .c-quest-detail__main__edit {
    font-size: 12px;
}
.c-quest-detail-new .c-quest-detail__main__confirm {
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin-top: 10px;
}
.c-quest-detail-new .c-quest-detail__main__user-info {
    flex-direction: row;
    margin-top: 11px;
    display: flex;
    align-items: baseline;
    margin-left: 10px;
}
@media (min-width: $quest-detail-new-sm-min) and (max-width: $quest-detail-new-sm-max) {
    .c-quest-detail-new .c-quest-detail__main__user-info {
        margin-top: 23px;
   }
}
@media (max-width: $quest-detail-new-xs-max) {
    .c-quest-detail-new .c-quest-detail__main__user-info {
        align-items: center;
        margin-top: 12px;
   }
}
.c-quest-detail-new .c-quest-detail__main__user-info-confirm {
    flex-direction: column;
}
.c-quest-detail-new .c-quest-detail__main__user-group, .c-quest-detail-new .c-quest-detail__main__user-address {
    color: rgba(0,0,0,0.7);
    letter-spacing: 0.025em;
    margin-bottom: 4px;
    font-family: 'ProximaNova-Bold';
    font-size: 12px;
}
.c-quest-detail-new .c-quest-detail__main__tools {
    align-items: flex-start;
    flex-direction: column;
    margin-top: 30px;
    display: flex;
    width: 100%;
}
@media (max-width: $quest-detail-new-xs-max) {
    .c-quest-detail-new .c-quest-detail__main__tools {
        align-items: center;
        margin-top: 10px;
   }
}
.c-quest-detail-new .c-quest-detail__main__tools .c-btn {
    margin-bottom: 9px;
    width: 100%;
}
.c-quest-detail-new .c-quest-detail__main__tools .c-btn small {
    text-transform: none;
    font-size: 0.625em;
}
.c-quest-detail-new .c-quest-detail__main__siblings-link {
    font-size: 1.4rem;
    display: block;
    width: 100%;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    color: var(--accent-color);
}
@media (max-width: $quest-detail-new-xs-max) {
    .c-quest-detail-new .tabs-nav li {
        width: auto;
   }
}
@media (max-width: $quest-detail-new-xs-max) {
    .c-quest-detail-new .tabs-nav li.active a {
        background-color: #93d7e5 !important;
   }
}
@media (min-width: $quest-detail-new-sm-min) and (max-width: $quest-detail-new-sm-max) {
    .c-quest-detail-new .tabs-nav li a {
        padding: 17px 50px 15px !important;
   }
}
@media (max-width: $quest-detail-new-xs-max) {
    .c-quest-detail-new .tabs-nav li a {
        font-size: 10px !important;
        padding: 15px !important;
   }
}
.c-quest-detail-new .tabs-nav li a span {
    float: none !important;
}
.c-quest-detail-new .c-checkbox.as-icon {
    min-height: 24px;
    height: 24px;
    width: 24px;
}
@media (max-width: $quest-detail-new-xs-max) {
    .c-quest-detail-new .c-checkbox.as-icon {
        min-height: 17px;
        height: 17px;
        width: 17px;
   }
}
.c-quest-detail-new .c-checkbox:not(.is-loading) > input:checked ~ .c-checkbox__icon {
    background-color: rgba(127,201,219,0.498);
}
.c-quest-detail-new .c-checkbox:not(.is-loading) > input:checked ~ .c-checkbox__icon .icon {
    color: #2dcc71;
}
.c-quest-detail-new .c-checkbox__icon {
    background-color: #7fc9db;
    height: 24px;
    width: 24px;
}
@media (max-width: $quest-detail-new-xs-max) {
    .c-quest-detail-new .c-checkbox__icon {
        height: 17px;
        width: 17px;
   }
}
.c-quest-detail-new .c-checkbox__icon .icon {
    font-size: 24px;
}
@media (max-width: $quest-detail-new-xs-max) {
    .c-quest-detail-new .c-checkbox__icon .icon {
        font-size: 17px;
   }
}
.c-quest-detail-new app-quest-milestones {
    background-color: #c3e8f1;
    padding-bottom: 29px;
    padding-right: 30px;
    margin-bottom: 26px;
    padding-left: 30px;
    padding-top: 48px;
    display: block;
}
@media (min-width: $quest-detail-new-md-min) {
    .c-quest-detail-new app-quest-milestones {
        border-radius: 6px;
   }
}
@media (min-width: $quest-detail-new-sm-min) and (max-width: $quest-detail-new-sm-max) {
    .c-quest-detail-new app-quest-milestones {
        padding-right: 20px;
        padding-left: 20px;
        padding-top: 34px;
   }
}
@media (max-width: $quest-detail-new-xs-max) {
    .c-quest-detail-new app-quest-milestones {
        padding-bottom: 22px;
        padding-right: 12px;
        padding-left: 12px;
        padding-top: 24px;
        margin-bottom: 0;
   }
}

app-quest-header-tools {
    display: block;
}
.temp-hidden {
    display: none !important;
}
app-fundraise-switch {
    display: block;
}
app-fundraise-switch.fundraise-switch__disabled .e-toggle {
    pointer-events: none;
    opacity: 0.6;
}
.switch__disabled .e-toggle {
    pointer-events: none;
    opacity: 0.6;
}
#upload-team-image-modal .c-btn {
    margin-top: 10px;
}
app-map-view {
    z-index: 1;
    height: 100vh;
    display: block;
}

.border-separation-top {
    position: relative;
    display: none;
    
    @media (max-width: $breakpoint-xm-max) {
        display: block;
    }

    &::before {
        content: '';
        height: 1px;
        width: calc(100% - 40px);
        margin-left: 20px;
        position: absolute;
        top: 0;
        left: 0;
        background: var(--platinum);
        display: none;

        @media (max-width: $breakpoint-xm-max) {
            display: block;
        }
    }
}

.mobile-pad-lr-20 {
    @media (max-width: $breakpoint-xm-max) {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.c-gallery__tools {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5rem;
    font-size: 2rem;
    color: var(--bg-text-color);

    &__arrow-left {
        margin-right: 10px;
    }

    &__arrow-right {
        margin-left: 10px;
    }
}
.c-gallery__tools__arrow-left.slick-disabled,  
.c-gallery__tools__arrow-right.slick-disabled {
    opacity: 0.5;
}

.c-quest-detail {
    &__action-cta-cont {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;
        min-width: 140px;

        @media (max-width: $breakpoint-xs-max) {
            margin-top: 25px;
        }

        button {
            margin: 0;
        }

        .icon {
            width: 12px;
            height: 12px;
        }
    }
}

/**
    c-quest-manage common css
**/
.c-quest-manage {
    background: var(--primary-bg-secondary);
    color: var(--bg-text-color);
    padding: 4rem 3.5rem;
    margin-bottom: 3rem;
    @media (max-width: 650px) {
        padding: 7rem 1rem;
    }        

    &__footer {
        padding: 12px 40px;
        border-top: solid 1px #c5c9cb;
        background: var(--primary-bg-secondary);
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 101;
        width: 100%;

        &__inner {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .e-btn {
                margin: 0;
            }
        
        
            @media (max-width: $breakpoint-xs) {
                flex-direction: column-reverse;
        
                > div {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                }
        
                .e-btn {
                    width: 100%;
                    margin: 0 0 10px 0 !important;
                }
            }
        }
    }
}

.c-nl-statistics {
	display: flex;
	flex-wrap: wrap;
	margin-left: -1rem;
	margin-right: -1rem;
}
@media (min-width: $screen-md-min) {
	.c-nl-statistics {
		margin-left: -1.5rem;
		margin-right: -1.5rem;
   }
}
.c-nl-statistics__col {
	width: 33.33%;
	padding-left: 1rem;
	padding-right: 1rem;
}
@media (min-width: $screen-md-min) {
	.c-nl-statistics__col {
		padding-left: 1.5rem;
		padding-right: 1.5rem;
   }
}
@media (max-width: $screen-sm-max) {
	.c-nl-statistics__col {
		width: 100%;
		margin-bottom: 1rem;
   }
}
.c-nl-statistics__card {
	border-radius: 0.8rem;
	border: solid 1px rgba(94,183,203,0.37);
	background-color: #fff;
	padding: 2rem 1rem;
	min-height: 100%;
	box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.06);
}
.c-nl-statistics__card__view {
	position: relative;
	height: 6rem;
	margin-bottom: 0;
}
.c-nl-statistics__card__view .icon {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	font-size: 8rem;
	color: $casal;
}
@media (min-width: $screen-md-min) {
	.c-nl-statistics__card__view .icon {
		width: auto;
		height: 50px;
   }
}
.c-nl-statistics__card__caption {
	padding-top: 2rem;
}
.c-nl-statistics__card__ttl {
	font-size: 1.8rem;
	font-family: 'ProximaNova-Bold';
	text-align: center;
	color: #2e3638;
	line-height: 1.2;
	margin-bottom: 1rem;
	text-transform: capitalize;
}
@media (min-width: $screen-md-min) {
	.c-nl-statistics__card__ttl {
		font-size: 2rem;
   }
}
.c-nl-statistics__card__content {
	max-width: 23rem;
	margin: 0 auto;
	font-size: 1.4rem;
	text-align: center;
	color: #2e3638;
	line-height: 1.2;
	margin-bottom: 0;
}

$lg-pad-v: 4rem; // horizontal padding
$lg-pad-h: 4rem; // horizontal padding
$md-pad-v: 2rem; // horizontal padding
$md-pad-h: 2rem; // horizontal padding

.c-dl-ns-post {
    display: block;
    max-width: 800px;
    width: 100%;
    margin: auto;
    font-size: $DL-h4;
    background: var(--white-section-bg);
    border-radius: 5px;
    padding: 20px;
    overflow: hidden;
    margin-bottom: 1rem;

    &__like-name {
        margin-left: 8px;
        font-size: 14px;
        color: var(--bg-text-color);
        font-family: 'ProximaNova-Semibold';
        cursor: pointer;
        @media (max-width: $breakpoint-xm-max) {
            font-size: 12px;
        }
    }

    &__comment-counter {
        font-size: 14px;
    }

    &__cheer-modal {
        .c-my-friends__item {
            &:first-child {
                padding-top: 0;
            }

            &:last-child {
                padding-bottom: 0;
            }
        }
    }

    &__header {
        justify-content: space-between;
        position: relative;
        align-items: flex-start;
    }

    &__post-options {
        display: flex;
        flex-direction: row;
    }

    &__share-icon {
        cursor: pointer;
    }

    &__options-parent {
        display: flex;
        align-items: flex-start;
        position: relative;
        // top: 0;
        // right: 0;
        z-index: 3;
        color: var(--bg-text-color);
        margin-left: 10px;

        .dots {
            font-size: 28px;
            font-weight: bold;
            line-height: 20px;
            cursor: pointer;
        }
    }
}
@media (max-width: $screen-md-max) {
    .c-dl-ns-post {
        font-size: DL-h5;
        max-width: 70%;
   }
}
@media (max-width: $screen-xs-max) {
    .c-dl-ns-post {
        max-width: 100%;
        font-size: DL-h6;
   }
}
.c-dl-ns-post__footer, .c-dl-ns-post__header {
    display: flex;
    align-items: center;
}

.c-dl-ns-post__profile {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-family: 'ProximaNova-Bold';
    text-decoration: none !important;
    color: var(--bg-text-color);
}
@media (max-width: $screen-sm-max) {
    .c-dl-ns-post__profile {
        font-size: DL-h3;
   }
}
@media (max-width: $screen-xs-max) {
    .c-dl-ns-post__profile {
        font-size: $DL-h4;
        max-width: calc(100% - 7rem);
   }
}
.c-dl-ns-post__profile .c-avatar {
    font-size: 5.5rem;
    margin-right: 1rem;
    flex-shrink: 0;

    @media (max-width: $screen-sm-max) {
        font-size: 3rem;
        margin-right: 0.5rem;
    }
}

.c-dl-ns-post__profile > span {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.c-dl-ns-post__profile__name {
    color: var(--bg-text-color);
}
.c-dl-ns-post__tools {
    display: flex;
    margin-left: auto;
}
.c-dl-ns-post__body {
    display: block;
    padding-top: 10px;
    padding-bottom: 20px;
}
.c-dl-ns-post__caption {
    font-size: 16px;
}
.c-dl-ns-post__caption.has-image {
    padding-bottom: 15px;
}
.c-dl-ns-post__caption h2 {
    font-size: 18px;
    font-family: 'ProximaNova-Bold';
}
.c-dl-ns-post__preview {
    position: relative;
    max-width: 100%;
    max-height: 440px;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 200%;
        height: 100%;
        margin-left: -50%;
        z-index: 1;
        background: var(--bg-white-ghost);
    }

    &__inner {
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 2;

        img {
            display: block;
            max-height: 400px;
            width: auto;
            margin: auto;
        }
    }
}

.c-dl-ns-post__caption {
    color: var(--bg-text-color);
}
.c-dl-ns-post__caption h2 {
    text-transform: none;
}
.c-dl-ns-post__date {
    font-size: 14px;
    font-family: 'ProximaNova-Semibold';
    margin-top: 0;
    color: var(--bg-text-color-light);
}
.c-dl-ns-post__date .edited-title {
    position: relative;
    margin-left: 20px;
}
.c-dl-ns-post__date .edited-title .dot::before {
    content: '';
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: var(--bg-text-color-light);
    position: absolute;
    left: -12px;
    top: calc(40%);
}
.c-dl-ns-post__footer {
    padding: 6px 0;
    justify-content: space-between;
}
.c-dl-ns-post__footer__line::after {
    content: '';
    width: 1000%;
    height: 1px;
    margin-left: -50%;
    display: block;
    border-top: solid 1px $lightGreyColor;
}
.c-dl-ns-post__tool {
    display: flex;
    align-items: center;
    font-family: 'ProximaNova-Bold';
}
.c-dl-ns-post__tool:not(:last-child) {
    margin-right: 1em;
}
.c-dl-ns-post__tool.comment-icon .icon {
    font-size: 1.2em;
}
.c-dl-ns-post__tool .icon {
    font-size: 1.3em;
}
.c-dl-ns-post__like .icon {
    display: block;
    cursor: pointer;
}
.c-dl-ns-post__counter {
    margin: 0 0.2em 0 0.4em;
    font-family: 'ProximaNova-Semibold';
    color: var(--bg-text-color);
    font-size: 14px;
}
.c-dl-ns-post__bottom {
    max-height: 5.4rem;
    overflow: hidden;
    transition: max-height 300ms ease;
}
.c-dl-ns-post__bottom.is-collapsed {
    max-height: 0;
}
.c-dl-ns-post__bottom__inner {
    padding: 1.5rem $lg-pad-h;
}
@media (max-width: $screen-md-max) {
    .c-dl-ns-post__bottom__inner {
        padding: 1.5rem $md-pad-h;
   }
}
.c-dl-ns-post__reply {
    color: rgba(4,3,3,0.5);
    font-family: 'ProximaNova-Bold';
}
.c-dl-ns-post__reply:hover {
    color: #000;
}
.c-dl-ns-post__replies {
    padding-left: 0;
    margin-left: -19px;
    margin-right: -19px;
}
@media (max-width: $screen-md-max) {
    .c-dl-ns-post__replies {
        padding-top: 2rem;
   }
}
.c-dl-ns-post__activity-row {
    font-size: 16px;
    position: relative;
}
.c-dl-ns-post__activity-row::before {
    content: ' ';
    background: var(--feedback-bg);
    width: 1000%;
    height: 100%;
    position: absolute;
    z-index: 0;
    left: -50%;
}
.c-dl-ns-post__activity-row > .d-flex {
    padding: 7px 0;
    position: relative;
    z-index: 2;
}
.c-dl-ns-post__activity-row__activity {
    display: flex;
    align-items: center;
    margin-right: 20px;

    border-radius: 17px;
    padding: 2px 10px;
    position: relative;
    border: solid 1px;
    font-size: 11px;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 20px;
        opacity: 0.05;
    }

    &.physical {
        color: var(--physical);
        border-color: var(--physical);

        &::before {
            background-color: var(--physical);
        }
    }

    &.mental {
        color: var(--mental);
        border-color: var(--mental);

        &::before {
            background-color: var(--mental);
        }
    }

    &.environmental {
        color: var(--environmental);
        border-color: var(--environmental);

        &::before {
            background-color: var(--environmental);
        }
    }

    &.occupational {
        color: var(--occupational);
        border-color: var(--occupational);

        &::before {
            background-color: var(--occupational);
        }
    }

    &.financial {
        color: var(--piggybank);
        border-color: var(--piggybank);

        &::before {
            background-color: var(--piggybank);
        }
    }

    &.social {
        color: var(--friends);
        border-color: var(--friends);

        &::before {
            background-color: var(--friends);
        }
    }

    &.others {
        color: var(--greyColor);
        border-color: var(--greyColor);
        cursor: pointer;

        &::before {
            background-color: var(--white);
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10);
        }

        @media (max-width: $breakpoint-xs) {
            &.desktop {
                display: none;
            }

            &.mobile {
                display: block !important;
            }
        }

        &.mobile {
            display: none;
        }
    }
}
.c-dl-ns-post__activity-row__attributes {
    color: var(--bg-text-color);
    margin-right: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;

    &__attribute {
        font-size: 12px;
        text-transform: uppercase;
        margin-right: 8px;
        margin-top: 1px;
    }
}

.c-dl-ns-post__activity-row__attributes__value {
    font-size: 14px;
}
.c-dl-ns-post__activity-row__msg-edit {
    color: var(--greyColor);
    font-size: 12px;
    margin-top: 10px;
    margin-bottom: 20px;
}
.c-dl-ns-post__activity-row.modal-edit-activity {
    background: var(--progress-bg);
    border-radius: 5px;
    padding: 10px 20px;
}
.c-dl-ns-post__activity-row.modal-edit-activity::before {
    display: none;
}


.c-dl-ns-post__options-box {
    position: absolute;
    top: 22px;
    right: 0;
    border-radius: 5px;
    background: var(--flow-pages-bg);
    box-shadow: 0px 2px 4px rgba(0,0,0,0.25);
    padding: 10px 0;
    min-width: 190px;
}
.c-dl-ns-post__options-box ul {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 14px;
    cursor: pointer;
}
.c-dl-ns-post__options-box ul li {
    padding: 10px 20px;
}
.c-dl-ns-post__options-box ul li:hover {
    background: var(--milestone-task-bg);
}

@font-face {
  font-family: 'FontAwesome';
  src: url('../fonts/fontawesome-webfont.eot?v=4.7.0');
  src: url('../fonts/fontawesome-webfont.eot?#iefix&v=4.7.0') format('embedded-opentype'), url('../fonts/fontawesome-webfont.woff2?v=4.7.0') format('woff2'), url('../fonts/fontawesome-webfont.woff?v=4.7.0') format('woff'), url('../fonts/fontawesome-webfont.ttf?v=4.7.0') format('truetype'), url('../fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
.icon {
	display: inline-block;
	width: 1em;
	height: 1em;
	stroke-width: 0;
	stroke: currentColor;
	fill: currentColor;
}
.icon-circle_arrow_right {
	margin-right: -0.12em;
}
.icon-heart {
	width: 1.3em;
	height: 1.1em;
	-webkit-filter: grayscale(100%);
	filter: grayscale(100%);
}
.icon-heart-filled {
	width: 1.3em;
	height: 1.1em;
}
.icon-logo.old {
	width: 5.3125em;
}
.icon-logo {
	width: 4.71875em;
}
.icon-loop {
	width: 0.9375em;
}
.icon-notify-active {
	width: 0.84375em;
}
.icon-q-check {
	width: 1.03125em;
}
.icon-settings {
	width: 1.09375em;
}
.icon-user {
	width: 0.875em;
}
.icon-interface {
	width: 1.0498046875em;
}
.icon-mental {
	width: 0.96875em;
}
.icon-physical {
	width: 1.3125em;
}
.icon-arrow-right-thin {
	width: 0.28125em;
}
.icon-repeat {
	width: 1.03125em;
}
.icon-piggybank {
	width: 1.0625em;
}
.icon-arrow-right {
	width: 0.59375em;
	&.show-left {
		transform: rotate(180deg);
	}
}
.icon-dots {
	width: 2.21875em;
}
.icon-freinds-group {
	width: 1.25em;
}
.icon-arrow-round {
	width: 1.150390625em;
}
.icon-discount {
	width: 1.5em;
}
.icon-error-403 {
	width: 2.220703125em;
}
.icon-error-401 {
	width: 2.2216796875em;
}
.icon-dl-upload {
	font-size: 2.5em;
	fill: var(--accent-color);
}
.icon-nl-save {
	width: 1.0029296875em;
}
.icon-notdef {
	width: 0.5em;
}
.icon-search {
	width: 0.9287109375em;
}
.icon-star {
	width: 0.9287109375em;
}
.icon-star_empty {
	width: 0.9287109375em;
}
.icon-user {
	width: 0.7138671875em;
}
.icon-film {
	width: 1.0712890625em;
}
.icon-th_large {
	width: 0.9287109375em;
}
.icon-remove {
	width: 0.7861328125em;
}
.icon-zoom_in {
	width: 0.9287109375em;
}
.icon-zoom_out {
	width: 0.9287109375em;
}
.icon-home {
	width: 0.9287109375em;
}
.icon-road {
	width: 1.0712890625em;
}
.icon-download_alt {
	width: 0.9287109375em;
}
.icon-lock {
	width: 0.642578125em;
}
.icon-headphones {
	width: 0.9287109375em;
}
.icon-volume_off {
	width: 0.4287109375em;
}
.icon-volume_down {
	width: 0.642578125em;
}
.icon-volume_up {
	width: 0.9287109375em;
}
.icon-qrcode {
	width: 0.7861328125em;
}
.icon-tags {
	width: 1.0712890625em;
}
.icon-book {
	width: 0.9287109375em;
}
.icon-bookmark {
	width: 0.7138671875em;
}
.icon-print {
	width: 0.9287109375em;
}
.icon-camera {
	width: 1.0712890625em;
}
.icon-font {
	width: 0.9287109375em;
}
.icon-bold {
	width: 0.7861328125em;
}
.icon-italic {
	width: 0.5712890625em;
}
.icon-picture {
	width: 1.0712890625em;
}
.icon-map_marker {
	width: 0.5712890625em;
}
.icon-tint {
	width: 0.5712890625em;
}
.icon-share {
	width: 0.9287109375em;
}
.icon-shield {
	width: 0.9287109375em;
}
.icon-check {
	width: 0.9287109375em;
}
.icon-step_backward {
	width: 0.5712890625em;
}
.icon-backward {
	width: 0.9287109375em;
}
.icon-play {
	width: 0.7861328125em;
}
.icon-forward {
	width: 0.9287109375em;
}
.icon-step_forward {
	width: 0.5712890625em;
}
.icon-eject {
	width: 0.8583984375em;
}
.icon-chevron_left {
	width: 0.7138671875em;
}
.icon-chevron_right {
	width: 0.7138671875em;
}
.icon-arrow_up {
	width: 0.9287109375em;
}
.icon-plus {
	width: 0.7861328125em;
}
.icon-minus {
	width: 0.7861328125em;
}
.icon-asterisk {
	width: 0.9287109375em;
}
.icon-fire {
	width: 0.7861328125em;
}
.icon-calendar {
	width: 0.9287109375em;
}
.icon-retweet {
	width: 1.0712890625em;
}
.icon-shopping_cart {
	width: 0.9287109375em;
}
.icon-folder_close {
	width: 0.9287109375em;
}
.icon-folder_open {
	width: 1.0712890625em;
}
.icon-resize_vertical {
	width: 0.4287109375em;
}
.icon-bar_chart {
	width: 1.142578125em;
}
.icon-cogs {
	width: 1.0712890625em;
}
.icon-star_half {
	width: 0.5em;
}
.icon-signout {
	width: 0.9287109375em;
}
.icon-pushpin {
	width: 0.642578125em;
}
.icon-trophy {
	width: 0.9287109375em;
}
.icon-upload_alt {
	width: 0.9287109375em;
}
.icon-phone {
	width: 0.7861328125em;
}
.icon-check_empty {
	width: 0.7861328125em;
}
.icon-bookmark_empty {
	width: 0.7138671875em;
}
.icon-twitter-og, .icon-facebook-og {
	width: 20px;
	height: 20px;
}
.icon-unlock {
	width: 0.9287109375em;
}
.icon-credit_card {
	width: 1.0712890625em;
}
.icon-rss {
	width: 0.7861328125em;
}
.icon-wrench {
	width: 0.9287109375em;
}
.icon-filter {
	width: 0.7861328125em;
}
.icon-group {
	width: 1.0712890625em;
}
.icon-link {
	width: 0.9287109375em;
}
.icon-cloud {
	width: 1.0712890625em;
}
.icon-beaker {
	width: 0.9287109375em;
}
.icon-paper_clip {
	width: 0.7861328125em;
}
.icon-table {
	width: 0.9287109375em;
}
.icon-magic {
	width: 0.9287109375em;
}
.icon-google_plus {
	width: 1.2861328125em;
}
.icon-money {
	width: 1.0712890625em;
}
.icon-caret_down {
	width: 0.5712890625em;
}
.icon-caret_up {
	width: 0.5712890625em;
}
.icon-caret_left {
	width: 0.357421875em;
}
.icon-caret_right {
	width: 0.357421875em;
}
.icon-columns {
	width: 0.9287109375em;
}
.icon-sort {
	width: 0.5712890625em;
}
.icon-sort_down {
	width: 0.5712890625em;
}
.icon-sort_up {
	width: 0.5712890625em;
}
.icon-bolt {
	width: 0.5em;
}
.icon-umbrella {
	width: 0.9287109375em;
}
.icon-light_bulb {
	width: 0.5712890625em;
}
.icon-cloud_download {
	width: 1.0712890625em;
}
.icon-cloud_upload {
	width: 1.0712890625em;
}
.icon-user_md {
	width: 0.7861328125em;
}
.icon-stethoscope {
	width: 0.7861328125em;
}
.icon-coffee {
	width: 1.0712890625em;
}
.icon-food {
	width: 0.7861328125em;
}
.icon-building {
	width: 0.7861328125em;
}
.icon-hospital {
	width: 0.7861328125em;
}
.icon-ambulance {
	width: 1.0712890625em;
}
.icon-fighter_jet {
	width: 1.0712890625em;
}
.icon-beer {
	width: 0.9287109375em;
}
.icon-double_angle_left {
	width: 0.5712890625em;
}
.icon-double_angle_right {
	width: 0.5712890625em;
}
.icon-double_angle_up {
	width: 0.642578125em;
}
.icon-double_angle_down {
	width: 0.642578125em;
}
.icon-angle_left {
	width: 0.357421875em;
}
.icon-angle_right {
	width: 0.357421875em;
}
.icon-angle_up {
	width: 0.642578125em;
}
.icon-angle_down {
	width: 0.642578125em;
}
.icon-desktop {
	width: 1.0712890625em;
}
.icon-laptop {
	width: 1.0712890625em;
}
.icon-tablet {
	width: 0.642578125em;
}
.icon-mobile_phone {
	width: 0.4287109375em;
}
.icon-quote_left {
	width: 0.9287109375em;
}
.icon-quote_right {
	width: 0.9287109375em;
}
.icon-github_alt {
	width: 0.9287109375em;
}
.icon-folder_close_alt {
	width: 0.9287109375em;
}
.icon-folder_open_alt {
	width: 1.0712890625em;
}
.icon-gamepad {
	width: 1.0712890625em;
}
.icon-keyboard {
	width: 1.0712890625em;
}
.icon-terminal {
	width: 0.9287109375em;
}
.icon-code {
	width: 1.0712890625em;
}
.icon-star_half_empty {
	width: 0.9287109375em;
}
.icon-location_arrow {
	width: 0.7861328125em;
}
.icon-crop {
	width: 0.9287109375em;
}
.icon-code_fork {
	width: 0.5712890625em;
}
.icon-unlink {
	width: 0.9287109375em;
}
.icon-question {
	width: 0.5712890625em;
}
.icon-_279 {
	width: 0.357421875em;
}
.icon-exclamation {
	width: 0.357421875em;
}
.icon-_283 {
	width: 1.0712890625em;
}
.icon-puzzle_piece {
	width: 0.9287109375em;
}
.icon-microphone {
	width: 0.642578125em;
}
.icon-microphone_off {
	width: 0.7861328125em;
}
.icon-shield {
	width: 1em;
	padding-top: 4px;
}
.icon-calendar_empty {
	width: 0.9287109375em;
}
.icon-fire_extinguisher {
	width: 0.7861328125em;
}
.icon-rocket {
	width: 0.9287109375em;
}
.icon-html5 {
	width: 0.7861328125em;
}
.icon-unlock_alt {
	width: 0.642578125em;
}
.icon-ellipsis_horizontal {
	width: 0.7861328125em;
}
.icon-ellipsis_vertical {
	width: 0.2138671875em;
}
.icon-check_minus {
	width: 0.7861328125em;
}
.icon-level_up {
	width: 0.5712890625em;
}
.icon-level_down {
	width: 0.5712890625em;
}
.icon-eur {
	width: 0.5712890625em;
}
.icon-gbp {
	width: 0.5712890625em;
}
.icon-usd {
	width: 0.5712890625em;
}
.icon-inr {
	width: 0.5009765625em;
}
.icon-jpy {
	width: 0.5732421875em;
}
.icon-rub {
	width: 0.7138671875em;
}
.icon-btc {
	width: 0.7138671875em;
}
.icon-sort_by_alphabet {
	width: 0.9287109375em;
}
.icon-_329 {
	width: 0.9287109375em;
}
.icon-_334 {
	width: 0.9287109375em;
}
.icon-_335 {
	width: 0.9287109375em;
}
.icon-xing {
	width: 0.7861328125em;
}
.icon-f171 {
	width: 0.7861328125em;
}
.icon-tumblr {
	width: 0.5712890625em;
}
.icon-long_arrow_down {
	width: 0.4287109375em;
}
.icon-long_arrow_up {
	width: 0.4287109375em;
}
.icon-apple {
	width: 0.7861328125em;
}
.icon-windows {
	width: 0.9287109375em;
}
.icon-android {
	width: 0.7861328125em;
}
.icon-foursquare {
	width: 0.7138671875em;
}
.icon-female {
	width: 0.7138671875em;
}
.icon-male {
	width: 0.5712890625em;
}
.icon-bug {
	width: 0.9287109375em;
}
.icon-vk {
	width: 1.0712890625em;
}
.icon-_372 {
	width: 0.7861328125em;
}
.icon-stack_exchange {
	width: 0.7138671875em;
}
.icon-_378 {
	width: 0.9287109375em;
}
.icon-_380 {
	width: 0.642578125em;
}
.icon-plus_square_o {
	width: 0.7861328125em;
}
.icon-_382 {
	width: 1.2138671875em;
}
.icon-_383 {
	width: 0.9287109375em;
}
.icon-_387 {
	width: 1.142578125em;
}
.icon-_388 {
	width: 1.2861328125em;
}
.icon-f1a4 {
	width: 1.0712890625em;
}
.icon-_396 {
	width: 1.142578125em;
}
.icon-_398 {
	width: 1.1376953125em;
}
.icon-_404 {
	width: 0.7138671875em;
}
.icon-uniF1B1 {
	width: 0.9287109375em;
}
.icon-_406 {
	width: 0.4287109375em;
}
.icon-_408 {
	width: 1.2861328125em;
}
.icon-_409 {
	width: 1.142578125em;
}
.icon-_414 {
	width: 1.142578125em;
}
.icon-_415 {
	width: 1.142578125em;
}
.icon-_418 {
	width: 0.5712890625em;
}
.icon-_419 {
	width: 1.2861328125em;
}
.icon-_432 {
	width: 1.142578125em;
}
.icon-uniF1D5 {
	width: 0.7138671875em;
}
.icon-uniF1D7 {
	width: 1.142578125em;
}
.icon-_448 {
	width: 0.7138671875em;
}
.icon-_460 {
	width: 1.142578125em;
}
.icon-_461 {
	width: 1.142578125em;
}
.icon-uniF1F0 {
	width: 1.2861328125em;
}
.icon-_466 {
	width: 1.2861328125em;
}
.icon-_467 {
	width: 1.2861328125em;
}
.icon-f1f3 {
	width: 1.2861328125em;
}
.icon-_469 {
	width: 1.2861328125em;
}
.icon-_470 {
	width: 1.2861328125em;
}
.icon-_471 {
	width: 1.142578125em;
}
.icon-_472 {
	width: 1.142578125em;
}
.icon-_473 {
	width: 0.7861328125em;
}
.icon-_479 {
	width: 1.142578125em;
}
.icon-_481 {
	width: 1.142578125em;
}
.icon-_484 {
	width: 1.142578125em;
}
.icon-_485 {
	width: 1.142578125em;
}
.icon-_486 {
	width: 1.2861328125em;
}
.icon-_488 {
	width: 1.142578125em;
}
.icon-_489 {
	width: 0.7138671875em;
}
.icon-_490 {
	width: 1.142578125em;
}
.icon-_494 {
	width: 1.142578125em;
}
.icon-f212 {
	width: 1.142578125em;
}
.icon-_498 {
	width: 1.142578125em;
}
.icon-_500 {
	width: 1.142578125em;
}
.icon-_501 {
	width: 1.142578125em;
}
.icon-_502 {
	width: 0.9287109375em;
}
.icon-_503 {
	width: 0.9287109375em;
}
.icon-_504 {
	width: 1.142578125em;
}
.icon-_505 {
	width: 1.142578125em;
}
.icon-_507 {
	width: 1.2861328125em;
}
.icon-venus {
	width: 0.7138671875em;
}
.icon-_512 {
	width: 0.7138671875em;
}
.icon-_516 {
	width: 1.0712890625em;
}
.icon-_517 {
	width: 1.142578125em;
}
.icon-_519 {
	width: 0.7138671875em;
}
.icon-_520 {
	width: 1.142578125em;
}
.icon-_521 {
	width: 0.7138671875em;
}
.icon-_522 {
	width: 0.7138671875em;
}
.icon-_526 {
	width: 0.7138671875em;
}
.icon-_529 {
	width: 1.142578125em;
}
.icon-_530 {
	width: 1.142578125em;
}
.icon-_531 {
	width: 1.142578125em;
}
.icon-_537 {
	width: 1.28125em;
}
.icon-_538 {
	width: 1.2861328125em;
}
.icon-_540 {
	width: 1.2861328125em;
}
.icon-_541 {
	width: 1.2861328125em;
}
.icon-_542 {
	width: 1.2861328125em;
}
.icon-_543 {
	width: 1.2861328125em;
}
.icon-_544 {
	width: 1.2861328125em;
}
.icon-_545 {
	width: 0.7138671875em;
}
.icon-_546 {
	width: 0.5712890625em;
}
.icon-_547 {
	width: 1.142578125em;
}
.icon-_548 {
	width: 1.2861328125em;
}
.icon-_551 {
	width: 1.2861328125em;
}
.icon-_552 {
	width: 1.2861328125em;
}
.icon-_554 {
	width: 1.2861328125em;
}
.icon-_563 {
	width: 1.142578125em;
}
.icon-_564 {
	width: 1.142578125em;
}
.icon-_567 {
	width: 1.1005859375em;
}
.icon-f260 {
	width: 1.142578125em;
}
.icon-_572 {
	width: 1.2861328125em;
}
.icon-f263 {
	width: 0.7138671875em;
}
.icon-_575 {
	width: 0.9599609375em;
}
.icon-_576 {
	width: 1.2861328125em;
}
.icon-_582 {
	width: 1.142578125em;
}
.icon-_591 {
	width: 0.5712890625em;
}
.icon-_593 {
	width: 1.142578125em;
}
.icon-_597 {
	width: 0.5712890625em;
}
.icon-_603 {
	width: 1.2861328125em;
}
.icon-_607 {
	width: 1.2861328125em;
}
.icon-_609 {
	width: 1.2861328125em;
}
.icon-_616 {
	width: 1.142578125em;
}
.icon-_619 {
	width: 0.5712890625em;
}
.icon-_628 {
	width: 0.7861328125em;
}
.icon-_629 {
	width: 1.2861328125em;
}
.icon-uniF2A0 {
	width: 0.7861328125em;
}
.icon-uniF2A1 {
	width: 1.2138671875em;
}
.icon-uniF2A3 {
	width: 1.2861328125em;
}
.icon-uniF2A6 {
	width: 0.8564453125em;
}
.icon-uniF2A7 {
	width: 0.9287109375em;
}
.icon-uniF2A9 {
	width: 0.7138671875em;
}
.icon-uniF2AC {
	width: 0.9287109375em;
}
.icon-uniF2AE {
	width: 1.2861328125em;
}
.icon-uniF2B1 {
	width: 0.9287109375em;
}
.icon-uniF2B5 {
	width: 1.2861328125em;
}
.icon-uniF2B9 {
	width: 0.9287109375em;
}
.icon-uniF2BA {
	width: 0.9287109375em;
}
.icon-uniF2BB {
	width: 1.142578125em;
}
.icon-uniF2BC {
	width: 1.142578125em;
}
.icon-uniF2C1 {
	width: 0.7138671875em;
}
.icon-uniF2C2 {
	width: 1.142578125em;
}
.icon-uniF2C3 {
	width: 1.142578125em;
}
.icon-uniF2C5 {
	width: 1.2861328125em;
}
.icon-uniF2C7 {
	width: 0.5712890625em;
}
.icon-uniF2C8 {
	width: 0.5712890625em;
}
.icon-uniF2C9 {
	width: 0.5712890625em;
}
.icon-uniF2CA {
	width: 0.5712890625em;
}
.icon-uniF2CB {
	width: 0.5712890625em;
}
.icon-uniF2CC {
	width: 1.0712890625em;
}
.icon-uniF2D2 {
	width: 1.142578125em;
}
.icon-uniF2D6 {
	width: 1.0009765625em;
}
.icon-uniF2D9 {
	width: 1.2138671875em;
}
.icon-uniF2DC {
	width: 0.9287109375em;
}
.icon-uniF2E0 {
	width: 1.0712890625em;
}
.fa-lock:before {
	content: "\f023";
}
.fa-users:before {
	content: "\f0c0";
}
.fa-user-plus:before {
	content: "\f234";
}
.fa {
	display: inline-block;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
/* makes the font 33% larger relative to the icon container */
.fa-lg {
	font-size: 1.33333333em;
	line-height: 0.75em;
	vertical-align: -15%;
}
.fa-2x {
	font-size: 2em;
}
.fa-3x {
	font-size: 3em;
}
.fa-4x {
	font-size: 4em;
}
.fa-5x {
	font-size: 5em;
}
.fa-fw {
	width: 1.28571429em;
	text-align: center;
}
.fa-ul {
	padding-left: 0;
	margin-left: 2.14285714em;
	list-style-type: none;
}
.fa-ul > li {
	position: relative;
}
.fa-li {
	position: absolute;
	left: -2.14285714em;
	width: 2.14285714em;
	top: 0.14285714em;
	text-align: center;
}
.fa-li.fa-lg {
	left: -1.85714286em;
}
.fa-border {
	padding: 0.2em 0.25em 0.15em;
	border: solid 0.08em #eee;
	border-radius: 0.1em;
}
.icon-cheer-icon {
	fill: var(--accent-color);
}
.icon-cheer-icon-gray {
	fill: var(--lightGreyColor);
}
.icon-accordion-down {
	cursor: pointer;
}
.icon-accordion-down.show-up {
	transform: rotate(180deg);
}
.animated-loader-icon {
	width: 3em;
	height: 3em;
	margin: auto;
	text-align: center;
	display: block;
}
.icon-edit-box {
	stroke: transparent;
	fill: var(--theme-text-light);
}
.icon-settings-cog {
	fill: var(--theme-text-light);
}
.theme-orange .icon {
	stroke: #000;
}
.icon-down-filled {
	fill: var(--accent-color);
}
.icon-ticket {
	fill: var(--accent-color);
}
.icon-trash {
	width: 17px;
	height: 19px;
	cursor: pointer;
	fill: var(--accent-color);
}
.icon-checked {
	fill: var(--accent-color);
}
.icon-bi-share {
	fill: var(--accent-color);
}
.icon-full-star {
	fill: var(--accent-color);
}
.icon-pencil {
	fill: var(--accent-color);
}
.icon-bank {
	fill: var(--bg-text-color);
}
.icon-dots-transparent {
	fill: var(--bg-text-color);
}

.icon-cheer-icon {
	fill: var(--accent-color);
	&-gray {
		fill: var(--lightGreyColor);
	}
}

.icon-accordion-down {
	cursor: pointer;
	&.show-up {
		transform: rotate(180deg);
	}
	path {
		fill: var(--outer-space);
	}
}

.animated-loader-icon {
	width: 3em;
	height: 3em;
	margin: auto;
	text-align: center;
	display: block;
}

.icon-filter {
	path {
		fill: var(--outer-space);
	}
}

.icon-delete-dustbin {
	width: 17px;
	height: 19px;
	cursor: pointer;
}

.icon-edit-box {
	stroke: transparent;
	fill: var(--theme-text-light);
}

.icon-settings-cog {
	fill: var(--theme-text-light);
}

.theme-orange {
	.icon {
		stroke: black;
	}
}

.icon-down-filled {
	fill: var(--accent-color);
}

.icon-ellipsis_vertical {
	fill: var(--accent-dark-color);
}

.icon-recurring {
	fill: var(--accent-dark-color);
	width: 22px;
	height: 22px;
}

.icon-pencil {
	fill: var(--accent-color);
}

.icon-bank {
	fill: var(--bg-text-color);
}

.icon-dots-transparent {
	fill: var(--bg-text-color);
}

.icon-filter-triangle {
	fill: var(--accent-color);
}

.search-gray {
	fill: #6D787E;
}

.icon-lock-transparent {
	fill: var(--brown-gold);
}

.icon-cancel-rounded-corner-white {
	fill: #ffffff;
}

.icon-minus-slim {
	fill: var(--outer-space);
}

.icon-image-add {
	fill: var(--white);
}

.icon-drag-position {
	fill: var(--outer-space);
}

.icon-loading-refresh {
	fill: var(--accent-color);
}

.icon-play-btn {
	fill: var(--accent-color);
}

.diemlife-badge-icon {
	fill: var(--brown-gold);
}

.icon-bell {
	fill: var(--white);
}

.icon-warning {
	fill: var(--accent-color);
}

.icon-create-webp {
	width: 75px !important;
	height: 70px !important;
}

.icon-track-webp {
	width: 71px !important;
	height: 70px !important;
}

.icon-measure-webp {
	width: 71px !important;
	height: 70px !important;
}

.icon-link-small {
	fill: var(--accent-color);
}

.icon-open-link {
	color: var(--sinbad);
}

.icon-location-pin {
	fill: var(--outer-space);
}
.icon-support-hand {
	fill: var(--accent-color);
}
.icon-check-circle {
	fill: var(--success-bg);
}
.icon-warning-filled {
	fill: var(--warning-color);
}
.icon-error-filled {
	fill: var(--error-dark);
}
.icon-info-filled {
	fill: var(--tealDarkColor);
}
.notification-bell {
	fill: var(--bg-text-color);
}
// .profile-icon-small {
// 	fill: var(--bg-text-color);
// }
.diemlife-plus-icon {
	fill: var(--site-header-highlight);
}
.diemlife-plus-filled-icon {
	fill: var(--site-header-highlight);
	stroke: var(--site-header);

	.stroke-wala {
		stroke: var(--site-header);
		fill: var(--site-header);
	}
}
.search-black {
	fill: var(--site-header-text);
}

.invoice-print {
	fill: var(--accent-color);
}

.icon-download-slim {
	fill: var(--btn-text-light);
}
.icon-back_arrow {
	fill: var(--btn-text-color);
}

.drag-dot-icon {
	fill: var(--bg-text-color-light);
}

.link-open-tab {
	fill: var(--accent-color);
}
.icon-dl-upload-new {
	fill: var(--accent-color);
	opacity: 0.6;
}
.reset-circle {
	fill: var(--accent-color);
}

.c-quest__doers {
    display: flex;
    flex-wrap: wrap;
}
.c-quest__doers.is-align-left {
    justify-content: flex-start;
}
.c-quest__doers.is-align-left .c-quest__doer:not(:last-child) {
    margin-right: 10.5px;
}
.c-quest__doers--wrap {
    margin-top: 30px;
}
.c-quest__doers__more {
    width: 64px;
    height: 64px;
    border: 2px solid currentColor;
    flex-shrink: 0;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #fff;
    background: #265967;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    font-family: 'ProximaNova-Semibold';
    letter-spacing: 0.05em;
    padding-top: 0.2em;
    line-height: 1.2;
}
.c-quest__doers__more.thin-border {
    border-width: 1px;
}
@media (max-width: 650px) {
    .c-quest__doers__more {
        width: 34px;
        height: 34px;
        font-size: 9px;
   }
}
.c-quest__doers__more:hover {
    color: #f8ec31;
}
.c-quest__doers__more.is-hidden {
    display: none;
}
.c-quest__doers__group .c-main-search__item {
    width: 100%;
    text-align: left;
}
.c-quest__doers__group .c-main-search__user {
    border-radius: 4px;
}
.c-quest__doers__group .c-main-search__user:hover {
    background: rgba(218,239,244,0.278);
}
.c-quest__doers__group .c-main-search__load {
    background-color: transparent;
}
.c-quest__doers__group .c-main-search__load:hover {
    background-color: transparent;
}
.c-quest__doers__group .c-quest__doer {
    width: 64px;
    height: 64px;
    margin-bottom: 10px;
}
.c-quest__doers .c-quest-profile__img {
    left: 1px;
    top: 0px;
}
@media (max-width: 650px) {
    .c-quest__doers .c-quest-profile__img {
        left: 0;
        top: 0;
   }
}
app-members.profile-friends .c-quest__doers .c-quest-profile__img {
    left: 0;
    top: 0;
}
.c-quest__doers .c-avatar {
    font-size: 62px;
}
@media (max-width: 650px) {
    .c-quest__doers .c-avatar {
        font-size: 34px;
   }
}
app-members.profile-friends .c-quest__doers .c-avatar {
    font-size: 64px;
}
@media (max-width: 650px) {
    app-members.profile-friends .c-quest__doers .c-avatar {
        font-size: 34px;
   }
}
@media (max-width: 650px) {
    .c-quest__doers .c-my-friends__item__img__price {
        font-size: 10px;
   }
}
.c-quest__doer {
    position: relative;
    border-radius: 50%;
    flex-shrink: 0;
    display: block;
    height: 64px;
    width: 64px;
}
@media (max-width: 650px) {
    .c-quest__doer {
        width: 34px;
        height: 34px;
        font-size: 10px;
   }
}
.c-quest__doer__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
}
.c-quest__doer__status {
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    width: 1em;
    height: 1em;
    font-size: 24px;
}
.c-quest__doer__status .icon.is-gold {
    color: amber;
}
.c-quest__doer__status .icon.is-silver {
    color: dusty-gray;
}
@media (max-width: 650px) {
    .c-quest__doer__status {
        right: -3px;
        bottom: -3px;
        font-size: 16px;
   }
}
.c-quest__doer app-members:not(.profile-friends):not(.is-visible):nth-child(14) ~ .c-quest__doer {
    display: none;
}
.c-quest__doer app-members:not(.profile-friends):not(.is-visible):nth-child(14) + .c-quest__doer ~ .c-quest__doers__more {
    display: flex;
}
@media (max-width: 1009px) {
    .c-quest__doer app-members:not(.profile-friends):not(.is-visible):nth-child(11) ~ .c-quest__doer {
        display: none;
   }
    .c-quest__doer app-members:not(.profile-friends):not(.is-visible):nth-child(11) + .c-quest__doer ~ .c-quest__doers__more {
        display: flex;
   }
}
@media (max-width: 927px) {
    .c-quest__doer app-members:not(.profile-friends):not(.is-visible):nth-child(10) ~ .c-quest__doer {
        display: none;
   }
    .c-quest__doer app-members:not(.profile-friends):not(.is-visible):nth-child(10) + .c-quest__doer ~ .c-quest__doers__more {
        display: flex;
   }
}
@media (max-width: 859px) {
    .c-quest__doer app-members:not(.profile-friends):not(.is-visible):nth-child(9) ~ .c-quest__doer {
        display: none;
   }
    .c-quest__doer app-members:not(.profile-friends):not(.is-visible):nth-child(9) + .c-quest__doer ~ .c-quest__doers__more {
        display: flex;
   }
}
@media (max-width: 781) {
    .c-quest__doer app-members:not(.profile-friends):not(.is-visible):nth-child(8) ~ .c-quest__doer {
        display: none;
   }
    .c-quest__doer app-members:not(.profile-friends):not(.is-visible):nth-child(8) + .c-quest__doer ~ .c-quest__doers__more {
        display: flex;
   }
}
@media (max-width: $breakpoint-xm) {
    .c-quest__doer app-members:not(.profile-friends):not(.is-visible):nth-child(7) ~ .c-quest__doer {
        display: none;
   }
    .c-quest__doer app-members:not(.profile-friends):not(.is-visible):nth-child(7) + .c-quest__doer ~ .c-quest__doers__more {
        display: flex;
   }
}
@media (max-width: $breakpoint-6s) {
    .c-quest__doer app-members:not(.profile-friends):not(.is-visible):nth-child(6) ~ .c-quest__doer {
        display: none;
   }
    .c-quest__doer app-members:not(.profile-friends):not(.is-visible):nth-child(6) + .c-quest__doer ~ .c-quest__doers__more {
        display: flex;
   }
}
app-members.profile-friends .c-quest__doer:not(.is-visible):nth-child(10) ~ .c-quest__doer {
    display: none;
}
app-members.profile-friends .c-quest__doer:not(.is-visible):nth-child(10) + .c-quest__doer ~ .c-quest__doers__more {
    display: flex;
}
@media (max-width: 859px) {
    app-members.profile-friends .c-quest__doer:not(.is-visible):nth-child(9) ~ .c-quest__doer {
        display: none;
   }
    app-members.profile-friends .c-quest__doer:not(.is-visible):nth-child(9) + .c-quest__doer ~ .c-quest__doers__more {
        display: flex;
   }
}
@media (max-width: 781) {
    app-members.profile-friends .c-quest__doer:not(.is-visible):nth-child(8) ~ .c-quest__doer {
        display: none;
   }
    app-members.profile-friends .c-quest__doer:not(.is-visible):nth-child(8) + .c-quest__doer ~ .c-quest__doers__more {
        display: flex;
   }
}
@media (max-width: $breakpoint-xm) {
    app-members.profile-friends .c-quest__doer:not(.is-visible):nth-child(7) ~ .c-quest__doer {
        display: none;
   }
    app-members.profile-friends .c-quest__doer:not(.is-visible):nth-child(7) + .c-quest__doer ~ .c-quest__doers__more {
        display: flex;
   }
}
@media (max-width: $breakpoint-6s) {
    app-members.profile-friends .c-quest__doer:not(.is-visible):nth-child(6) ~ .c-quest__doer {
        display: none;
   }
    app-members.profile-friends .c-quest__doer:not(.is-visible):nth-child(6) + .c-quest__doer ~ .c-quest__doers__more {
        display: flex;
   }
}
.c-quest__doer__progress {
    display: block;
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.c-quest__doer__progress .c-quest-progress__inner {
    background: none;
    font-size: 64px;
}
@media (max-width: 650px) {
    .c-quest__doer__progress .c-quest-progress__inner {
        font-size: 34px;
   }
}
.c-quest__doer__progress .c-quest-progress__caption {
    display: none;
}
.c-quest-administration {
    display: flex;
    font-family: 'Proxima Nova';
}
.c-quest-administration__col {
    position: relative;
    width: 33.33%;
    padding-left: 5px;
    padding-right: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
}
.c-quest-administration__col:last-child .c-quest-administration__icon {
    display: none;
}
.c-quest-administration__col:before {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.1);
    top: 0;
}
.c-quest-administration__icon {
    content: '';
    position: absolute;
    left: 100%;
    top: 0;
    z-index: 1;
    font-size: 100px;
    height: 1em;
    width: 0.37em;
    margin-left: -22px;
}
.c-quest-group_row .c-quest-administration__icon {
    font-size: 100px;
}
@media (max-width: $breakpoint-sm) {
    .c-quest-administration__icon {
        font-size: 100px;
   }
}
.c-quest-administration__view {
    position: relative;
    border-radius: 50%;
    width: 1em;
    height: 1em;
    overflow: hidden;
    font-size: 50px;
    border: 1px solid var(--petrol);
    background-color: $petrol-15;
    z-index: 2;
    margin-top: 14px;
}
.c-quest-administration__view img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    width: auto;
    height: auto;
}
.c-quest-administration__ttl {
    color: ebony-clay;
    font-size: 14px;
    display: block;
    width: 100%;
    position: relative;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 80%;
}
.c-quest-group {
    display: flex;
    margin-left: -10px;
    margin-right: -10px;
    align-items: flex-end;
}
@media (max-width: $breakpoint-sm) {
    .c-quest-group {
        flex-direction: column;
   }
}
.c-quest-group_row {
    display: block;
}
.c-quest-group_row .c-quest-group__col {
    width: 100%;
}
.c-quest-group__col {
    width: 50%;
    padding-left: 10px;
    padding-right: 10px;
}
.c-quest-group_row .c-quest-group__col {
    width: 100%;
}
.c-quest-group_row .c-quest-group__col:not(:last-child) {
    margin-bottom: 20px;
}
@media (max-width: $breakpoint-sm) {
    .c-quest-group__col {
        width: 100%;
   }
    .c-quest-group__col:not(:last-child) {
        margin-bottom: 20px;
   }
}
.c-quest__ban {
    height: calc(100vh - 70px);
    display: flex;
    flex-direction: column;
    overflow: auto;
    color: var(--petrol);
}
@media (min-width: $breakpoint-xm-max) {
    .c-quest__ban {
        max-width: 362px;
        margin: 0 auto;
   }
}
.c-quest__ban__inner {
    padding: 20px;
    text-align: center;
    margin: auto;
    width: 100%;
    font-size: 16px;
}
.c-quest__ban__icon {
    font-size: 100px;
    margin: 0 auto 20px;
}
.c-quest__ban__title {
    font-size: 20px;
}
.c-quest__ban__desc {
    display: block;
    font-size: 16px;
    margin-bottom: 30px;
}
.c-quest__ban__help p {
    margin-bottom: 10px;
}
.c-quest__ban__help p:last-child {
    margin-bottom: 0;
}
.c-quest__ban__help__divider {
    display: block;
    margin-bottom: 6px;
    text-transform: uppercase;
}

.c-gallery__empty {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border: 1px dashed var(--accent-dark-color);
    border-radius: 5px;
    padding: 20px;
    text-align: center;
    font-size: 14px;
  }
   .c-gallery__empty::before {
    content: '';
    width: 100%;
    height: 100%;
    z-index: 1;
    background: var(--accent-color);
    opacity: 0.15;
    position: absolute;
  }
   .c-gallery__empty__overwrap {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
  }
   .c-gallery__empty__link {
    background: transparent;
    padding: 0;
    height: 100%;
    width: 100%;
    font-size: 0;
    overflow: hidden;
    text-indent: -1000px;
    margin: 0;
    border: 0;
    cursor: pointer;
    z-index: 2;
  }

  .c-gallery__empty .icon {
    font-size: 15rem;
    color: #6d787e;
    z-index: 2;
  }

  @media (max-width: $breakpoint-xm-max) {
     .c-gallery__empty .icon {
      font-size: 8rem;
   }
}

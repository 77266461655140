/**
* Variables for global DIEMlife
* * important this variables is strongly for new design
* TODO: improve variables for UI elements here
**/

/**:=====================================
**************** Typography **************
====================================== **/

// *Display
$DL-d1: 6.5rem;
$DL-d1-weight: 900;
$DL-d2: 6rem;
$DL-d2-weight: 900;
$DL-d3: 5.5rem;
$DL-d3-weight: 900;
$DL-d4: 5rem;
$DL-d4-weight: 900;

// *Headings
$DL-h1: 3.5rem;
$DL-h2: 3rem;
$DL-h3: 2.5rem;
$DL-h4: 2rem;
$DL-h5: 1.8rem;
$DL-h6: 1.6rem;
$DL-h-weight: 900;

// *fonts
$DL_ff: 'Proxima Nova', arial, Verdana, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;

/**:=====================================
************** Breakpoints ***************
====================================== **/

$screen-xs-min : 600px;
$screen-xs-max : 599.98px;
$screen-sm-min : 768px;
$screen-sm-max : 767.98px;
$screen-md-min : 992px;
$screen-md-max : 991.98px;
$screen-lg-min : 1200px;
$screen-lg-max : 1999.98px;
$screen-xl-min: 1420px;
$screen-xl-max: 1419.98px;

/**:=====================================
*************** Container ****************
====================================== **/

$scrn-container-min-sm: 720px;
$scrn-container-min-md: 960px;
$scrn-container-min-lg: 1140px;

/**:=====================================
***************** Form *******************
====================================== **/

// * Used for interval between form controls
// * especially for validation element height - ".c-n-forms__alert"
$DL-controls-space-interval: 2.4rem;
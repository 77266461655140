.c-search {
    background-color: rgba(255,255,255,0.95);
    position: absolute;
    color: #005166;
    display: block;
    z-index: 1000;
}
@media (min-width: 769px) {
    .c-search {
        box-shadow: 0 0 5px 0 rgba(0,0,0,0.3);
        height: calc(100vh - 70px);
        margin-right: 10px;
        max-height: 540px;
        margin-top: 50px;
        width: 350px;
        right: 0;
        top: 0;
   }
}
@media (max-width: 768px) {
    .c-search {
        height: 100vh;
        width: 100%;
        left: 0;
        top: 0;
   }
}
@media (min-width: 769px) {
    .c-search:before {
        border-color: transparent transparent #fff;
        border-width: 0 5px 7px;
        border-style: solid;
        position: absolute;
        bottom: 100%;
        right: 43px;
        content: '';
        z-index: 2;
        height: 0;
        width: 0;
   }
}
.c-search a {
    color: #000;
    cursor: pointer;
}
.c-search__more {
    display: block;
    width: 100%;
    min-height: 50px;
    position: relative;
    font-size: 20px;
}
.c-search__empty {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    font-size: 16px;
    padding: 10px;
    font-family: 'ProximaNova-Bold';
}
.c-search__backdrop {
    background-color: rgba(0,0,0,0.5);
    position: fixed;
    cursor: pointer;
    z-index: 999;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
}
.c-search__header {
    box-shadow: 0 0 5px 0 rgba(0,0,0,0.3);
    background-color: #fff;
    justify-content: center;
    position: relative;
    color: $casal;
    font-size: 16px;
    display: flex;
    padding: 10px;
    height: 40px;
    z-index: 1;
}
.c-search__title {
    text-transform: uppercase;
    align-items: center;
    font-family: 'ProximaNova-Bold';
    display: flex;
}
.c-search__title .icon {
    margin-right: 10px;
}
.c-search__tools {
    text-decoration: none !important;
    text-transform: uppercase;
    padding-right: 10px;
    align-items: center;
    position: absolute;
    font-family: 'ProximaNova-Bold';
    background: none;
    color: #005166;
    font-size: 16px;
    display: flex;
    height: 100%;
    right: 0;
    top: 0;
}
.c-search__tools a {
    text-decoration: none !important;
    text-transform: uppercase;
    color: #005166;
}
.c-search__body {
    background-color: #b7e6f1;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    font-size: 14px;
}
@media (max-width: 768px) {
    .c-search__body {
        -webkit-overflow-scrolling: touch;
        overflow-y: scroll;
   }
}
.c-search__body.is-loading {
    overflow: hidden;
}
@media (min-width: 769px) {
    .c-search__body {
        height: calc(100% - 40px);
   }
}
@media (max-width: 768px) {
    .c-search__body {
        height: calc(100vh - 88px);
        padding: 10px;
   }
}
@media (max-width: 768px) {
    .c-search__body.is-sm {
        height: calc(100vh - 126px);
   }
}
.c-search .c-btn {
    border-radius: 0;
}
.c-search__filter {
    background: linear-gradient(90deg, rgba(218,238,244,0.9) 19%, rgba(218,239,244,0.1) 100%, rgba(217,239,244,0) 0);
    box-shadow: 0 4px 20px -14px #000;
    display: flex;
}
.c-search__search {
    align-items: center;
    position: relative;
    display: flex;
    flex-grow: 1;
}
.c-search__search__control {
    padding: 16px 15px 14px 36px;
    background: transparent;
    font-family: inherit;
    border-radius: 12px;
    line-height: 1.25;
    color: #005166;
    font-family: 'ProximaNova-Semibold';
    font-size: 14px;
    height: auto;
    width: 100%;
    outline: 0;
    border: 0;
}
.c-search__search__control::placeholder {
    color: inherit;
}
.c-search__search .icon {
    position: absolute;
    font-size: 1.12em;
    margin-top: -0.5em;
    display: block;
    left: 10px;
    top: 50%;
}
.c-search__select {
    position: relative;
    line-height: 1.2;
    font-size: 14px;
}
.c-search__select__header {
    justify-content: center;
    text-align: center;
    min-width: 83px;
    cursor: pointer;
    padding: 15px;
    display: flex;
}
.c-search__select__header span {
    padding-right: 10px;
    position: relative;
    display: block;
}
.c-search__select__header span:after {
    border-bottom: 1px solid currentColor;
    border-right: 1px solid currentColor;
    transform: rotate(45deg);
    position: absolute;
    font-size: 6px;
    margin: auto;
    bottom: 3px;
    content: "";
    height: 1em;
    width: 1em;
    right: 0;
    top: 0;
}
.c-search__select__body {
    border: 1px solid #68c3d8;
    background: #fff;
    position: absolute;
    text-align: center;
    border-right: 0;
    display: none;
    border-top: 0;
    margin-top: 0;
    width: 100%;
    z-index: 3;
    top: 100%;
}
.c-search__select__body.is-active {
    display: block;
}
.c-search__select__item {
    padding: 5px 14px;
    cursor: pointer;
}
.c-search__select__item.is-active {
    background: #daeff4;
}
.c-search__select__item:not(.is-active):hover {
    background: #e5f4f7;
}
.c-search__list {
    position: relative;
}
.c-search__item {
    margin-bottom: 1px;
}
.c-search__item:hover {
    background: var(--quest-highlight-bg);
}
.c-search__item.no-hover:hover {
    background: none;
}
.c-search__item__quest {
    text-decoration: none;
    align-items: center;
    color: inherit;
    display: flex;
    padding: 10px;
}
.c-search__item__quest__img {
    background-color: #1f3034;
    background-size: cover;
    border-radius: 8px;
    min-width: 50px;
    flex-shrink: 0;
    height: 50px;
    width: 50px;
}
.c-search__item__quest__title {
    padding-left: 10px;
    font-family: 'ProximaNova-Bold';
    font-size: 16px;
    flex-grow: 1;
}
.c-search__item__friend {
    align-items: center;
    display: flex;
    padding: 10px;
}
.c-search__item__friend__img {
    position: relative;
    min-width: 50px;
    cursor: pointer;
    flex-shrink: 0;
    height: 50px;
    width: 50px;
}
.c-search__item__friend__img__inner {
    border: 2px solid #fff;
    border-radius: 50%;
    position: absolute;
    overflow: hidden;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
}
.c-search__item__friend__caption {
    align-items: center;
    padding-left: 10px;
    display: flex;
    flex-grow: 1;
}
.c-search__item__friend__title {
    text-decoration: none !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: 'ProximaNova-Bold';
    overflow: hidden;
    color: inherit;
    display: block;
    flex-grow: 1;
}
.c-search__item__friend__tool {
    flex-direction: row-reverse;
    align-items: center;
    white-space: nowrap;
    cursor: pointer;
    display: flex;
}
.c-search__item__friend__tools {
    flex-direction: column;
    align-items: flex-end;
    padding-left: 10px;
    flex-shrink: 0;
    display: flex;
}
.c-search__item__friend__tool .e-oval {
    box-shadow: 0 1px 11px -4px #000;
    font-size: 30px;
    color: #fff;
}
.c-search__item__friend__tool .e-oval .icon {
    display: block;
}
.c-search__item__friend__tool span {
    flex-direction: row-reverse;
    margin-right: 10px;
    display: none;
}
.c-main-search__load {
    text-decoration: underline;
    text-align: center;
    display: block;
    color: inherit;
    padding: 10px;
    width: 100%;
}

html {
	font-family: 'Proxima Nova', serif;
	min-height: 100%;
	height: auto;
	font-size: 10px;
}
html body {
	font-family: 'Proxima Nova', serif;
	background: var(--primary-bg);
	position: relative;
	height: auto;
}
html body .notifier__notification--error {
	top: 12px !important;
	bottom: unset !important;
}
html body .notifier__notification {
	font-size: 1.5rem;

	svg {
		margin-right: 5px;
	}
}
@media (max-width: 450px) {
	html body .notifier__notification {
		width: 86%;
   }
	html body .notifier__notification--material .notifier__notification-message {
		width: 100%;
   }
}
body a:not([href]) {
	cursor: default;
}
body .l-content {
	padding-top: 66px;
	min-height: 100vh;
	overflow: hidden;
	width: 100%;

	@media (max-width: $breakpoint-xm-max) {
		padding-top: 56px;
	}
}
body .l-content.no-top-pad {
	padding-top: 0;
}

body .c-container {
	width: 100%;
	margin: 0 auto;
	padding-left: 10px;
	padding-right: 10px;
	max-width: ($container-md + $padding-md);
}
@media (min-width: $breakpoint-xm) {
	body .c-container {
		padding-left: $padding-sm;
		padding-right: $padding-sm;
   }
}
@media (min-width: $breakpoint-sm) {
	body .c-container {
		padding-left: $padding-md;
		padding-right: $padding-md;
   }
}
body .c-container_sm {
	max-width: 700px;
}
@media (max-width: $breakpoint-sd-max) {
	body .c-container.sd-no-pad {
		padding-left: 0;
		padding-right: 0;
   }
}
@media (max-width: $breakpoint-xm-max) {
	body .c-container.xm-no-pad {
		padding-left: 0;
		padding-right: 0;
   }
}
@media (max-width: $breakpoint-sm-max) {
	body .c-container.sm-no-pad {
		padding-left: 0;
		padding-right: 0;
   }
}
@media (max-width: $breakpoint-xm-max) {
	body .row-xm {
		margin-left: -10px;
		margin-right: -10px;
   }
}
body .c-container-new {
	width: 100%;
	margin: 0 auto;
	padding-left: 1rem;
	padding-right: 1rem;
}
@media (min-width: $screen-sm-min) {
	body .c-container-new {
		width: $scrn-container-min-sm;
   }
}
@media (min-width: $screen-md-min) {
	body .c-container-new {
		width: $scrn-container-min-md;
		padding-left: 2rem;
		padding-right: 2rem;
   }
}
@media (min-width: $screen-lg-min) {
	body .c-container-new {
		width: $scrn-container-min-lg;
   }
}
body .c-container-new_fluid {
	width: 100%;
}
body ul {
	padding: 0;
	margin: 0;
}
body .grade1 {
	background: #61bed5;
	background: -moz-linear-gradient(top, #61bed5 0%, #cbf3f9 100%, #7db9e8 100%);
	background: -webkit-linear-gradient(top, #61bed5 0%, #cbf3f9 100%, #7db9e8 100%);
	background: linear-gradient(to bottom, #61bed5 0%, #cbf3f9 100%, #7db9e8 100%);
}
body .grade2 {
	background: #026789;
	background: -moz-linear-gradient(top, #026789 0%, #cee8ed 100%, #7db9e8 100%);
	background: -webkit-linear-gradient(top, #026789 0%, #cee8ed 100%, #7db9e8 100%);
	background: linear-gradient(to bottom, #026789 0%, #cee8ed 100%, #7db9e8 100%);
	position: relative;
	z-index: 1;
}
body .clearfix:after {
	visibility: hidden;
	display: table;
	width: 100%;
	clear: both;
	content: '';
	height: 0;
}
body h1, body h2, body h3, body h4, body h5, body h6, body .h1, body .h2, body .h3, body .h4, body .h5, body .h6 {
	line-height: 1;
	margin: 0px;
	text-transform: uppercase;
	margin-bottom: 10px;
	font-family: 'ProximaNova-Extrabold';
}
body h1 {
	font-size: 22px;
}
@media (max-width: $breakpoint-xm) {
	body h1 {
		font-size: 20px;
   }
}
body h2 {
	font-size: 20px;
}
@media (max-width: $breakpoint-xm) {
	body h2 {
		font-size: 18px;
   }
}
body h3 {
	font-size: 16px;
}
@media (max-width: $breakpoint-xm) {
	body h3 {
		font-size: 14px;
   }
}
body h4 {
	font-size: 14px;
}
@media (max-width: $breakpoint-xm) {
	body h4 {
		font-size: 12px;
   }
}
body h5 {
	font-size: 12px;
}
@media (max-width: $breakpoint-xm) {
	body h5 {
		font-size: 10px;
   }
}
body .h1 {
	font-size: 24px;
}
@media (max-width: $breakpoint-xm) {
	body .h1 {
		font-size: 20px;
   }
}
body .h2 {
	font-size: 20px;
}
@media (max-width: $breakpoint-xm) {
	body .h2 {
		font-size: 18px;
   }
}
body .h3 {
	font-size: 16px;
}
@media (max-width: $breakpoint-xm) {
	body .h3 {
		font-size: 14px;
   }
}
body .h4 {
	font-size: 14px;
}
@media (max-width: $breakpoint-xm) {
	body .h4 {
		font-size: 12px;
   }
}
body .h5 {
	font-size: 12px;
}
@media (max-width: $breakpoint-xm) {
	body .h5 {
		font-size: 10px;
   }
}
body p {
	-webkit-margin-before: 0;
	-webkit-margin-after: 0;
	-webkit-margin-start: 0;
	-webkit-margin-end: 0;
	margin-bottom: 1.5em;
	line-height: 1.25;
}
body img {
	height: auto;
	max-width: 100%;
}
body .h-btn-reset {
	background-color: transparent;
	border: 0;
	padding: 0;
	font-weight: normal;
	font-size: inherit;
	font-style: inherit;
	color: inherit;
}
body .h-heading-reset {
	text-transform: none;
	font-size: inherit;
	color: inherit;
	line-height: inherit;
	margin-bottom: inherit;
	font-weight: inherit;
}
body .h-underline {
	text-decoration: underline;
}
body .h-list-reset {
	margin-bottom: 0;
	padding-left: 0;
	list-style-type: none;
}
body .c-explore__inner .c-container {
	padding-top: 0px !important;
	border: 1px solid #f00;
}
body .c-my-quests__nav__list li a span {
	text-decoration: underline;
}
body .c-gallery__empty {
	background-color: rgba(0,81,102,0.05);
	cursor: pointer;
}
body .c-container.feed {
	padding-top: 0;
}

@media (min-width: $breakpoint-xm) {
	body .h-sd-min-hidden {
		display: none;
   }
}
body #ppBody {
	font-size: 1.6rem;
}
body .terms--bottom {
	font-size: 1.6rem;
}
body .d-none {
	display: none !important;
}
body .d-flex {
	display: flex !important;

	&__justify {
		&__end {
			justify-content: flex-end;
		}

		&__start {
			justify-content: flex-start;
		}
	}
}
body .d-flex-row {
	flex-direction: row !important;
}
body .d-flex-column {
	flex-direction: column !important;
}
body .d-flex__justify__space-between {
	justify-content: space-between !important;
}
body .d-flex__justify__center {
	justify-content: center;
}
body .d-flex-align{
	&_center {
		align-items: center;
	}

	&_start {
		align-items: flex-start;
	}
}
body .align-items-center {
	align-items: center;
}
body .ql-editor {
	padding-left: 0;
	padding-right: 0;


	ol li:not(.ql-direction-rtl), 
	ul li:not(.ql-direction-rtl) {
		padding-left: 0.2rem !important;
	}
}
body .ql-editor.ql-blank::before {
	left: 0;
	font-family: 'ProximaNova-Regular';
	font-style: normal;
	color: var(--bg-text-color-light);
}
@media (min-width: 992px) {
	body .modal.confirm-modal .modal-dialog {
		--bs-modal-width: 500px;
   }
}
@media (min-width: 992px) {
	body .modal.create-quest-modal .modal-dialog {
		--bs-modal-width: 680px;
   }
}
@media (min-width: 992px) {
	body .modal.log-activity-modal .modal-dialog, body .modal.milestone-modal .modal-dialog {
		--bs-modal-width: 700px;
   }
}
@media (min-width: 992px) {
	body .modal.small-login-modal .modal-dialog {
		--bs-modal-width: 610px;
   }
}
body .modal .modal-content {
	overflow: visible;
	background: var(--modal-bg);
	color: var(--bg-text-color);
}
body .modal {
	--bs-modal-zindex: 1090 !important;

	.modal-content .modal-header {
		padding: 20px;
	}

	&.carousel-modal {
		.modal-dialog {
			--bs-modal-margin: 0;
			--bs-modal-width: 100%;
			height: 100%;
			.modal-content {
				height: 100%;
				background: none transparent;
				border-width: 0;
			}
		}
	}

	&.log-activity-modal {
		@media (min-width: 992px) {
			.modal-dialog {
				--bs-modal-width: 820px;
			}
		}

		.component-host-scrollable {
			@media (max-width: $breakpoint-xs-max) {
				overflow: auto !important;
			}
		}

		.modal-body {
			@media (max-width: $breakpoint-xs-max) {
				overflow-y: visible !important;
			}
		}
	}

	&.cheer-modal {
		.modal-content {
			.modal-body {
				padding: 20px;
			}
		}
	}

	&.login-modal {
		.modal-dialog {
			width: 80%;
			max-width: 840px;

			@media (max-width: 575px) {
				width: auto;
			}
		}

		.modal-content {
			border-width: 0;
		}
	}

	&.small-login-modal {
		.btn-close {
			top: 13px !important;
		}
	}

	&.share-modal-full {
		.modal-header {
			border-bottom: 0 !important;

			h4 {
				text-align: center;
				width: 100%;
			}
		}
	}
}

body .modal .modal-content .modal-header h4 {
	text-transform: none;
	font-size: 20px;
	font-family: 'ProximaNova-Bold';
	color: var(--bg-text-color);
}
body .modal .modal-content .modal-header .btn-close {
	font-size: 15px;
	filter: var(--close-btn-invert-str) grayscale(100%) brightness(200%);
}
body .modal .modal-content .modal-body {
	overflow: visible;
	padding: 25px 20px;
}
body .modal .modal-content .modal-body .absolute-btn.btn-close {
	position: absolute;
	top: 10px;
	right: 10px;
	z-index: 9;
}
body .modal .modal-content .modal-body p {
	font-size: 14px;
	// margin-bottom: 10px;
}
body .modal .modal-content .modal-body p.headline {
	font-weight: bold;
	margin-bottom: 3px;
}
body .modal .modal-content .modal-body .btn-close {
	font-size: 15px;
	filter: var(--close-btn-invert-str) grayscale(100%) brightness(200%);
}
body .modal .modal-content .modal-footer {
	padding: 20px;
}
body .modal.no-overflow .modal-content {
	overflow: hidden;
}
body .modal.no-overflow .modal-content .modal-body {
	overflow-y: auto;
}
body .modal.no-overflow .component-host-scrollable {
	overflow: hidden;
}
body .modal-backdrop {
	--bs-backdrop-zindex: 1089 !important;
	z-index: 1089 !important;
	background-color: var(--bs-backdrop-bg-custom);

	&.carousel-bg.show {
		opacity: 1 !important;
	}
}
body ngb-modal-window .component-host-scrollable {
	overflow: visible;
}
body .character__left {
	font-size: 10px;
	margin-bottom: 12px;
	display: block;
	margin-top: 5px;
	text-align: right;
}
body .character__left.error {
	color: var(--errorRed);
}
body .character__left.warning {
	color: var(--warning-color);
}
.sr-only {
	position: absolute !important;
	width: 1px !important;
	height: 1px !important;
	padding: 0 !important;
	margin: -1px !important;
	overflow: hidden !important;
	clip: rect(0, 0, 0, 0) !important;
	white-space: nowrap !important;
	border: 0 !important;
}
.mr-0 {
	margin-right: 0 !important;
}
.mr-5 {
	margin-right: 5px !important;
}
.ml-10 {
	margin-left: 10px !important;
}
.ml-30 {
	margin-left: 30px !important;
}
.mt-20 {
	margin-top: 20px !important;

	&-mobile {
		@media (max-width: $breakpoint-xs) {
			margin-top: 20px !important;
		}
	}
}
.mt-30 {
	margin-top: 30px !important;

	&-mobile {
		@media (max-width: $breakpoint-xs) {
			margin-top: 20px !important;
		}
	}
}
.mt-10 {
	margin-top: 10px !important;
}

.mobile-options-window {
	display: none;
	position: fixed;
	top: 65px;
	left: 0;
	width: 80%;
	height: calc(100% - 65px);
	background: white;
	z-index: 9997;
	max-width: 400px;
	overflow-y: auto;
	&-bg-overlay {
		display: none;
		position: fixed;
		top: 65px;
		left: 0;
		width: 100%;
		height: calc(100% - 160px);
		background: gray;
		z-index: 9997;
	}
	&__header {
		border-bottom: solid 1px #C5C9CB;
		padding: 15px 20px;
		display: flex;
		flex-direction: row;

		.title {
			text-align: center;
			width: 100%;
			font-size: 15px;
			text-transform: uppercase;
			color: var(--theme-text-dark);
		}

		.icon-cancel-rounded-corner {
			width: 24px;
			height: 24px;
		}
	}
	&__inner-content {
		padding: 25px 20px;

		h2 {
			font-size: 18px;
			text-transform: uppercase;
			font-family: 'ProximaNova-Bold';
			margin-bottom: 20px;
		}

		ul {
			list-style: none;

			li {
				margin-bottom: 20px;
				text-align: left;
				&:last-child {
					margin-bottom: 0;
				}

				a {
					width: 100%;
					text-align: left;
					text-decoration: none;
				}
			}
		}
	}
	&__footer {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		z-index: 9999;
		background: white;
		padding: 25px;
		display: none;
		button {
			min-width: 120px;
		}
	}
}

.active-side-menu {
	.mobile-options-window {
		display: block;
		&-bg-overlay {
			display: block;
		}
		&__footer {
			display: block;
		}
	}
}

.ql-tooltip {
	left: 0px !important;
}

.pb-40 {
	padding-bottom: 40px !important;
}

.mb-20 {
	margin-bottom: 20px !important;
}

.mb-50 {
	margin-bottom: 50px !important;
}

.mr-20 {
	margin-right: 20px !important;
}

.hr-break-gray {
	width: 100%;
	height: 1px;
	background: var(--dove-gray);
}

.mb-30 {
	margin-bottom: 30px;
}

.mr-10 {
	margin-right: 10px;
}

.mr-15 {
	margin-right: 15px !important;
}

.ml-15 {
	margin-left: 15px !important;
}


.ml-0 {
	margin-left: 0 !important;
}

.mt-5 {
	margin-top: 5px !important;
}

.mb-10 {
	margin-bottom: 10px !important;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.dropdown {
	.dropdown-item {
		padding: 0;
	}
}

ngb-tooltip-window.c-dl-tooltip.tooltip.fade {
	opacity: 1 !important;
	z-index: 9999;
}

.text-transform {
	&-none {
		text-transform: none !important;
	}

	&-capitalize {
		text-transform: capitalize !important;
	}
}

.login-alert-msg {
    background: var(--bg-milestone);
    padding: 15px 20px;
    margin-bottom: 30px;
    border-radius: 5px;

    p {
        font-size: 14px;
        font-family: 'ProximaNova-Bold';
        margin: 0;
        color: var(--bg-text-color);

        span {
            color: var(--accent-color);
            cursor: pointer;
            display: inline-block;
            text-decoration: underline;
        }
    }
}

.cursor-pointer {
	cursor: pointer !important;
}
.underline {
	text-decoration: underline;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
	font-weight: bold !important;
	color: var(--outer-space) !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option-child {
	padding-left: 20px !important;
}

.text-error {
	color: #b41212;
}

svg,
svg use {
	stroke: transparent;
    stroke-width: 0px;
	border: 0;
	outline: 0;
}

.text-color-accent {
	color: var(--accent-color);
}

.highlight-focus-box {
	animation: blink-animation 1s steps(2, start) infinite;
	-webkit-animation: blink-animation 1s steps(2, start) infinite;
}

@keyframes blink-animation {
	to {
		box-shadow: 0px 0px 5px 0px var(--accent-color);
	}
}
@-webkit-keyframes blink-animation {
	to {
		box-shadow: none;
	}
}

.mb-0 {
	margin-bottom: 0 !important;
}

.header-name-tooltip {
	.tooltip-inner {
		white-space: pre;
	}
}

.mt-0 {
	margin-top: 0 !important;
}

body .modal.confirm-modal {
	.modal-footer {
		button {
			@media (max-width: $breakpoint-xs-max) {
				width: 47%;
				max-width: 47%;  
			}
		}
	}
}

.desktop-only {
	display: block;
  
	@media (max-width: $breakpoint-xs-max) {
	  display: none;
	}
}

.mobile-only {
	display: none;
  
	@media (max-width: $breakpoint-xs-max) {
	  display: block;
	}
}

.skeleton-field,
.skeleton-item {
	position: relative;

	&::before {
		content: '';
		width: 100%;
		height: 100%;
		background: linear-gradient(
			to right,
			rgba(255, 255, 255, 0),
			rgba(255, 255, 255, 0.5) 50%,
			rgba(255, 255, 255, 0) 80%
		  ),
		  var(--black-10);
		background-repeat: repeat-y;
		background-size: 50px 500px;
		background-position: 0 0;
		animation: shine-skeleton 1s infinite;
		position: absolute;
		top: 0;
		left: 0;
	}

	&.static-skeleton {
		&::before {
			animation: none;
		}
	}
}

@keyframes shine-skeleton {
	to {
	  background-position: 100% 0, /* move highlight to right */ 0 0;
	}
}

.h6 {
	&-regular {
		font-family: 'ProximaNova-Regular';
		font-size: 18px;
	}

	&-semibold {
		font-family: 'ProximaNova-Semibold';
		font-size: 18px;
	}

	&-bold {
		font-family: 'ProximaNova-Bold';
		font-size: 18px;
	}

	&-button {
		&-large {
			font-family: 'ProximaNova-Bold';
			font-size: 15px;
		}

		&-medium {
			font-family: 'ProximaNova-Bold';
			font-size: 14px;
		}

		&-small {
			font-family: 'ProximaNova-Bold';
			font-size: 13px;
		}
	}
}

.body {
	&-regular {
		font-family: 'ProximaNova-Regular';
		font-size: 1.6rem;
	}

	&-semibold {
		font-family: 'ProximaNova-Semibold';
		font-size: 1.6rem;
	}

	&-bold {
		font-family: 'ProximaNova-Bold';
		font-size: 1.6rem;
	}
}

.h2 {
	&-regular {
		font-size: 30px;
		line-height: 34px;
		font-family: 'ProximaNova-Regular';
	}

	&-semibold {
		font-size: 30px;
		line-height: 34px;
		font-family: 'ProximaNova-Semibold';
	}

	&-bold {
		font-size: 30px;
		line-height: 34px;
		font-family: 'ProximaNova-Bold';
	}
}

.rotate-180deg {
	transform: rotate(180deg);
}


@keyframes rotating {
    from { -webkit-transform: rotate(0deg) } 
    to { -webkit-transform: rotate(360deg) } 
}

@-webkit-keyframes rotating {
    from { -webkit-transform: rotate(0deg) } 
    to { -webkit-transform: rotate(360deg) } 
}

.no-cursor-pointer {
	cursor: unset !important;
}

.disabled-tooltip-msg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
}
.StripeElement {
	color: #fff;
}
.c-payments__collapse__header {
	position: relative;
	padding-left: 45px;
	padding-top: 7px;
	padding-bottom: 5px;
}
.c-payments__collapse__oval {
	display: none;
}
.c-payments__collapse__body {
	overflow: hidden;
	max-height: 0;
	transition: max-height 300ms linear;
}
.c-payments__collapse.is-active .c-payments__collapse__body {
	max-height: 200px;
}

.c-payments__list__item.is-selectable .c-payments__card__caption {
	padding-left: 41px;
}
.c-payments__card__caption.StripeElement {
	padding-top: 10px;
	padding-bottom: 10px;
}
.c-payments__card__caption.has-two-icons-right {
	padding-right: 80px;
}
.c-payments__list__item.is-selectable .c-payments__card__caption:hover:before {
	background-color: $petrol-50;
}
.c-payments__list__item.is-selectable .c-payments__card__caption:before {
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	left: 20px;
	width: 1em;
	height: 1em;
	border-radius: 50%;
	background-color: $petrol-30;
	margin: auto;
	font-size: 10px;
	margin-left: -0.5em;
	z-index: 1;
}
.c-payments__list__item.is-selectable .c-n-form__group.is-selected .c-payments__card__caption:before {
	background-color: var(--petrol);
	transform: scale(1.4);
}
.c-payments__card__icon {
	position: absolute;
	left: 10px;
	top: 0;
	bottom: 0;
	margin: auto;
}
.c-payments__card__icon:not(.icon) {
	width: 20px;
	height: auto;
}
.c-payments__card__icon.icon {
	font-size: 18px;
}
.c-payments__card__info {
	background-color: transparent;
	border: 0;
	padding: 0;
	margin: 0;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	width: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 20px;
	color: var(--petrol);
	cursor: pointer;
}
.c-payments__card__info.is-loading, .c-payments__card__remove.is-loading {
	opacity: 1;
}
.c-payments__card__info.is-loading .fa, .c-payments__card__remove.is-loading .fa, .c-payments__card__info.is-loading .icon, .c-payments__card__remove.is-loading .icon {
	display: none;
}
.c-payments__card__info.is-loading:before, .c-payments__card__remove.is-loading:before {
	content: '';
	position: relative;
	width: 1em;
	height: 1em;
	border-radius: 50%;
	border: 2px solid currentColor;
	border-bottom-color: transparent;
	display: inline-block;
	vertical-align: middle;
	margin-right: 5px;
	font-size: 14px;
	animation: rotating 2s linear infinite;
}
.c-payments__card__info {
	color: $cinnabar;
	right: 30px;
}
.c-payments__list {
	list-style-type: none;
	padding-left: 0;
	margin-bottom: 0;
}
.c-payments__list__hr {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 8px;
	font-family: 'ProximaNova-Bold';
	font-size: 14px;
}
.c-payments__list__group {
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
	margin-bottom: 10px;
	padding-right: 0;
	background: white-50;
	border-radius: 3px;
	border: 2px solid transparent;
}
.c-payments__list__col {
	width: 50%;
}
.c-payments__list__col.is-long {
	width: calc(100% - 140px);
}
.c-payments__list__col.is-short {
	width: 140px;
}
.c-payments__list__col.is-short .c-n-form__input {
	padding-left: 0;
}
.c-payments__list__col .c-n-form__input {
	background-color: transparent;
	box-shadow: none;
	border: 0;
}
.c-payments__list__item.is-selectable .c-payments__list__col .c-n-form__input {
	padding-left: 0;
}
.c-payments__box-view {
	background: var(--flow-pages-section-bg);
}
.c-payments__box-view__payment-item__open-view {
	display: none;
	border: solid 1px var(--ghostGrey);
	border-top-width: 0;
	padding: 20px;
	background: var(--bg-white-ghost);
}
.c-payments__box-view__payment-item__open-view > .c-n-form__group {
	margin-bottom: 0;
}
.c-payments__box-view__payment-item__open-view.show {
	display: block;
}
.c-payments__box-view__payment-item__open-view .card-option-item {
	background: var(--flow-pages-section-bg);
	padding: 10px 15px 10px 20px;
	border: solid 1px var(--ghostGrey);
	border-radius: 5px;
	margin-bottom: 10px;
}
.c-payments__box-view__payment-item__open-view .card-option-item__options {
	color: var(--accent-color);
	font-family: 'ProximaNova-Bold';
	font-size: 14px;
	cursor: pointer;
}
.c-payments__box-view__payment-item__open-view .card-option-item__container {
	width: 100%;
	justify-content: space-between;
	align-items: center;
}
.c-payments__box-view__payment-item__open-view .card-option-item__container .e-radio {
	margin-bottom: 0;
}
.c-payments__box-view__payment-item > .c-n-form__control {
	border: solid 1px var(--ghostGrey);
	padding: 15px;
	border-radius: 5px;
	margin-top: 10px;
}
.c-payments__box-view__payment-item .e-radio {
	color: var(--bg-text-color);
}
.c-payments__box-view__payment-item .e-radio__title {
	flex-direction: row;
	align-items: center;
}
.c-payments__box-view__payment-item .e-radio__title .icon {
	margin-right: 10px;
	margin-left: 0;
}
.c-payments__box-view__payment-item .ng-option {
	border-bottom: solid 1px $lightGreyColor;
}
.c-payments__box-view__payment-item .ng-option .card-name {
	font-size: 14px;
}
.c-payments__box-view__payment-item .ng-option .card-numb {
	margin-top: 5px;
	font-size: 12px;
	color: $lightGreyColor;
}
.c-payments__new-card-box {
	margin-top: 20px;
}
.c-payments__new-card-box .c-n-form__group.mb-0 {
	margin-bottom: 0;
}
@media (max-width: $breakpoint-xs-max) {
	.c-payments__new-card-box .row {
		flex-direction: column;
   }
	.c-payments__new-card-box .row .col-6 {
		width: 100% !important;
   }
}

.c-quest-header {
    position: relative;
    display: block;
}
.c-quest-header__img {
    height: 0;
    padding-bottom: 40%;
    position: relative;
}
.c-quest-header__photo {
    aspect-ratio: 4 / 1;
    overflow: hidden;
    position: relative;
    background-color: $lightGreyColor;
}
.c-quest-header__photo__bg:after, .c-quest-header__photo__img:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
}
.c-quest-header__photo__bg__upload, .c-quest-header__photo__img__upload {
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    position: absolute;
    font-size: 14px;
    display: flex;
    margin: auto;
    right: 20px;
    bottom: 20px;
    padding: 5px 9px;
    cursor: pointer;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.50);
    color: var(--btn-text-light);

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        background: var(--accent-color);
        opacity: 0.8;
        width: 100%;
        height: 100%;
        border-radius: 5px;
    }

    * {
        position: relative;
        z-index: 2;
    }

    &:hover {
        opacity: 1;
    }

    .icon {
        fill: var(--btn-text-light);
        margin-right: 5px;
    }

    .icon-camera,
    span {
        display: block;
    }

    .hidden {
        display: none;
    }
}

.c-quest-header__photo .h-object-fit {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.c-quest-header__header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    padding: 0 10px;
}
.c-quest-header__caption {
    display: flex;
    align-items: flex-end;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0 10px;
    color: #fff;
}
.c-quest-header__tools {
    margin-left: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px;
    font-family: 'ProximaNova-Bold';
    color: #fff;
    display: flex;
    align-items: flex-start;
}
.c-quest-header__tools.is-align-to-left {
    margin-left: 0;
}
.c-quest-header__tools .c-dropdown__body {
    top: 10px;
    right: 10px;
}
.c-quest-header__tool {
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 6px;
    background: $black-40;
}
.c-quest-header__tool:not(:last-child) {
    margin-right: 10px;
}
.c-quest-header__tool .icon {
    margin-right: 4px;
}
.c-quest-header__tool .icon-eye {
    font-size: 1.2em;
}
.c-quest-header__tool .icon-save {
    font-size: 1.24em;
}
.c-quest-header__tool > span {
    margin-bottom: -0.1em;
}
.c-quest-header__switch {
    display: flex;
    align-items: center;
    margin-bottom: 7px;
}
@media (max-width: $breakpoint-xs) {
    .c-quest-header__switch {
        flex-direction: column;
        align-items: flex-end;
        margin-bottom: 0;
   }
}
.c-quest-header__switch > span {
    margin-right: 10px;
}
@media (max-width: $breakpoint-xs) {
    .c-quest-header__switch > span {
        margin-right: 0;
   }
}

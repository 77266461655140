.l-new-landing {
	position: relative;
	display: block;
}
.l-new-landing__header {
	top: 0;
	left: 0;
	z-index: 1;
}
@media (max-width: $screen-sm-max) {
	.l-new-landing__header {
		position: relative;
   }
}

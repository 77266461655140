:root {
    --accent-color: #008297;
    --accent-dark-color: #295967;
    --accent-bg-light-color: #339BAC; //rgba(0, 130, 151, 0.15);
    --btn-text-color: #2e3638; //btn where bg is transparent
    --theme-text-dark: #2e3638;
    --bg-text-color: #2e3638;
    --theme-text-light: #ffffff;
    --btn-text-light: #ffffff;
    --quest-content-bg: #FFFFFF;
    --quest-highlight-bg: #E5EAED;
    --white-section-bg: #ffffff;
    --comment-blue-bg: #E5F2F5;
    --quest-header-bg: #008297;
    --lighter-btn-bg: #E5F2F5;
    --quest-detail-nav: #008297;
    --quest-detail-nav-dark: #295967;
    --site-header: #ffffff;
    --site-header-text: #2e3638;
    --site-header-highlight: #008297;
    --site-header-highlight-light: #008297;
    --site-header-highlight-text: #ffffff;
    --site-header-highlight-text-invert: #ffffff;
    --site-header-box-shadow: #00000080;
    --site-header-badge-bg: #E5F2F5;
    --site-header-badge-text: #008297;
    --avatar-border: #e0e0e0;
    --success-bg-btn: #1D966A;
    --success-bg-text: #ffffff;
    
    --bg-text-color-light: #6d787e;
    --bg-white-ghost: #F8F8F9;
    --bg-milestone: #f3f3f3;
    --white-bg-original: #ffffff;
    --modal-bg: #ffffff;
    --feedback-bg: #f5f7f8;
    --transparent-white-bg: #FFFFFF;
    --milestone-task-bg: #f5f7f8;
    --primary-bg: #f3f3f3;
    --primary-bg-secondary: #ffffff;
    --quest-detail-right: #E5EAED;
    --input-field-bg: #ffffff;
    --nav-flow-number-bg: #ffffff;
    --nav-flow-number-color: #000000;
    --flow-pages-bg: #f3f3f3;
    --flow-pages-section-bg: #ffffff;
    --tooltip-bg: #000000;
    --tooltip-text-color: #ffffff;
    --close-btn-invert: 0;
    --close-btn-invert-str: invert(0);
    --payment-block-row: #ffffff;
    --search-hover-bg: #E5F2F5;
    --progress-bar-uncovered: #ffffff;
    --bg-text-color-secondary: #c5c9cb;
    --bs-backdrop-bg-custom: #000000;
    --read-more-gradient: #ffffff;
    --read-more-btn-text: #2e3638;
    --dots-bg: #E5F2F5;
    --dots-color: #2e3638;
    --total-amount-color: #2e3638;
    --team-option-bg: #f9f9f9;
    --team-option-bg-dark: #ededed;
    --checkbox-selection-color: #ffffff;
    --comment-text-color: #2e3638;
    --table-member-bg: #F4F7F7;
    --table-rank-bg: #ffffff;
    --table-gutter-bg: #E8E9EA;
    --error-dark: #D00808;
    --error-dark-hover: #e61c1c;
    --timeline-bg: #F3F4F5;
    --marked-milestone-bg: #1D966A;
    --marked-milestone-bg-hover: #22ab79;
    --accent-color-secondary-light: #EAEEF0;

    //sample variables for account page
    --sample-primary-bg: #f3f3f3;
    --sample-quest-content-bg: #ffffff;
    --sample-bg-text-color: #2e3638;
    --sample-theme-bg-color: #008297;
    --sample-theme-bg-color-light: #339BAC;
    --sample-theme-text-light: #ffffff;
    --sample-btn-text-light: #ffffff;
    --sample-btn-text-color: #2e3638;

    --white-10: rgba(255, 255, 255, 0.1);
    --black: #000;
    --black-70: rgba(0, 0, 0, 0.7);
    --black-50: rgba(0, 0, 0, 0.5);
    --black-40: rgba(0, 0, 0, 0.4);
    --black-10: rgba(0, 0, 0, 0.10);
    --black-05: rgba(0, 0, 0, 0.05);
    --petrol-70: rgba(0, 81, 102, 0.7);
    --petrol-50: rgba(0, 81, 102, 0.5);
    --petrol-30: rgba(0, 81, 102, 0.3);
    --petrol-15: rgba(0, 81, 102, 0.05);
    --blue-stone-15: rgba(0,81,102, 0.05);
    --bismark-80: rgba(71,121,133,0.8);
    --cornflowerBlue-10: rgba(36,88,102,0.1);
    --white-70: rgba(255, 255, 255, 0.7);
    --white-50: rgba(255, 255, 255, 0.5);
    --white-30: rgba(255, 255, 255, 0.3);
    --light-blue-grey: #a6dce5;
    --jungle-mist: #aac9ce;
    --powder-blue: #b0e0e8;
    --shakespeare: #3fb1d1;
    --matisse: #1D728F;
    --blizzard-blue: #a6e0ec;
    --aquamarine-blue: #86d0e1;
    --hippie-blue: #609eae;
    --purpley-grey: #9e94a2;
    --ebony-clay: #1f3034;
    --petrol: #005166;
    --orient: #006a86;
    --cutty-sark: #516e75;
    --juniper: #648288;
    --smalt-blue: #548b97;
    --bali-hai: #81a8b3;
    --golden-fizz: #f8ec31;
    --amber: #FFC107;
    --geraldine: #ff7f7f;
    --cinnabar: #e83d3d;
    --white: #fff;
    --silver: #bbb;
    --shamrock: #2ecc71;
    --amazon: #347750;
    --puce: #cc8588;
    --alto: #dedede;
    --bermuda: #83cad9;
    --cornflower: #92d6e5;

        // landing colors
    --bright-yellow: #fff100;
    --tabasco: #b41212;
    --casal: #295966;
    --outer-space: #2e3638;
    --dove-gray: #646464;
    --dusty-gray: #979797;
    --silver-sand: #bec1c1;
    --tower-gray: #d6dee1;
    --geyser: #d6dee1;
    --wild-sand: #f4f4f4;
    --alabaster: #f9f9f9;
    --anakiwa: #87ffff;
    --pelorous: #34a6b1;
    --shakespeare: #41abcb;
    --eastern-blue: #168cb2;

    --tealColor: #008297;
    --tealLightColor: #339BAC;
    --tealDarkColor: #295967;
    --greyDarkColor: #2e3638;
    --greyColor: #6d787e;
    --lightGreyColor: #c5c9cb;
    --ghostWhite: #F8F8F9;
    --ghostGrey: #EBECED;
    --paleCyan: #cce6ea;
    
    --secondary-bg: #ebebeb;
    --progress-bg: #f5f7f8;
    --tealLightBg: #E5EAED;
    --backgroundOptionsGray: #f5f5f5;
    --platinum: #A7AEB2;
    --grayBlueBg: #E5F2F5;
    --errorRed: #B73622;
    --warning-color: #EB7200;
    --orange-warning: #EB7200;
    --orange-warning-light: #FDF1E5;
    --orange: #FFB217;
    --orange-80: rgba(255, 178, 23, 0.8);

    --physical: #DB3900;
    --environmental: #4A8500;
    --occupational: #E20096;
    --mental: #018484;
    --friends: #905CB4;
    --piggybank: #AD7D00;

    --teal-01: rgba(41, 89, 103, 0.1);
    --teal-005: rgba(41, 89, 103, 0.05);
    --light-gold: #F2EBD9;
    --brown-gold: #A67B00;
    --border-radius-val: 5px;
    --borderLightGray: #C5C9CB;
    --silver-20: #F3F4F5;
    --teal-70: #4DA7B6;
    --success-bg: #1D966A;
    --success-bg-hover: #22ab79;
    --sinbad: #99CDD5;
}

/*****************************
*      layout variables      *
*****************************/
$gutter-width: 20px;

// for new quest-detail layout;
$gutter-md: 67px;
$gutter-sm: 25px;
$gutter-xs: 20px;
$container-md: 1280px;
$container-sm: 708px;
$padding-sm: 40px;
$padding-md: 60px;

$quest-detail-new-md-min: 1300px;
$quest-detail-new-sm-min: 730px;

$quest-detail-res-sm: 800px;
$quest-detail-res-sm-min: $quest-detail-res-sm - 1px;
$quest-detail-res-md: 1024px;
$quest-detail-res-md-min: $quest-detail-res-md + 1px;
$quest-detail-res-lg: 1280px;
$quest-detail-res-lg-min: $quest-detail-res-lg + 1px;
$quest-detail-res-hd: 1440px;
$quest-detail-res-hd-max: $quest-detail-res-hd + 1px;

$quest-detail-new-sm-max: $quest-detail-new-md-min - 1px;
$quest-detail-new-xs-max: $quest-detail-new-sm-min - 1px;

/**********************************;
*      breakpoints variables      *;
**********************************/
$breakpoint-sd: 1297px;
$breakpoint-sd-max: $breakpoint-sd - 1px;
$breakpoint-md: 1145px;
$breakpoint-md-max: $breakpoint-md - 1px;
$breakpoint-sm: 848px;
$breakpoint-sm-max: $breakpoint-sm - 1px;
$breakpoint-xm: 768px;
$breakpoint-xm-max: $breakpoint-xm - 1px;
$breakpoint-xs: 570px;
$breakpoint-xs-max: $breakpoint-xs - 1px;
$breakpoint-6s: 374px;
$breakpoint-6s-max: $breakpoint-6s - 1px;


/*****************************
*      colors variables      *
*****************************/
$white-10       : rgba(255, 255, 255, 0.1);
$black          : #000;
$black-70       : rgba(0, 0, 0, 0.7);
$black-50       : rgba(0, 0, 0, 0.5);
$black-40       : rgba(0, 0, 0, 0.4);
$petrol-70      : rgba(0, 81, 102, 0.7);
$petrol-50      : rgba(0, 81, 102, 0.5);
$petrol-30      : rgba(0, 81, 102, 0.3);
$petrol-15      : rgba(0, 81, 102, 0.05);
$blue-stone-15  : rgba(0,81,102, 0.05);
$bismark-80     : rgba(71,121,133,0.8);
$cornflowerBlue-10 : rgba(36,88,102,0.1);
$white-70       : rgba(255, 255, 255, 0.7);
$white-50       : rgba(255, 255, 255, 0.5);
$white-30       : rgba(255, 255, 255, 0.3);
$light-blue-grey: #a6dce5;
$jungle-mist    : #aac9ce;
$powder-blue    : #b0e0e8;
$shakespeare    : #3fb1d1;
$matisse        : #1D728F;
$blizzard-blue  : #a6e0ec;
$aquamarine-blue: #86d0e1;
$hippie-blue    : #609eae;
$purpley-grey   : #9e94a2;
$ebony-clay     : #1f3034;
// outer-space    : #29393b;
$petrol         : #005166;
$orient         : #006a86;
$cutty-sark     : #516e75;
$juniper        : #648288;
$smalt-blue     : #548b97;
$bali-hai       : #81a8b3;
$golden-fizz    : #f8ec31;
$amber          : #FFC107;
$geraldine      : #ff7f7f;
$cinnabar       : #e83d3d;
$white          : #fff;
$silver         : #bbb;
$shamrock       : #2ecc71;
$amazon         : #347750;
$puce           : #cc8588;
$alto           : #dedede;
$bermuda        : #83cad9;
$cornflower     : #92d6e5;

// landing colors;
$bright-yellow  : #fff100;
$tabasco        : #b41212;
$casal          : #295966;
$outer-space    : #2e3638;
$dove-gray      : #646464;
$dusty-gray     : #979797;
$silver-sand    : #bec1c1;
$tower-gray     : #d6dee1;
$geyser         : #d6dee1;
$wild-sand      : #f4f4f4;
$alabaster      : #f9f9f9;
$anakiwa        : #87ffff;
$pelorous       : #34a6b1;
$shakespeare    : #41abcb;
$eastern-blue   : #168cb2;

$tealColor: #008297;;
$tealLightColor: #339BAC;;
$tealDarkColor: #295967;;
$greyDarkColor: #2e3638;;
$greyColor: #6d787e;;
$lightGreyColor: #c5c9cb;;
$ghostWhite: #F8F8F9;;
$ghostGrey: #EBECED;;
$paleCyan: #cce6ea;;
$primary-bg: #f3f3f3;;
$secondary-bg: #ebebeb;;
$progress-bg: #f5f7f8;;
$tealLightBg: #E5EAED;;
$backgroundOptionsGray: #f5f5f5;;
$platinum: #A7AEB2;;
$grayBlueBg: #E5F2F5;;
$errorRed: #B73622;;
$warning-color: #EB7200;;
$orange: #FFB217;;
$orange-80: rgba(255, 178, 23, 0.8);
$borderLightGray: #C5C9CB;
/****************************;
*      linear-gradients     *
*****************************/
$LG-defaut: linear-gradient(to bottom, #61bed5 0%, #cbf3f9 100%, #7db9e8 100%);
$LG-blue: linear-gradient(to bottom, #61bed5 0%, #cbf3f9 100%, #7db9e8 100%);


/**********************
*      typography     *
**********************/
$link-color: var(--outer-space);
$link-hover: var(--petrol);

/***********************************
*      quests specifics styles     *
***********************************/
$questDefaultImageUrl: '/assets/static/tree-back.png';

$physical: #DB3900;
$environmental: #4A8500;
$occupational: #E20096;
$mental: #018484;
$friends: #905CB4;
$piggybank: #AD7D00;

$border-radius-val: 5px;
$teal-01: rgba(41, 89, 103, 0.1);
$teal-005: rgba(41, 89, 103, 0.05);

.ql-container {
	font-family: inherit;
	font-size: inherit;
}
.ql-editor ol {
	margin-left: 20px !important;
}
.ql-tooltip.ql-editing {
	min-width: 100px;
}
.ql-bubble .ql-tooltip {
	z-index: 101;
}

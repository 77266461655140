.c-purchase-flow {
  display: block;
	font-size: 1.4rem;
	background: var(--flow-pages-bg);

	@media (max-width: $breakpoint-sd) {
		padding-left: 0;
		padding-right: 0;
	}

  .tf-right-padding {
		padding-right: 60px;
	}

  &__content-container {
    padding-top: 78px;
    padding-bottom: 75px;

    &.no-header {
      padding-top: 0;
    }

    &__body {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      min-height: calc(100vh - 220px);
      padding-left: 0 !important;
      padding-right: 0 !important;
      
      @media (max-width: $breakpoint-xm) {
          flex-direction: column;
          justify-content: flex-start;
      }

      .left-panel__parent {
        width: 70%;
        padding: 20px 40px;
        background: var(--flow-pages-section-bg);
        color: var(--bg-text-color);

        @media (max-width: $breakpoint-xm) {
          width: 100%;
          padding: 20px;
        }
      }

      .right-panel__parent {
        width: 30%;
        background: var(--bg-white-ghost);

        @media (max-width: $breakpoint-xm) {
          width: 100%;
          border-left: 0;
          padding-bottom: 100px;
        }

        app-quest-header .c-quest-detail__header {
          aspect-ratio: 2 / 1;
        }

        &__content {
          padding: 27px 30px; 
        }
      }
    }
  }

  &__row {
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  &__nav-header {
    display: block;
    font-family: 'ProximaNova-Extrabold';
    font-size: 18px;
    text-transform: uppercase;
    color: var(--theme-text-light);
    padding: 0 10px;
    background-color: var(--accent-color);
    position: fixed;
    top: 65px;
    width: 100%;
    z-index: 11;

    @media (max-width: $breakpoint-xm-max) {
      padding: 0;
      top: 56px;
    }

    &__steps {
      display: flex;
      counter-reset: pagination;
      flex-direction: row;
      justify-content: space-between;

      li {
        pointer-events: none;

        &.is-valid {
          pointer-events: auto;
        }
      }
    }

    li {
      display: block;
      transition: max-width 300ms ease;
      display: flex;
      overflow: hidden;
      padding: 14px 25px;
      color: var(--btn-text-light);
      position: relative;
      letter-spacing: 1.25px;
      font-size: 14px;
      font-family: 'ProximaNova-Bold';
      justify-content: center;
      width: 100%;

      @media (max-width: $breakpoint-xs-max) {
        width: calc(24px + 10px);
      }

      &.is-active {
        border-bottom: solid 3px #fff;
        position: relative;

        @media (max-width: $breakpoint-xs-max) {
          width: 100%;
        }

        div, a {
          @media (max-width: $breakpoint-xs-max) {
            span {
              display: block;
            } 
          }

          &:before { 
            @media (max-width: $breakpoint-xs-max) {
              left: -30px;
            }
          }
        }

        &::after {
          content: '';
          width: 100%;
          height: 100%;
          opacity: 0.25;
          background: #fff;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 2;
          border-radius: 0;
        }

        * {
          z-index: 3;
        }
      }

      div, 
      a {
        display: block;
        cursor: pointer;
        text-decoration: none !important;
        color: inherit;
        position: relative;
        padding-top: 25px;

        @media (max-width: $breakpoint-xs-max) {
          padding-top: 0;

          span {
            display: none;
          }
        }

        &:before {
          counter-increment: pagination;
          content: counter(pagination);
          position: absolute;
          top: 0;
          left: calc(40%);
          color: var(--nav-flow-number-color);
          background: var(--nav-flow-number-bg);
          border-radius: 60px;
          width: 22px;
          height: 22px;
          display: flex;
          align-items: center;
          font-size: 16px;
          font-family: 'ProximaNova-Bold';
          justify-content: center;
          z-index: 3;

          @media (max-width: $breakpoint-xs-max) {
            left: -12px;
          }
        }
      }
    }
  }

  &__footer {
    position: fixed;
    bottom: 0;
    left: 0;
    display: block;
    background: var(--flow-pages-section-bg);
    padding: 15px 10px;
    width: 100%;
    z-index: 10;
    border-top: solid 1px var(--lightGreyColor);

      &__inner {
        display: flex;
        align-items: center;
        justify-content: space-between;

        button {
          @media (max-width: $breakpoint-xs-max) {
            width: 47%;
            max-width: 47%;  
          }
        }

        > .c-btn {
          margin: 0;
        }
      }
  }
}

.c-purchase-flow .section-title {
  display: none;
  background: tealLightColor;
  padding: 0 30px;
}
@media (max-width: 650px) {
  .c-purchase-flow .section-title {
    display: block;
 }
}
.c-purchase-flow .section-title span {
  display: none;
}
.c-purchase-flow .section-title span.is-active {
  display: block;
}
.c-purchase-flow .section-title span a {
  display: block;
  text-decoration: none !important;
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
  font-family: 'ProximaNova-Bold';
  line-height: 30px;
  letter-spacing: 1.25px;
}

.c-dl-milestones {
	display: block;
}
.c-dl-milestones__title {
	position: relative;
	font-size: 20px;
	font-family: 'ProximaNova-Semibold';
	color: var(--bg-text-color);
	text-transform: none;
	display: flex;
	align-items: baseline;

	&__inner {
		max-width: 40rem;
		white-space: nowrap;
		overflow: hidden;
	}

	.control-marked-count {
		font-size: 14px;
		font-family: 'ProximaNova-Semibold';
		color: var(--accent-color);
		margin-left: 5px;
	}
}

.c-dl-milestones__title__inner:not(.toTextarea) {
	text-overflow: ellipsis;
}
@media (max-width: 650px) {
	.c-dl-milestones__title__inner {
		max-width: 63vw;
   }
}
.c-dl-milestones__title__toggle {
	border: none;
	padding: 0 4px;
	margin: 0 0 0 4px;
	// transform: rotate(90deg);
	transition: transform 250ms ease-in-out;

	svg {
		width: 1.2rem;
		height: 1.2rem;
	}

	&--expanded {
		transform: rotate(90deg);
		// transform: rotate(-90deg) !important;
	}
}
.c-dl-milestones__group-body {
	margin-top: 20px;
}

.c-dl-milestones__group-body-content {
	box-shadow: none;
	background-color: transparent;
	transition: all 250ms ease-in-out;
}
.c-dl-milestones__group-body-content.c-dl-milestones__group-body-content--modifiable:before, .c-dl-milestones__group-body-content.c-dl-milestones__group-body-content--modifiable:after {
	position: absolute;
	right: 5px;
	font-size: 1rem;
	color: juniper;
}
.c-dl-milestones__group-body-content.c-dl-milestones__group-body-content--modifiable:before {
	top: -15px;
	content: '▲';
}
.c-dl-milestones__group-body-content.c-dl-milestones__group-body-content--modifiable:after {
	bottom: -15px;
	content: '▼';
}
.c-dl-milestones__ttl-helpers {
	display: flex;
	position: absolute;
	left: 0;
	top: 100%;
	margin-top: 1.7rem;
}
.c-dl-milestones__ttl-helpers__item {
	white-space: nowrap;
}
.c-dl-milestones__ttl-helpers__item:not(:last-child) {
	margin-right: 0.5rem;
}
.c-dl-milestones__placeholder {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 6rem;
	margin-top: 3rem;
	text-align: center;
	font-size: 1.4rem;
	font-family: 'ProximaNova-Bold';
	background-color: #ceeff6;
	color: dove-gray;
}
@media (max-width: $screen-sm-max) {
	.c-dl-milestones__placeholder {
		margin-top: 1.5rem;
		margin-bottom: 1.5rem;
   }
}
.c-dl-milestones__footer {
	display: flex;
	justify-content: flex-end;
	padding-top: 2rem;
}
.c-dl-milestones__new {
	display: block;
}
.c-dl-milestones__body {
	margin-bottom: 2rem;
}
@media (max-width: $screen-sm-max) {
	.c-dl-milestones__body {
		margin-top: 1.5rem !important;
   }
}
.c-dl-milestones__tasks-block {
	padding: 1.6rem 2.5rem;
	border-radius: 5px;
	background-color: var(--bg-milestone);
	transition: background-color 250ms ease-in-out;
	margin-bottom: 15px;
	@media (max-width: $breakpoint-xm-max) {
		padding: 1.5rem 2rem;
	}

	&-separator {
		width: 100%;
		height: 1px;
		background: var(--btn-text-color);
		margin-bottom: 15px;
	}

	&.empty-block {
		background-color: var(--orange-warning-light);
	}

	.empty-block {
		&__msg {
			color: var(--orange-warning);
			font-size: 1.6rem;
			font-family: 'ProximaNova-Semibold';
			margin-top: 1.5rem;
			display: flex;
			align-items: center;

			.e-help {
				border-color: var(--orange-warning);
				background-color: var(--orange-warning);
				margin-right: 7px;
			}
		}
	}
}
.c-dl-milestones__tasks-block--bordered {
	background-color: #fff;
}
.c-dl-milestones__tasks-block-header {
	.icon-trash {
		fill: var(--error-dark);
	}

	.icon-settings-cog {
		fill: var(--accent-color);
		width: 17px;
		height: 17px;
	}
}